import useEstimationStatus from './useEstimationStatus'
import { useMethodListenerResult, useModelListenerResult } from '../../store'

const useEstimationValues = () => {
  const estimations = useMethodListenerResult('ridemode', 'package_estimations')
  const authorized = useModelListenerResult('commercialpackage', 'package_authorized')
  const status = useEstimationStatus()
  return [estimations, authorized, status]

}

export default useEstimationValues