import ProviderListener from "../../../../listener"
import isEqual from "react-fast-compare"
import _ from "lodash"
class Component extends ProviderListener {
  constructor(provider, name, info, options) {
    super(provider, name, info, options)
    this.errors = new Set()
    this.ref = null
  }
  getProps() {
    return {

    }
  }
  onCreate() {
    this.meta = {
      dirty: false,
      touched: false,
      visited: false,
      active: false
    }
    this.setResult(this.getInfoKey('initialValue'))
    this.setIsReady(true)
    return super.onCreate()
  }
  getMeta() {
    return this.meta
  }
  setMeta(meta, callback_options) {
    return this.handleKeyChange('meta_updated', 'meta', meta, callback_options)
  }
  getMetaKey(key) {
    return _.get(this.meta, key)
  }
  setMetaKey(key, value, callback_options) {
    const new_meta = _.set(_.clone(this.getMeta()), key, value)
    return this.setMeta(new_meta, callback_options)
  }
  isReadOnly() {

  }
  isDisabled() {

  }
  getValue() {
    return this.getResult()
  }
  setValue(value) {
    return this.setResult(value)
  }
  onChange(value) {
    return this
      .setMetaKey('dirty', !isEqual(this.getInfoKey('initialValue'), value))
      .setValue(value)
  }
  onFocus(e) {
    return this.setMetaKey('visited', true)
  }
  onBlur(e) {
    return this.setMetaKey('touched', true)
  }
  getParentForm() {
    return this.getParentProvider().getForm(this.getInfoKey('form_name'))
  }
  reset() {
    this.setResult(this.getParentForm().getInitialValue(this.getId()))
    this.setMeta({
      dirty: false,
      touched: false,
      visited: false,
      active: false
    })
    return this
  }
}
class Field extends Component {
  constructor(provider, name, info, options) {
    super(provider, name, info, options)
  }
}

export default Field