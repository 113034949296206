import React, { useContext } from 'react'
import { ExternalContext } from '../../contexts/external'
import useCDNAsset from '../../hooks/relay/useCDNAsset'
const CDNSVG = ({ name, category = 'icons', variant, ...rest }) => {
  const path = useCDNAsset(name, category, { variant, extension: 'svg' })
  const { SVGRenderer } = useContext(ExternalContext)
  if (!path) return null
  return (
    <SVGRenderer {...rest} source={path} />
  )
}

export default React.memo(CDNSVG)

export {
  CDNSVG
}
