import { useCallback, useEffect } from 'react'
import Callbacks from './callbacks'
import _ from 'lodash'
import { ProviderInstance } from '../../../provider'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import isEqual from 'react-fast-compare'
import useProviderListener from '../../root/useProviderListener'
const useGlobalState = (key, initial_value) => {
  const subscribe = useProviderListener('global', key, { initial_value })
  const [value, status] = useSyncExternalStoreWithSelector(
    subscribe,
    useCallback(() => [
      ProviderInstance.getProvider('global').getListenerResult(key, { initial_value }),
      ProviderInstance.getProvider('global').getFullListenerStatus(key) || { isLoading: true, isReady: false },
    ], [key]),
    null,
    _.identity,
    isEqual
  )
  const setProviderValues = useCallback((data) => ProviderInstance.getProvider('global').setValues(data), [])
  const clearProviderValues = useCallback((paths) => ProviderInstance.getProvider('global').clearValues(paths), [])

  const setValue = useCallback(Callbacks.setValueHandler(setProviderValues, key), [key])
  const setSubValue = useCallback(Callbacks.setSubValueHandler(setProviderValues, key), [key])
  const setSubValues = useCallback(Callbacks.setSubValuesHandler(setProviderValues, key), [key])
  const clear = useCallback(Callbacks.clearHandler(clearProviderValues, key), [key])
  useEffect(() => () => ProviderInstance.getProvider('global').destroyListener(key), [])

  return [value, setValue, clear, { setSubValue, setSubValues }, status]
}

export default useGlobalState
