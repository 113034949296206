import { useContext, useCallback, useMemo } from 'react'
import {
  GlobalFormContext,
} from '../..'
import _ from 'lodash'

const useGlobalFormState = (formName, key) => {
  const { getInjectedDispatch, getPartialState } = useContext(GlobalFormContext)
  const dispatch = useCallback(getInjectedDispatch(formName), [getInjectedDispatch, formName])
  const state = useMemo(() => getPartialState(formName), [getPartialState, formName])
  const updateStateValue = useCallback((value) => dispatch({ type: !!key ? 'UPDATE_STATE_KEY' : 'UPDATE_STATE', key, value }), [key])
  const state_values = useMemo(() => !!key ? _.get(state, `state.${key}`) : _.get(state, 'state') || {}, [state, key])
  return [state_values, updateStateValue]
}

export default useGlobalFormState
