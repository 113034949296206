import _ from 'lodash'
import I18n from '../../utils/translation'
import Utils from '../../utils/'
import { FormEmitter } from '../../provider/form'
class Callbacks {
  static setNormalizedFieldValueHandler(setFieldValue, normalize, validateField, { clearOnEmpty, clearValue, validateOnChange }) {
    return function setNormalizedFieldValue(value, extra_meta) {
      const normalized_value = normalize(value)
      const validation_result = validateOnChange ? validateField(normalized_value) : null
      const meta = !!validateOnChange ? { field_error: validation_result } : {}

      if ((Utils.isNil(normalized_value) || (_.isObject(normalized_value) && _.isEmpty(normalized_value))) && !!clearOnEmpty)
        setFieldValue(undefined, _.merge({}, extra_meta, meta))
      else setFieldValue(!Utils.isNil(normalized_value) ? normalized_value : clearValue, _.merge({}, extra_meta, meta))
      return normalized_value
    }
  }
  static applyChangeHandler(setNormalizedFieldValue, afterUserChange, full_name, form_name) {
    return function applyChange(value) {
      const new_value = setNormalizedFieldValue(value)
      afterUserChange(new_value, full_name)
      FormEmitter.emit(form_name, { action: 'field_change' })
    }
  }
  static onUserChangeHandler(setNormalizedFieldValue, afterUserChange, full_name, isDisabled, form_name, getChangeDialog, openDialog, prev_value, applyChange) {
    return function onUserChange(new_value) {
      if (!isDisabled) {
        const { dialog_name, dialog_props, dialog_extra } = getChangeDialog(prev_value, new_value) || {}

        if (!!dialog_name) {
          openDialog(dialog_name, { ...dialog_props, prev_value, new_value }, { ...dialog_extra, onChange: applyChange, prev_value, new_value })
        }
        else {
          const normalized_value = setNormalizedFieldValue(new_value)
          afterUserChange(normalized_value, full_name)
          FormEmitter.emit(form_name, { action: 'field_change' })
        }
      }
    }
  }
  static validateFieldHandler(required, validate, requiredText) {
    return function validateField(value) {
      if (!!required && (Utils.isNil(value) || (_.isObject(value) && _.isEmpty(value)))) {
        return requiredText || I18n.t('voc.field.required')
      }
      else if (!!validate) {
        const validation_result = validate(value)
        return validation_result || null
      }
      else return null

    }
  }
  static afterChangeHandler(isDisabled, inputAfterChange, asyncNormalize, setNormalizedFieldValue, inputRef) {
    return async function afterChange(value, meta) {
      if (!isDisabled) {
        const final_value = await asyncNormalize(value, inputRef)
        if (!_.isEqual(value, final_value)) {
          setNormalizedFieldValue(final_value)
        }
        else if (!_.get(meta, 'error'))
          !!inputAfterChange && inputAfterChange(value)
      }
    }
  }
}

export default Callbacks
