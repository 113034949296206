import _ from 'lodash'
import Utils from '../../utils/'
class Memos {
  static getSanitizedValue(value, multiple) {
    const default_value = !!multiple ? [] : undefined
    const sanitized_values = !!multiple ? _.compact(_.flatten([value])) : value
    return !Utils.isNil(sanitized_values) ? sanitized_values : default_value
  }
}

export default Memos
