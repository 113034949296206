import { useMethodListenerStatus, useModelListenerStatus } from '../../store'
import { HookUtils } from '../../../utils'
const useEstimationStatus = () => {
  const estimation_status = useMethodListenerStatus('ridemode', 'package_estimations')
  const authorized_status = useModelListenerStatus('commercialpackage', 'package_authorized')
  return {
    isLoading: HookUtils.getLoadingState([estimation_status, authorized_status]),
    isReady: HookUtils.getReadyState([estimation_status, authorized_status]),
    isEstimationLoading: HookUtils.getLoadingState([estimation_status]),
    isEstimationFetched: estimation_status?.isFetched,
    isAuthorizedLoading: HookUtils.getLoadingState([authorized_status]),
  }
}

export default useEstimationStatus