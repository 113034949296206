import _ from 'lodash'
import { useGlobalFormFunction, useGlobalFormStatus, useGlobalFormValues } from '../../../hooks'

const useGlobalForm = (form_name) => {
  const functions = useGlobalFormFunction(form_name)
  const status = useGlobalFormStatus(form_name)
  const values = useGlobalFormValues(form_name)
  return [values, _.get(functions, 'bulkChange'), status, functions]
}

export default useGlobalForm
