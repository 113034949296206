import _ from "lodash";
import ProviderListener from ".."
const defaultTruth = () => true
async function tick(interval = 1) {
  return new Promise(resolve => setTimeout(resolve, interval * 1000));
}
/**
 * Class representing a search listener
 * @extends ProviderListener
 */
class SearchListener extends ProviderListener {
  constructor(provider, listener_id, info, options) {
    super(provider, listener_id, info, options)
    this.count = 0
  }
  async onCreate() {
    this.createModelListener(`parent_${this.getId()}`, this.getParentProvider().getName(), { ids: [] }, { listener_type: 'basic', populate: this.getInfoKey('populate') })
    if (!_.isUndefined(this.getInfoKey('initial_filter'))) {
      await this.search(this.getInfoKey('initial_filter'))
    }
    else this.setIsReady(true)
    return this
  }
  onInfoUpdated() {
    return this.search()
  }
  onExternalResultUpdated() {
    if (!!this.isWatching())
      this.getParentProvider().getProvider('socket').refreshWatchers()
  }
  onDestroy() {
    super.onDestroy()
    if (!!this.isWatching())
      this.getParentProvider().getProvider('socket').refreshWatchers()
    return this
  }
  async onSocketConnected() {
    try {
      if (this.isWatchEnabled())
        await this.refreshSearch(true)
    }
    catch (err) {
      if ((err?.context?.code || err?.code) === 'ECONNREFUSED') {
        console.warn("Connection refused, retrying...")
        await tick(5)
        return this.onSocketConnected()
      }
      else console.warn("Failed to handle socket reconnect in listener", { listener: this, err })
    }
    return super.onSocketConnected()
  }
  setResult(result, callback_options) {
    this.getParentListener()
      .setInfoKey('ids', result)
      .setOptionKey('populate', this.getInfoKey('populate'))
    this.getParentProvider().getProvider('store').shakeTree()
    return super.setResult(result, callback_options)
  }
  setCount(count, callback_options) {
    return this.handleKeyChange('result_updated', 'count', count, callback_options)
  }
  isWatching() {
    return !!_.get(this.getExternalResult(), 'headers.x-watcher-id')
  }
  getWatcherId() {
    return _.get(this.getExternalResult(), 'headers.x-watcher-id')
  }
  isWatchEnabled() {
    return !!this.getOption('watch_type')
  }
  setExternalResult(result, extra) {
    this.getParentProvider().getProvider('store').addPopulatedRecords(this.getParentProvider().getName(), _.get(result, 'data'), true)
    const new_ids = _.map(_.get(result, 'data'), this.getParentProvider().getPrimaryKey())
    if (this.getInfoKey('incremental') && _.get(extra, 'action') !== 'reset') {
      if (parseInt(this.getSearchOption('skip') || 0) <= parseInt(_.get(result, 'args.options.skip') || 0))
        this.setResult(_.uniq(_.flatten([this.getResult(), new_ids])))
      else this.setResult(_.uniq(_.flatten([new_ids, this.getResult()])))
    }
    else this.setResult(new_ids)
    this.setCount(parseInt(_.get(result, 'headers.x-count') || 0))
    return super.setExternalResult(result)
  }
  /**
   * Get the filter currently sent the backend (after forced_filter/initial_filter are applied)
   * @returns {object} the final filter object
   */
  getExternalFilter() {
    return _.get(this.getArgs(), 'filter')
  }
  /**
   * Get the options currently sent the backend (after combining user and default options)
   * @returns {object} the final options object
   */
  getExternalOptions() {
    return _.get(this.getArgs(), 'options')
  }
  /**
   * Get count currently returned by the backend
   * 
   * ATTENTION: this value is reserved to the backend response,
   * - If you have watchers enabled the real count may be updated but this value will NOT change.
   * - If you want the live updated count, use getCount instead
   * @returns {number} count received by the backend
   */
  getExternalCount() {
    return parseInt(_.get(this.getExternalResult(), 'headers.x-count') || 0)
  }
  /**
   * Get the current updated count of the search
   * @returns {number} Current count of the listener
   */
  getCount() {
    return this.count
  }
  getMethodArgs(args) {
    const incoming_filter = _.get(args, 'filter')
    const incoming_options = _.get(args, 'options')
    const filter = _.merge({}, this.getInfoKey('default_filter', {}), incoming_filter, this.getInfoKey('forced_filter', {}))
    const options = _.merge(this.getDefaultSearchOptions(), incoming_options)
    return { filter, options }
  }
  getDefaultSearchOptions() {
    const pageSize = this.getInfoKey('default_limit', 25)
    return {
      limit: pageSize,
      skip: this.getInfoKey('default_page', 0) * pageSize,
      mode: this.getInfoKey('mode'),
      populate: this.getInfoKey('populate'),
      sort: this.getInfoKey('sort'),
      count: this.getOption('enableCount') || false,
      watch_type: this.getOption('watch_type'),
      log_query: this.getOption('enableLogQuery'),
    }
  }
  /**
   * Get all options either from the default options or from the current request
   * @returns {object} All options currently in effect
   */
  getSearchOptions() {
    return this.getExternalOptions() || this.getDefaultSearchOptions()
  }
  /**
   * Get the value of a single option either from the default options or from the current request
   * @param {string} name Option name to get
   * @returns {any} Value of the option
   */
  getSearchOption(name) {
    return _.get(this.getSearchOptions(), name)
  }
  /**
   * Calls the backend search of the listener
   * @param {object} filter Filter to use for the request -- this will automatically be combined with listener forced/default filters
   * @param {object} options Options to use for the request (undefined values will fallback to their defaults) -- see getDefaultSearchOptions
   * @param {boolean} [silent] Allows you to do the request without changing the isLoading/isReady flags of the search
   * @returns {Promise} result of the request
   */

  search(filter, options, silent, extra) {
    const validate_filter = this.getInfoKey('validate_filter') || defaultTruth
    if (validate_filter())
      return this.callMethod({ filter, options }, silent, extra)
    return this.clearSearch()
  }
  refreshSearch(silent) {
    const last_args = this.getLastCallArgs()
    if (!!last_args)
      return this.search(_.get(last_args, 'filter'), _.get(last_args, 'options'), silent, { action: 'refresh' })
    return null
  }
  resetSearch(silent) {
    const initial_filter = this.getInfoKey('initial_filter')
    return this.search(initial_filter, _.merge({}, this.getSearchOptions(), { skip: 0 }), silent, { action: 'reset' })
  }
  clearSearch() {
    return this.setExternalResult(null)
  }

  onSocketIndexUpdate(event) {
    const new_result = _.reduce(event.data, (acc, info) => {
      if (!!info.rank) {
        if (this.getSearchOption('limit') !== -1 && (info.rank - 1) < this.getSearchOption('skip') || (info.rank - 1) > (this.getSearchOption('skip') + this.getSearchOption('limit')))
          return acc
        const adjusted_rank = info.rank - this.getSearchOption('skip')
        const existing_index = _.findIndex(acc, (id) => id === info.record_id)
        if (existing_index === -1 || existing_index + 1 !== adjusted_rank) {
          const new_acc = (existing_index !== -1) ? _.set(acc, existing_index, null) : acc
          return _.flatten([_.slice(new_acc, 0, adjusted_rank - 1), info.record_id, _.slice(new_acc, adjusted_rank - 1)])
        }
        return acc
      }
      else {
        const remove_index = _.findIndex(acc, (id) => id === info.record_id)
        if (remove_index === -1)
          return acc
        return _.set(acc, remove_index, null)
      }
    }, _.clone(this.getResult()))
    return this
      .setResult(this.getSearchOption('limit') === -1 ? new_result : _.take(new_result, this.getSearchOption('limit')))
      .setCount(parseInt(event.count || 0))
  }
  onSocketResultUpdate(event) {
    const full_result = _.uniq(_.flatten([event.data.include_ids || [], _.difference(this.getResult(), event.data.exclude_ids)]))
    const new_result = this.getSearchOption('limit') === -1 ? full_result : _.take(full_result, this.getSearchOption('limit'))
    return this
      .setResult(new_result)
      .setCount(parseInt(event.count || 0))
  }
  onSocketCountUpdate(event) {
    return this
      .setCount(parseInt(event.count || 0))
  }
}
export default SearchListener