import utils from './main'
import _ from 'lodash'
import polyline from 'google-polyline'

class GeoUtils {
  static getAddressKeys() {
    return ['address_id', 'place_id', 'poi_id']
  }

  static decodePoints(points) {
    return _.map(polyline.decode(points), (point) => ({ lat: point[0], lng: point[1] }))
  }
  static encodePoints(points) {
    return polyline.encode(points)
  }

  static isSameAddress(address1, address2) {
    return utils.isSameObject(address1, address2, GeoUtils.getAddressKeys())
  }

  static hasCoordinates(coordinates) {
    return GeoUtils.toGeoCoordinates(coordinates).length === 2
  }

  static toGeoCoordinates(coordinates) {
    return _.compact([_.get(coordinates, 'lng'), _.get(coordinates, 'lat')])
  }

  static invertPolygon(polygon) {
    return _.map(polygon, (point) => GeoUtils.invertCoordinates(point))
  }
  static invertCoordinates(coordinates) {
    return _.reverse(coordinates)
  }

  static toGeoJSON(obj, coordinates_path, properties) {
    if (!GeoUtils.hasCoordinates(_.get(obj, coordinates_path)))
      return null
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: GeoUtils.toGeoCoordinates(_.get(obj, coordinates_path))
      },
      properties: _.reduce(properties, (acc, property) => {
        if (_.has(obj, property))
          _.set(acc, property, _.get(obj, property))
        return acc
      }, {})
    }
  }

  static format(details, options) {
    let formatted_address = []
    if (!!_.get(details, 'poi.poi_client_id'))
      formatted_address = _.concat(formatted_address, _.get(details, 'poi.poi_client_id'))
    else if (!!_.get(details, 'extra.stop_number'))
      formatted_address = _.concat(formatted_address, _.get(details, 'extra.stop_number'))
    if (!!_.get(details, 'extra.name'))
      formatted_address = _.concat(formatted_address, _.get(details, 'extra.name'))
    if (
      (!_.get(details, 'poi') && !!_.get(details, 'name')) ||
      (!!_.get(details, 'poi') && _.get(details, 'poi.display_address', false))
    ) {
      if (!_.isEmpty(_.get(options, 'omit')))
        formatted_address = [GeoUtils.mergeAddress(_.omit(details, _.flatten(['region', _.get(options, 'omit')])), _.get(details, 'country'))]
      else formatted_address = _.concat(formatted_address, _.get(details, 'name'))
    }
    return !_.isEmpty(formatted_address) ? _.join(formatted_address, ' - ') : null
  }
  static getFormattedObject(details) {
    if (_.isEmpty(details)) return null
    return _.merge({}, _.pick(details, GeoUtils.getAddressKeys()), { formatted_address: GeoUtils.format(details) })
  }

  static formatCountryStreet(details, country) {
    switch (_.toLower(country || _.get(details, 'country') || '')) {
      case 'belgique':
      case 'belgium':
        return _.compact([_.get(details, 'street'), _.get(details, 'street_number')]).join(' ')
      default:
        return _.compact([_.get(details, 'street_number'), _.get(details, 'street')]).join(' ')
    }
  }
  static getFormattedAddress(details) {
    return utils.selectFirstKey(details, ['formatted_address', 'name'])
  }
  static getFullAddress(details) {
    return utils.selectFirstKey(details, ['name', 'formatted_address'])
  }
  static getCompactAddress(details) {
    if (!!_.get(details, 'extra.name'))
      return _.get(details, 'extra.name')
    return GeoUtils.formatCountryStreet(details) || GeoUtils.getSpecificFormat(details, 'city,country')
  }
  static parseLocation(location) {
    return {
      lng: _.get(location, 'coords.longitude'),
      lat: _.get(location, 'coords.latitude'),
      alt: _.get(location, 'coords.altitude'),
      speed: _.get(location, 'coords.speed'),
      bearing: _.get(location, 'coords.heading'),
      accuracy: _.get(location, 'coords.accuracy'),
      mocked: _.get(location, 'mocked'),
      timestamp: _.get(location, 'timestamp')
    }
  }

  static getAddressUseEffectKeys(address) {
    return utils.selectAllKeys(address, GeoUtils.getAddressKeys())
  }

  static mergeAddress(details, country) {
    return _.trim(_.compact([
      GeoUtils.formatCountryStreet(details, country),
      _.compact([
        _.trim(_.get(details, 'postal_code')),
        _.trim(_.get(details, 'region')),
        _.compact([
          _.trim(_.get(details, 'city')),
          _.trim(_.get(details, 'country'))
        ]).join(', ')
      ]).join(' ')
    ]).join(', '))
  }

  static getSpecificFormat(address, format) {
    if (format === 'compact')
      return GeoUtils.getCompactAddress(address)
    else if (format === 'full')
      return GeoUtils.getFullAddress(address)
    else if (format === 'friendly')
      return GeoUtils.format(address)
    else if (!_.isEmpty(format) && !!address) {
      return _.includes(format, 'street') && !address?.street
        ? address?.name
        : _.compact(_.map(_.split(format, ','), key => _.get(address, key))).join(', ')
    }
    else return null
  }
}

export default GeoUtils
