import i18n from "i18n-js";
import _ from "lodash";
import moment from 'moment'
import "moment/min/locales";
const fallback = 'en'
class Translation {
  static _instance
  static getInstance() {
    if (!Translation._instance)
      Translation._instance = new Translation()
    return Translation._instance
  }
  t(key, config) {
    return i18n.t(key, config)
  }
  getLocale() {
    return i18n.locale
  }
  setLocale(locale) {
    const languageTag = this.parseLocale(locale) || fallback
    i18n.locale = languageTag;
    moment.updateLocale(languageTag, { calendar: this.t('voc.calendar') })
    const selectedTranslation = (_.get(this.translations, languageTag) || _.get(this.translations, fallback))()
    i18n.translations = { [languageTag]: selectedTranslation };
  }
  parseLocale(locale) {
    if (_.includes(locale, '_'))
      return _.get(_.split(locale, '_'), 0) || fallback
    if (_.includes(locale, '-'))
      return _.get(_.split(locale, '-'), 0) || fallback
    return locale || fallback
  }
  setI18nConfig(getTranslations) {
    this.translations = getTranslations()
    this.setLocale(this.getLocale())
  }
}
export default Translation.getInstance()
