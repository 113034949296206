import _ from 'lodash'
import HookUtils from '../../../utils/hooks'
import useJobNextSteps from './useJobNextSteps'
const active_list = ['planned', 'started', 'at_src', 'on_board', 'at_dst', 'off_board']

const useJobCurrentStatus = (input) => {
  const job_id = _.get(input, 'job_id')
  const [filtered_steps, stepState] = useJobNextSteps({ job_id }, { populate: ['order.assigned_transport'] })
  const current_status = _.minBy(_.map(filtered_steps, 'order.assigned_transport.status'), (status) => _.indexOf(active_list, status))
  return [current_status, { isLoading: HookUtils.getLoadingState([stepState]) }]
}


export default useJobCurrentStatus