import _ from 'lodash'

import I18n from './translation'

class OptionUtils {
  static getLabel(option, displayPrice = true) {
    return `${option?.name_translated} ${option?.price && !!displayPrice ? `+${I18n.t('voc.currency.eur', { price: option.price })}` : ''}`
  }

}

export default OptionUtils
