import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import shortid from 'shortid';
import { hooks } from '..';
import GeoUtils from '../utils/geo';
import HookUtils from '../utils/hooks';
function onGetDetailsHandler(getDetails, setSessionToken, session_token, owner, language, onAddressResolve) {
  return async function onGetDetails(filter, inputRef) {
    if (_.isEmpty(filter)) {
      await onAddressResolve(null)
      return filter
    }
    else {
      const details = await getDetails({
        filter,
        owner,
        options: _.merge({}, { session_token, request: ['info', 'coordinates'], language })
      })
      setSessionToken(shortid.generate())
      await onAddressResolve(details, inputRef)
      return _.pick(details, _.flatten([GeoUtils.getAddressKeys(), ['name', 'formatted_address', 'timezone']]))
    }
  }
}
const withAddress = (Component) => ({ initialConfig, search_id, resolveAddress = false, onAddressResolve = _.noop, owner, disabled, ...props }) => {
  const [session_token, setSessionToken] = useState(!!resolveAddress ? shortid.generate() : null)
  const [addresses, autocomplete, autocomplete_state, autocomplete_controls] = hooks.useAddressAutocomplete(_.merge({}, initialConfig, { owner, disabled, search_id }), { session_token })
  const language = hooks.useUserLanguage({ user_id: owner })
  const [getDetails] = hooks.useModelFunction('address', 'getDetails')
  const onGetDetails = useCallback(
    onGetDetailsHandler(getDetails, setSessionToken, session_token, owner, language, onAddressResolve),
    [getDetails, setSessionToken, session_token, owner, language, onAddressResolve]
  )
  return (
    <Component
      onInputChange={autocomplete}
      asyncNormalize={!!resolveAddress ? onGetDetails : _.identity}
      isLoading={HookUtils.getLoadingState([autocomplete_state])}
      isReady={HookUtils.getReadyState([autocomplete_state])}
      options={addresses}
      selectKeys={[]}
      matchKeys={GeoUtils.getAddressKeys()}
      labelKeys={['formatted_address', 'name']}
      selectFirst={false}
      includeLabelInSelection
      addressControls={autocomplete_controls}
      disabled={disabled}
      clearInvalid={false}
      {...props}
    />
  );
}


export default withAddress
