import { useEffect, useCallback } from 'react'
import Utils from '../../../utils'
import _ from 'lodash'

const useGlobalKeyListener = (onKeyDown, keys, { disabled, ...control_keys } = {}) => {
  const execKeyDown = useCallback(e => {
    onKeyDown(e)
    e.preventDefault()
  }, [onKeyDown])

  const handleOnKeyDown = useCallback(event => {
    const { key, ctrlKey, altKey, ...rest } = event
    if (!!ctrlKey && _.get(control_keys, 'ctrlKey') && _.includes(keys, key))
      execKeyDown(event)
    else if (!!altKey && _.get(control_keys, 'altKey') && _.includes(keys, key))
      execKeyDown(event)
    else if (!ctrlKey && !altKey && _.includes(keys, key))
      execKeyDown(event)
  }, [execKeyDown, Utils.selectAllKeys(control_keys, ['ctrlKey', 'altKey', 'disabled']).join(',')])

  useEffect(() => {
    if (!disabled) {
      window.addEventListener('keydown', handleOnKeyDown)
      return () => window.removeEventListener('keydown', handleOnKeyDown)
    }
    return _.noop
  }, [handleOnKeyDown])

  return
}

export default useGlobalKeyListener
