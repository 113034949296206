import _ from 'lodash'
import I18n from './translation'

class OrderUtils {
  static getOrderType = (current_application, load_type) => {
    return _.get(current_application, 'name') === 'Collecto' ? 'collecto' : load_type
  }
  static getColorTheme = type => {
    switch (type) {
      case 'package':
        return "packageText";
      case 'collecto':
        return "collectoText";
      case 'user':
      default:
        return "primary.main";
    }
  }
  static getOrderStatus = (status, confirmed) =>
    !confirmed && status === 'created'
      ? I18n.t('order.status.quotation')
      : !!status ? I18n.t(`order.status.${status}`) : null

  static getTransportStatus = (status, statusReason, load_type = 'user') => {
    if (!status)
      return null
    else {
      return status === 'canceled' && statusReason === 'not_loaded'
        ? I18n.t(`transportStatus.not_loaded.${load_type}`)
        : I18n.t(`transportStatus.${status}.${load_type}`)
    }
  }

  static parseOrderClientId = order_client_id => {
    if (!order_client_id) return
    const first = _.head(order_client_id.match(/^[A-z]+.[0-9]{0,6}/g))
    const second = _.slice(order_client_id, first.length, order_client_id.length).join('')
    return {
      first,
      second,
      full: `${first}${second}`
    }
  }
}

export default OrderUtils
