import _ from 'lodash'

import useModel from '../../store/useModel'
import useEstimationDurationAmount from '../estimation/useEstimationDurationAmount'

const useOrderDurationAmount = (order_id, duration, type = 'driver') => {
  const [order] = useModel('order', [order_id], { single: true })
  return useEstimationDurationAmount(type === 'driver' ? order?.driver_estimation : order?.quotation, duration)

}

export default useOrderDurationAmount