import { useCallback, useMemo } from 'react'
import { useGlobalFormMeta, useGlobalFormStatus } from '../../../hooks'
import _ from 'lodash'

const useGlobalFieldMeta = (form_name, field) => {
  const [formMeta, setFormMeta, removeFormMeta] = useGlobalFormMeta(form_name)
  const isReadOnly = useGlobalFormStatus(form_name, 'isReadOnly')
  const setFieldMeta = useCallback((data) => !isReadOnly && setFormMeta(field, data), [isReadOnly, setFormMeta, field])
  const removeFieldMeta = useCallback(() => !isReadOnly && removeFormMeta(field), [isReadOnly, removeFormMeta, field])
  const meta = useMemo(() => _.find(formMeta, ['field', field]), [_.values(_.find(formMeta, ['field', field])).join(',')])
  return [meta, setFieldMeta, removeFieldMeta]
}

export default useGlobalFieldMeta
