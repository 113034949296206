import _ from 'lodash'
import { useEffect } from 'react'
import useModelSearch from '../../store/useModelSearch'
import HookUtils from '../../../utils/hooks'
const useAuthorizedPaymentTypes = ({ service, load_type, user_id, commercial_formula, domain_id }, inputIsReady, onPaymentTypeChange = _.noop, onPaymentMethodChange = _.noop) => {
  const [payment_types, , payment_types_state] = useModelSearch('paymenttype', 'get', {
    initial_filter: {},
    forced_filter: {
      deleted: false,
      load_type: load_type || 'user',
      service_id: service,
      user_id,
      commercial_formula,
      domain_id
    },
    mode: 'available',
    sort: ['display_index ASC'],
    watchers: [service, load_type, user_id, commercial_formula, domain_id]
  })
  const [payment_methods, , payment_methods_state] = useModelSearch('paymentmethod', 'get', {
    initial_filter: {},
    watch_type: 'result'
  })
  const isReady = HookUtils.getReadyState([payment_types_state, payment_methods_state])
  const isLoading = HookUtils.getLoadingState([payment_types_state, payment_methods_state])
  const default_paymenttype = _.head(payment_types)
  const default_paymentmethod = _.find(payment_methods, ['default', true])
  const default_paymenttype_without_card = _.find(payment_types, ['card_required', false])
  const mustAddCard = !!isReady && _.isEmpty(payment_methods) && payment_types?.length === 1

  useEffect(() => {
    if (!!isReady && !!inputIsReady) {
      if (!!default_paymentmethod && !!default_paymenttype?.card_required) {
        onPaymentTypeChange(_.get(default_paymenttype, 'paymenttype_id'))
        onPaymentMethodChange(_.get(default_paymentmethod, 'payment_method_id'))
      }
      else {
        onPaymentTypeChange(_.get(default_paymenttype_without_card, 'paymenttype_id'))
        onPaymentMethodChange(null)
      }
    }
  }, [default_paymentmethod?.payment_method_id, default_paymenttype?.paymenttype_id, default_paymenttype_without_card?.paymenttype_id, isReady, inputIsReady])


  return [{
    payment_types,
    payment_methods,
    payment_types_state,
    payment_methods_state
  }, {
    isReady,
    isLoading,
    mustAddCard
  }]
}

export default useAuthorizedPaymentTypes