import useTranslation from '../translation/useTranslation'
import useRelayFeature from '../../relay/useRelayFeature'

const useLoads = () => {
  const PACKAGE_ENABLED = useRelayFeature('PACKAGE_ENABLED')
  const TAXI_ENABLED = useRelayFeature('TAXI_ENABLED')

  return _.compact([
    !!TAXI_ENABLED && { label: useTranslation('load', 'user', { suffix: 'label.one' }), value: 'user' },
    !!PACKAGE_ENABLED && { label: useTranslation('load', 'package', { suffix: 'label.one' }), value: 'package' }
  ])
}

export default useLoads
