
class SetUtils {
  static find(set, comparator) {
    const values = set.values()
    let it = values.next()
    while (!it.done) {
      const value = it.value
      if (!!comparator(value))
        return value
      it = values.next()
    }
    return null
  }
  static filter(set, comparator) {
    const values = set.values()
    let it = values.next()
    const result = []
    while (!it.done) {
      const value = it.value
      if (!!comparator(value))
        result.push(value)
      it = values.next()
    }
    return result
  }
  static map(set, mutator) {
    const values = set.values()
    let it = values.next()
    const result = []
    while (!it.done) {
      const value = it.value
      result.push(mutator(value))
      it = values.next()
    }
    return result
  }
  static remove(set, comparator) {
    const remove_record = SetUtils.find(set, comparator)
    if (!!remove_record) {
      set.delete(remove_record)
      return remove_record
    }
    return null
  }
  static removeAll(set, comparator) {
    const result = []
    set.forEach((value) => {
      if (comparator(value)) {
        set.delete(value)
        result.push(value)
      }
    })
    return result
  }
  static join(set1, set2) {
    const result = []
    set1.forEach((value) => {
      result.push(value)
    })
    set2.forEach((value) => {
      result.push(value)
    })
    return result
  }
}

export default SetUtils