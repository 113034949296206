import { useCallback } from 'react'
import useProps from '../../root/useProps'
import Callbacks from './callbacks'
import _ from 'lodash'
const defaultTruth = () => true
const useModelFormatter = (options) => {
  const {
    single,
    limit,
    postFilter,
    mutator,
    finalMutator,
    sortBy,
    sortDirection,
  } = useProps(options, {
    allowAll: false,
    postFilter: defaultTruth,
    mutator: _.identity,
    finalMutator: _.identity,
    sortDirection: ['desc'],
  })

  const getFormattedRecords = useCallback(
    Callbacks.getFormattedRecordsHandler(
      {
        postFilter,
        sortBy,
        sortDirection,
        mutator,
        finalMutator
      }, {
      single,
      limit
    }), [
    postFilter,
    sortBy,
    sortDirection,
    mutator,
    finalMutator,
    single,
    limit
  ])
  return getFormattedRecords
}

export default useModelFormatter