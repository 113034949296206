import _ from "lodash";
import Listener from ".."

class MethodListener extends Listener {
  async onCreate() {
    this.setIsReady(true)
    return super.onCreate()
  }
  async onCreate(event) {
    return this.onWatchersUpdated(event)
  }
  async onInfoUpdated(event) {
    return this.onWatchersUpdated(event)
  }
  async onWatchersUpdated() {
    if (this.getOption('submitOnUpdate'))
      await this.callMethod(this.getInfo())
    return this
  }
  onExternalResultUpdated() {
    return this.setResult(_.get(this.getExternalResult(), 'data'))
  }
  refreshMethod(silent) {
    const last_args = this.getLastCallArgs()
    if (!!last_args)
      return this.callMethod(last_args, silent)
    return null
  }
}

export default MethodListener