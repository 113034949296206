import _ from "lodash";
import ProviderListener from "../../../listener"

class Listener extends ProviderListener {
  constructor(provider, listener_id, info, options) {
    super(provider, listener_id, info, options)
    this.last_call_args = null
    this.last_args = null
    this.boundOnSocketConnected = this.onSocketConnected.bind(this)
  }
  onCreate() {
    this.getParentProvider().getProvider('socket').attach('connect', this.boundOnSocketConnected)
    return super.onCreate()
  }
  onDestroy() {
    this.getParentProvider().getProvider('socket').dettach('connect', this.boundOnSocketConnected)
    return super.onDestroy()
  }
  getLastCallArgs() {
    return this.last_call_args
  }
  getLastArgs() {
    return this.last_args
  }
  getLastCallArg(name) {
    return _.get(this.getLastCallArgs(), name)
  }
  setLastCallArgs(last_call_args, callback_options) {
    return this.handleKeyChange('result_updated', 'last_call_args', last_call_args, callback_options)
  }
  setLastArgs(last_args, callback_options) {
    return this.handleKeyChange('result_updated', 'last_args', last_args, callback_options)
  }
  onSocketConnected() {
  }
  getMethodArgs(args) {
    return args
  }
  getMethod(silent, extra) {
    return this.getParentProvider().getMethod(this.getOption('method_name'), this.getId(), silent, extra)
  }
  callMethod(args, silent, extra) {
    return this.getMethod(silent, extra)(args)
  }
  getArgs() {
    return _.get(this.getExternalResult(), 'args')
  }
  clear() {
    return this.setExternalResult(null, { action: 'reset' })
  }
  getStatus() {
    return _.merge({ isFetched: !!this.getLastArgs() }, super.getStatus())
  }
}

export default Listener
