import _ from 'lodash'
import { useEffect, useMemo, useCallback } from 'react'
import Utils from '../../../../utils'
import { useFormMeta, useFormValues, useFormStatus, useFormState } from '.'

const parseErrorsHandler = (isMounted, callback, setFormMeta) => {
  return async function parseErrors(values, formState, formMeta) {
    if (!!isMounted && !_.isEmpty(formMeta)) {
      const errors = await callback(values, formState)
      _.map(formMeta, meta => {
        const related_error = _.find(errors, ['field', meta.field])
        const meta_error = _.get(meta, 'form_error') || null
        const new_error = _.get(related_error, 'message') || null
        if (!_.isEqual(meta_error, new_error))
          setFormMeta(_.get(meta, 'field'), { form_error: new_error });
      })
    }
  }
}

const useFormValidation = (callback, field_watchers = [], state_watchers = [], extra_watchers = []) => {
  const values = useFormValues()
  const isMounted = useFormStatus('isMounted')
  const [formState] = useFormState()
  const [formMeta, setFormMeta] = useFormMeta()

  const form_field_keys = useMemo(() => Utils.selectAllKeys(values, field_watchers || _.map(formMeta, 'field')), [field_watchers, formMeta])
  const form_state_keys = useMemo(() => Utils.selectAllKeys(formState, state_watchers), [state_watchers, formMeta])

  const parseErrors = useCallback(parseErrorsHandler(isMounted, callback, setFormMeta), [isMounted, callback, setFormMeta])

  useEffect(() => {
    parseErrors(values, formState, formMeta)
  }, [form_field_keys.join(','), form_state_keys.join(','), extra_watchers.join(',')])
}

export default useFormValidation
