import _ from 'lodash'
import useSearchListenerCount from '../useSearchListenerCount'
import useSearchListenerOptions from '../useSearchListenerOptions'
import useSearchListenerResult from '../useSearchListenerResult'
const useSearchListenerPagination = (model_name, listener_id) => {
  const result = useSearchListenerResult(model_name, listener_id)
  const count = useSearchListenerCount(model_name, listener_id)
  const search_options = useSearchListenerOptions(model_name, listener_id)
  const pageSize = parseInt(_.get(search_options, 'limit') || 0)
  const pageCount = !!pageSize ? _.ceil(count / pageSize) : 0
  const currentPage = !!pageSize ? _.floor(parseInt(_.get(search_options, 'skip') || 0) / pageSize) : 0
  return _.merge({}, {
    currentSize: (result || []).length,
    currentPage,
    pageSize,
    pageCount,
    count,
    sort: _.get(search_options, 'sort'),
  })
}

export default useSearchListenerPagination