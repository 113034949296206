import { useCallback } from 'react'
import _ from 'lodash'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '../../../provider'

const useModelListener = (model_name, listener_id, listener_type) => {
  useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider('store').getModel(model_name).prepareAttachedSubscription(listener_id, listener_type), [listener_id, listener_type]),
    useCallback(() => null, []),
    null,
    _.identity,
    isEqual
  )
  return useCallback(() => ProviderInstance.getProvider('store').getModel(model_name).getListener(listener_id), [model_name, listener_id])
}

export default useModelListener