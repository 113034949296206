import React, { useContext } from 'react'
import { ExternalContext } from '../../contexts/external'
import useCDNAsset from '../../hooks/relay/useCDNAsset'

const CDNGIF = ({ name, category = 'gif', variant, extension, style, children, ...rest }) => {
  const path = useCDNAsset(name, category, { variant, extension: extension || 'gif' })
  const { GIFRenderer } = useContext(ExternalContext)

  if (!path) return null

  return (
    <GIFRenderer
      style={{ resizeMode: 'contain', objectFit: 'contain', ...style }}
      source={{ uri: path }}
      {...rest}
    />
  )
}

export default React.memo(CDNGIF)

export {
  CDNGIF
}

