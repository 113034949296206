import _ from 'lodash'
import React, { createContext, useEffect, useCallback } from 'react'
import useProviderListener from '../../hooks/root/useProviderListener'
import { ProviderInstance } from '../../provider'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import isEqual from 'react-fast-compare'
import FormListener from '../../classes/providers/form/listener/form'
const FormContext = createContext({})
const FormContextProvider = FormContext.Provider

const Form = (props) => {
  const {
    name,
    children,
    ...remaining_props
  } = props
  const listener = useProviderListener(
    'form',
    name,
    FormListener.parseInfoFromProps(remaining_props),
    FormListener.parseOptionsFromProps(remaining_props),
    [],
    FormListener.getFormInfoWatchers(),
    FormListener.getFormOptionsWatchers()
  )
  const status = useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => ProviderInstance.getProvider('form').getFullListenerStatus(name), []),
    null,
    _.identity,
    isEqual
  )
  useEffect(() => () => ProviderInstance.getProvider('form').destroyListener(name), [name])

  const getForm = useCallback(() => ProviderInstance.getProvider('form').getForm(name), [name])

  const { isInitialized } = status || {}
  const contextValues = {
    name,
    status,
    getForm
  }
  return (
    <FormContextProvider value={contextValues}>
      {!!isInitialized && children}
    </FormContextProvider>
  )
}

export default React.memo(Form)

export {
  FormContext,
}