import { useContext, useMemo } from 'react'
import {
  FormContext,
} from '../..'

import _ from 'lodash'
const useFormProps = (props_name) => {
  const { props } = useContext(FormContext)
  return useMemo(() => !!props_name ? _.get(props, props_name) : props, [props, props_name])
}

export default useFormProps
