import _ from 'lodash'
import useModel from '../../store/useModel'

const useOrderHandlingAmount = (order_id, duration) => {
  const [order] = useModel('order', [order_id], { single: true, populate: ['commercial_package', 'specific_parameter', 'driver_estimation.rate_parameter'] })
  const load_type = order?.commercial_package?.load_type
  const specific_parameter = order?.specific_parameter
  const rate_parameter = order?.driver_estimation?.rate_parameter
  const package_handling_total = _.ceil((duration || 0) / (_.get(rate_parameter, 'handling_slice') || 15)) * (_.get(rate_parameter, 'handling_rate') || 0)
  const user_handling_total = (_.get(specific_parameter, 'waiting_minute_rate') || 0) * _.max([(duration || 0) - (_.get(specific_parameter, 'included_waiting_minute') || 0), 0])
  const handling_total = load_type === 'user' ? user_handling_total : package_handling_total
  return parseFloat(handling_total)
}

export default useOrderHandlingAmount