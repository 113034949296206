import _ from "lodash";
import ProviderListener from "../../../../listener"
import Field from "../field"
import SetUtils from "../../../../../utils/set"
import ProviderUtils from "../../../../../utils/provider"

class Form extends ProviderListener {
  constructor(provider, listener_id, info, options) {
    super(provider, listener_id, info, options)
    this.fields = new Set()
    this.state = null
  }
  prepareFieldSubscription(subscribe_id, info, options) {
    return function getSubscription(callback) {
      const listener_instance = this.registerField(callback, subscribe_id, _.merge({}, info, { initialValue: this.getInitialValue(subscribe_id), form_name: this.getId() }), options)
      return () => listener_instance?.removeCallback(callback)
    }.bind(this)
  }
  prepareAttachedFieldSubscription(subscribe_id, listener_type) {
    return function getSubscription(callback) {
      const new_listener = this.registerField(callback, subscribe_id, null, { listener_type, callback_type: 'attached' })
      return () => this.unregisterField(new_listener?.removeCallback(callback, 'attached').getId());
    }.bind(this)
  }
  registerField(callback, subscribe_id, info, options) {
    return ProviderUtils.recycleListener(
      this.getParentProvider(),
      this.getFields(),
      Field,
      { callback, subscribe_id, info, options }
    )
  }
  unregisterField(subscribe_id) {
    return ProviderUtils.destroyListener(this.getFields(), subscribe_id)
  }
  getFields() {
    return this.fields
  }
  getField(name) {
    return SetUtils.find(this.getFields(), (field) => field.getId() === name)
  }
  onParentEvent(event) {
    if (event?.name === 'config_updated' && !!_.get(this.getStatus(), 'isReady'))
      this.resetState()
    if (event?.key === 'result' && (!_.get(this.getStatus(), 'isReady') || this.isReadOnly())) {
      this.resetFields()
      this.setIsReady(true)
    }
    return super.onParentEvent(event)
  }
  onCreate(event, info, options) {
    this.resetState()
    if (!!this.isManagingModel()) {
      this.createModelListener(`parent_${this.getId()}`, this.getFormConfig('model_name'), {
        ids: this.getFormConfig('record_ids')
      }, {
        listener_type: 'basic',
        populate: this.getFormConfig('populate')
      })
    }
    else {
      this.setIsReady(true)
    }
    return super.onCreate()
  }
  onInfoUpdated() {
    if (!!this.isManagingModel()) {
      this.getParentListener()
        .setInfoKey('ids', this.getFormConfig('record_ids'))
        .setOptionKey('populate', this.getFormConfig('populate'))
    }
    return super.onInfoUpdated()
  }
  isReadOnly() {
    return this.getStateValue('isReadOnly')
  }
  isManagingModel() {
    return this.getStateValue('isManagingModel')
  }
  isManagingRecord() {
    return this.getStateValue('isManagingRecord')
  }
  getInitialValues() {
    if (this.isManagingModel()) {
      if (this.getFormOption('enableGrouping'))
        return _.groupBy(this.getParentListenerResult(), this.getFormOption('groupKey'))
      return this.getFormOption('singleMode') ? _.head(this.getParentListenerResult()) : this.getParentListenerResult()
    }
    return this.getInfoKey('initialValues')
  }
  getInitialValue(path) {
    return _.get(this.getInitialValues(), path)
  }
  getDefaultInitialState() {
    return {
      isReadOnly: !_.isEmpty(this.getFormConfig('record_ids'))
    }
  }
  getInitialState() {
    return _.merge({},
      this.getDefaultInitialState(),
      this.getInfoKey('initialState'), {
      isManagingModel: !_.isEmpty(this.getFormConfigs()),
      isManagingRecord: !_.isEmpty(this.getFormConfig('record_ids')),
      model_name: this.getFormConfig('model_name'),
      record_id: this.getFormOption('singleMode') ? _.head(this.getFormConfig('record_ids')) : null,
    })
  }
  resetState() {
    return this.handleKeyChange('state_updated', 'state', this.getInitialState())
  }
  getState() {
    return this.state
  }
  getStateValue(path) {
    return _.get(this.getState(), path)
  }
  getFieldValue(name) {
    const field = this.getField(name)
    if (!!field)
      return field.getResult()
    return this.getInitialValue(name)
  }
  setFieldValue(key, value, callback_options) {
    return this.getField(key).setResult(value, callback_options)
  }
  resetFields() {
    return this.getFields().forEach((field) => field.reset())
  }
  resetForm() {
    this.setIsReady(false)
    this.resetState()
    this.resetFields()
    this.setIsReady(true)
  }
  static getFormInfoWatchers() {
    return [
      'initialState',
      'initialValues',
      'functions.getSuccessNotification',
      'functions.getFailedNotification',
      'functions.onSubmitSuccess',
      'functions.onSubmitFailed',
    ]
  }
  static parseInfoFromProps(props) {
    return _.merge({}, {
      initialState: props.initialState,
      functions: {
        getSuccessNotification: props.getSuccessNotification,
        getFailedNotification: props.getFailedNotification,
        onSubmitSuccess: props.onSubmitSuccess,
        onSubmitFailed: props.onSubmitFailed,
      }
    },
      !!props.model_name ? {
        config: !props.model_name ? null : {
          model_name: props.model_name,
          record_ids: _.compact(props.record_ids || [props.record_id]),
          populate: props.populate
        }
      } : {
        initialValues: props.initialValues
      })
  }
  static getFormOptionsWatchers() {
    return [
      'destroyOnUnmount',
      'submitChangesOnly',
      'includeReadOnly',
      'submitOnEmpty',
      'fastMode',
      'submitOnChange',
      'submitOnEnter',
      'submitOnReset',
      'submitOnMount',
      'submitWatchers',
      'closeOnSuccess',
      'closeOnFail',
      'disableParentSubmitListener',
      'disableParentDisabledListener',
      'singleMode',
      'enableGrouping',
      'groupKey'
    ]
  }
  static parseOptionsFromProps(props) {
    return _.pick(props, Form.getFormOptionsWatchers())
  }
  getDefaultFormOptions() {
    return {
      destroyOnUnmount: true,
      submitChangesOnly: this.getStateValue('isManagingRecord'),
      includeReadOnly: false,
      submitOnEmpty: false,
      fastMode: false,
      submitOnChange: false,
      submitOnEnter: false,
      submitOnReset: false,
      submitOnMount: false,
      submitWatchers: [],
      closeOnSuccess: true,
      closeOnFail: false,
      disableParentSubmitListener: false,
      disableParentDisabledListener: false,
      singleMode: true,
      enableGrouping: false,
      groupKey: this.getParentListener()?.getParentProvider().getPrimaryKey()
    }
  }
  getFormOptions() {
    return _.merge({}, this.getDefaultFormOptions(), this.getOptions())
  }
  getFormOption(key) {
    return _.get(this.getFormOptions(), key)
  }
  getDefaultFormFunctions() {
    return {
      getSuccessNotification: _.noop,
      getFailedNotification: _.noop,
      onSubmitSuccess: _.noop,
      onSubmitFailed: _.noop,
    }
  }
  getFormFunctions() {
    return _.merge({}, this.getDefaultFormFunctions(), this.getInfoKey('functions'))
  }
  getFormFunction(key) {
    return _.get(this.getFormFunctions(), key)
  }
  getFormConfigs() {
    return _.merge({}, this.getInfoKey('config'))
  }
  getFormConfig(key) {
    return _.get(this.getFormConfigs(), key)
  }
  submit() {

  }
}

export default Form