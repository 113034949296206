import _ from 'lodash'

import useActiveUser from '../user/useActiveUser'
import useModel from '../../store/useModel'
import HookUtils from '../../../utils/hooks'
import { useMemo } from 'react'

const useCurrentJob = (options) => {
  const [user, userState] = useActiveUser({ populate: ['vehicle'] })
  const job_id = useMemo(() => _.get(user, 'vehicle.current_job'), [HookUtils.getRecordsWatcher(_.get(user, 'vehicle'))])
  const [[job], jobState] = useModel('job', [job_id], options)
  return useMemo(() => [job, { isLoading: HookUtils.getLoadingState([userState, jobState]) }], [HookUtils.getRecordsWatcher(job), userState, jobState])
}


export default useCurrentJob