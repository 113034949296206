import React, { useContext, useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { FormContext } from '../../contexts/form'
import isEqual from 'react-fast-compare'
import _ from 'lodash'
const withSuperField = (Component) => (props) => {
  const {
    name,
    disabled
  } = props
  const { getForm } = useContext(FormContext)
  const getField = useCallback(() => getForm().getField(name), [getForm, name])
  const value = useSyncExternalStoreWithSelector(
    useCallback(getForm().prepareFieldSubscription(name, { disabled }), [getField, name]),
    useCallback(() => getForm().getFieldValue(name), []),
    null,
    _.identity,
    isEqual
  )

  const onChange = useCallback((v) => getField().onChange(v), [getField])
  const onFocus = useCallback((e) => getField().onFocus(e), [getField])
  const onBlur = useCallback((e) => getField().onBlur(e), [getField])
  const events = {
    onChange,
    onFocus,
    onBlur
  }
  const mergedProps = {
    value
  }
  return (
    <Component {...props} {...mergedProps} {...events} />
  )

}


export default withSuperField
