import { useContext, useMemo } from 'react'
import {
  FormContext,
} from '../..'

import _ from 'lodash'
const useFormStatus = (status_name) => {
  const { status } = useContext(FormContext)
  return useMemo(() => !!status_name ? _.get(status, status_name) : status, [status, status_name])
}

export default useFormStatus
