import _ from 'lodash'
import { useMemo } from 'react'
import HookUtils from '../../../utils/hooks'
import useActiveUser from '../user/useActiveUser'
const useCurrentLocation = () => {
  const [user, status] = useActiveUser({ populate: ['vehicle.current_location'] })
  const current_location = useMemo(() => (
    !_.get(user, 'vehicle.current_location.coordinates') ?
      null :
      _.merge({}, _.get(user, 'vehicle.current_location.coordinates'), { bearing: _.get(user, 'vehicle.current_location.bearing') })
  ), [HookUtils.getRecordsWatcher(_.get(user, 'vehicle.current_location'))])
  return [current_location, status]
}

export default useCurrentLocation
