import _ from 'lodash'
class Callbacks {
  static getFormattedRecordsHandler({ postFilter, sortBy, sortDirection, mutator, finalMutator }, { single, limit }) {
    return function getFormattedRecords(records) {
      const filtered_records = _.filter(records, postFilter)
      const mutated_records = _.map(filtered_records, mutator)
      const sorted_records = !!sortBy ? _.orderBy(mutated_records, sortBy, sortDirection) : mutated_records
      const limited_records = _.isUndefined(limit) ? sorted_records : _.take(sorted_records, limit)
      if (!!single) {
        if (limited_records.length > 1)
          console.warn("Requested single record but found multiple", { records: limited_records, single, limit })
        return finalMutator(_.head(limited_records))
      }
      return finalMutator(limited_records)
    }
  }
}

export default Callbacks