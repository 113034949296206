import useEstimationSelector from "./useEstimationSelector"
import useEstimationStatus from "./useEstimationStatus"
import _ from 'lodash'

const useSelectedEstimation = (commercial_package_id, estimation_type) => {
  const status = useEstimationStatus()
  const selector = useEstimationSelector()
  const selected_estimation = selector(commercial_package_id, estimation_type)
  return [selected_estimation, status]
}

export default useSelectedEstimation