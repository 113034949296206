import useModel from '../../store/useModel'
import TimeUtils from '../../../utils/time'
import { HookUtils } from '../../../utils'

const useOrderRequestedAt = (order_id, format, options) => {
  const [order, orderState] = useModel('order', [order_id], { single: true, populate: ['source'] })
  if (HookUtils.getLoadingState([orderState]))
    return null
  return TimeUtils.getFormat(TimeUtils.getDetailsMomentFront(order?.requestedAt, order?.source), format, options)
}

export default useOrderRequestedAt