import _ from 'lodash'

import useModel from '../../store/useModel'

const useRidemodeDurationAmount = (ridemodeparameter_id, duration) => {
  const [ridemode_parameter] = useModel('ridemodeparameter', [ridemodeparameter_id], { single: true })

  const duration_slice = _.get(ridemode_parameter, 'duration_slice') || 15
  const duration_time_slices = _.ceil(duration / (duration_slice * 60))
  const duration_rate = _.get(ridemode_parameter, 'duration_rate') || 0

  return duration_time_slices * duration_rate * (duration_slice / 60)

}

export default useRidemodeDurationAmount