import _ from "lodash";
import ProviderListener from "../../../listener"

class Listener extends ProviderListener {
  constructor(provider, listener_id, info, options) {
    super(provider, listener_id, info, options)
  }
  onCreate() {
    if (!_.isUndefined(this.getInfoKey('initial_value')) && !!_.isUndefined(this.result))
      this.getParentProvider().setValues([{ path: this.getId(), value: this.getInfoKey('initial_value') }])
    this._refresh()
    this.setIsLoading(false)
    return super.onCreate()
  }
  onInfoUpdated() {
    return this._refresh()
  }
  onOptionsUpdated() {
    return this._refresh()
  }
  _refresh() {
    return this.setResult(_.clone(_.get(this.getParentProvider().getState(), this.getId())))
  }
}

export default Listener