import _ from 'lodash'
import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import Callbacks from './callbacks'
import { useDialogs, useLocalField, useFieldMeta, useFormState, useFormStatus, useFormFunction, useFieldReporter, useSectionPath, useLocalSectionState, useFormName } from '../../provider/hooks'
import Utils from '../../utils'

const withField = (Component, defaultProps = {}) => ({
  name,
  normalize = _.identity,
  asyncNormalize = _.identity,
  afterChange = _.noop,
  afterUserChange = _.noop,
  multiple,
  required,
  autoFocus = false,
  allowNone = !required && !multiple,
  noneValue = null,
  clearOnEmpty = _.has(defaultProps, 'clearOnEmpty') ? _.get(defaultProps, 'clearOnEmpty') : !multiple && !allowNone,
  clearValue = allowNone ? noneValue : undefined,
  validate,
  readOnly: isFieldReadOnly,
  disabled,
  debounceTime = 0,
  options,
  selectKeys,
  matchKeys,
  watchKeys = matchKeys,
  validateOnBlur = _.has(defaultProps, 'validateOnBlur') ? _.get(defaultProps, 'validateOnBlur') : true,
  isReady = _.has(defaultProps, 'isReady') ? _.get(defaultProps, 'isReady') : undefined,
  validateOnChange = true,
  defaultValue,
  helperText,
  requiredText,
  disableFocusHandler,
  disableBlurHandler,
  nextField,
  section,
  alwaysInclude = false,
  getChangeDialog = _.noop,
  ...props
}) => {

  const inputRef = useRef()
  const [, { openDialog }] = useDialogs()
  const path = useSectionPath()
  const { isReadOnly: isSectionReadOnly } = useLocalSectionState()
  const full_name = _.flatten([section || path, name]).join('.')
  const [value, setFieldValue] = useLocalField(name)
  const [meta] = useFieldMeta(full_name)
  const [isFormReadOnly] = useFormState('isReadOnly')
  const isFormMounted = useFormStatus('isMounted')
  const isFormSubmitted = useFormStatus('isSubmitted')
  const isFormEditable = useFormStatus('isEditable')
  const isFieldWritable = useFormFunction('isFieldWritable')
  const form_name = useFormName()
  const isReadOnly = useMemo(
    () => !isFormMounted || !!isFieldReadOnly || isFormEditable === false || !!isSectionReadOnly || (!isFormReadOnly && !isFieldWritable(full_name)),
    [isFormMounted, isFieldReadOnly, isSectionReadOnly, isFormEditable, isFormReadOnly, isFieldWritable, full_name]
  )
  const isFieldDisabled = _.isUndefined(disabled) ? isReadOnly : disabled
  const isDisabled = useMemo(
    () => isFieldDisabled || isFormEditable === false || isFormReadOnly || !isFormMounted || _.isEmpty(meta) || (!isFormReadOnly && !isFieldWritable(full_name)),
    [isFieldDisabled, isFormReadOnly, isFormMounted, isFormEditable, meta, isFieldWritable, full_name]
  )
  const validateField = useCallback(Callbacks.validateFieldHandler(required, validate, requiredText), [required, validate, requiredText])
  const setNormalizedFieldValue = useCallback(
    Callbacks.setNormalizedFieldValueHandler(setFieldValue, normalize, validateField, { clearOnEmpty, clearValue, validateOnChange }),
    [setFieldValue, normalize, validateField, clearOnEmpty, clearValue, validateOnChange])
  const debouncedAfterUserChange = useCallback(_.debounce(afterUserChange, debounceTime), [afterUserChange, debounceTime])
  const applyChange = useCallback(
    Callbacks.applyChangeHandler(setNormalizedFieldValue, debouncedAfterUserChange, full_name, form_name),
    [setNormalizedFieldValue, debouncedAfterUserChange, full_name, isDisabled, form_name]
  )
  const onUserChange = useCallback(
    Callbacks.onUserChangeHandler(setNormalizedFieldValue, debouncedAfterUserChange, full_name, isDisabled, form_name, getChangeDialog, openDialog, value, applyChange),
    [setNormalizedFieldValue, debouncedAfterUserChange, full_name, isDisabled, form_name, getChangeDialog, openDialog, value, applyChange]
  )
  const debouncedAfterChange = useCallback(
    _.debounce(Callbacks.afterChangeHandler(isDisabled, afterChange, asyncNormalize, setNormalizedFieldValue, inputRef), debounceTime),
    [isDisabled, afterChange, asyncNormalize, setNormalizedFieldValue, debounceTime, inputRef]
  )
  const value_watcher = useMemo(
    () => !_.isEmpty(watchKeys) && !!_.isEmpty(selectKeys) ?
      _.map(_.compact(_.flatten([value])), (v) => Utils.selectAllKeys(v, watchKeys).join(',')).join(',') :
      _.compact(_.flatten([value])).join(','),
    [(watchKeys || []).join(','), value]
  )
  const [onFocus, onBlur, onError] = useFieldReporter(full_name, value, { isFieldReadOnly, isSectionReadOnly, isFieldDisabled, validateField, validateOnBlur, setNormalizedFieldValue, defaultValue, alwaysInclude, autoFocus })
  useEffect(() => {
    debouncedAfterChange(value, meta)
  }, [value_watcher])

  const requestFocus = useMemo(() => !!_.get(meta, 'focus'), [_.get(meta, 'focus')])
  const requestBlur = useMemo(() => !!_.get(meta, 'blur'), [_.get(meta, 'blur')])
  useEffect(() => {
    requestFocus && !disableFocusHandler && !!_.get(inputRef, 'current') && inputRef.current.focus()
  }, [requestFocus])
  useEffect(() => {
    requestBlur && !disableBlurHandler && !!_.get(inputRef, 'current') && inputRef.current.blur()
  }, [requestBlur])
  const active_error = _.get(meta, 'form_error') || _.get(meta, 'field_error') || _.get(meta, 'internal_error')

  const display_error = (!isDisabled || isFormEditable === false) && !!active_error && (!!_.get(meta, 'touched') || !!isFormSubmitted || !!_.get(meta, 'form_error') || isFormEditable === false)

  const mergedProps = {
    autoFocus,
    name: full_name,
    value,
    onChange: onUserChange,
    onFocus,
    onBlur,
    onError,
    readOnly: isReadOnly,
    disabled: isDisabled,
    options,
    required,
    meta,
    multiple,
    helperText: !!display_error ? active_error : helperText,
    error: !!display_error,
    inputRef,
    allowNone: allowNone && !defaultProps.disableNone,
    noneValue,
    isReady
  }
  const special_props = _.merge({}.mergedProps, !!matchKeys ? { matchKeys } : {}, !!selectKeys ? { selectKeys } : {})

  return <Component {...mergedProps} {...special_props} {...props} />
}

export default withField
