import utils from '@front/volcanion/utils'
import _ from 'lodash'
class Callbacks {
  static getOptionValueHandler(selectKeys, matchKeys, labelKeys, includeLabelInSelection) {
    return function getOptionValue(option) {
      const isCreateOption = _.intersection(_.keys(option), selectKeys).length === 0
      return !_.isEmpty(selectKeys) ?
        (!isCreateOption ? utils.selectFirstKey(option, selectKeys) : option) :
        _.pick(option, _.flatten([matchKeys, !!includeLabelInSelection ? labelKeys : []]))
    }
  }
  static getOptionLabelHandler(labelKeys) {
    return function getOptionLabel(option) {
      return utils.selectFirstKey(option, labelKeys)
    }
  }
  static getOptionKeyHandler(matchKeys) {
    return function getOptionKey(option) {
      return utils.selectFirstKey(option, matchKeys)
    }
  }
  static getOptionPropsHandler(inputGetOptionProps, disabledOptions, isOptionEqualValue, isFieldDisabled) {
    return function getOptionProps(option, selectorProps) {
      const input_props = inputGetOptionProps(option)
      const isDisabled = !!_.find(disabledOptions, (disabled_option) => isOptionEqualValue(option, disabled_option))
      return _.merge(!!isFieldDisabled ? {} : { disabled: isDisabled }, input_props)
    }
  }
  static getOptionsPropsHandler(inputGetOptionsProps, options, selectorProps) {
    return function getOptionsProps() {
      return inputGetOptionsProps(options, selectorProps) || {}
    }
  }
  static isValueEqualValueHandler(selectKeys, matchKeys) {
    return function isValueEqualValue(value1, value2) {
      return !_.isEmpty(selectKeys) ?
        _.difference(_.flatten([value1]), _.flatten([value2])).length === 0 :
        utils.isSameObject(value1, value2, matchKeys)
    }
  }

  static isOptionEqualValueHandler(isValueEqualValue, getOptionValue) {
    return function isOptionEqualValue(option, value) {
      return isValueEqualValue(getOptionValue(option), value)
    }
  }
  static isOptionEqualOptionHandler(isValueEqualValue, getOptionValue) {
    return function isOptionEqualOption(option1, option2) {
      return isValueEqualValue(getOptionValue(option1), getOptionValue(option2))
    }
  }
  static isValueSelectedHandler(selected_values, isValueEqualValue) {
    return function isValueSelected(value) {
      return !!_.find(_.compact(_.flatten([selected_values])), (selected_value) => isValueEqualValue(selected_value, value))
    }
  }
  static isOptionSelectedHandler(isValueSelectedHandler, getOptionValue) {
    return function isOptionSelected(option) {
      return isValueSelectedHandler(getOptionValue(option))
    }
  }
  static getValueOptionHandler(options, isOptionEqualValue) {
    return function getValueOption(value) {
      return _.find(options, (opt) => isOptionEqualValue(opt, value))
    }
  }

  static onValueChangeHandler(onChange, isValueEqualValue, existing_values, { multiple, allowDeselect, resetOnChange }) {
    return function onValueChange(value) {
      if (!!resetOnChange)
        return onChange(value)
      if (!!multiple) {
        const [matched_values, other_values] = _.partition(existing_values, (existing_value) => isValueEqualValue(existing_value, value))
        const matched_value = _.head(matched_values)
        if (!!allowDeselect && !!matched_value) {
          return onChange(other_values)
        }
        return onChange(_.uniq(_.compact(_.flatten([existing_values || [], value]))))
      }
      else return onChange(!!allowDeselect && isValueEqualValue(value, existing_values) ? undefined : value)
    }
  }
  static isOptionLoadingHandler() {
    return function isOptionLoading(option) {
      return _.get(option, '_complete') === false
    }
  }
  static clearValueHandler(onInputChange, onChange) {
    return async function clearValue() {
      await onInputChange()
      onChange(undefined)
    }
  }
}

export default Callbacks
