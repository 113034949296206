import _ from 'lodash'
import { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '../../../provider'
import { useFormActionListener } from '../../../provider/hooks'
import HookUtils from '../../../utils/hooks'
import useModelSearchSubscription from '../useModelSearchSubscription'
import useSearchListenerPagination from '../useSearchListenerPagination'
import useSearchListenerStatus from '../useSearchListenerStatus'
import useModelFormatter from '../useModelFormatter'

const useModelSearch = (model_name, method, options) => {
  const { watchers, ...config } = options || {}
  const [listener, listener_id, controls] = useModelSearchSubscription(model_name, method, config, watchers)
  const result = useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => _.compact(ProviderInstance.getProvider('store').getModel(model_name).getParentListenerResult(listener_id)) || [], [listener_id]),
    null,
    useModelFormatter(options),
    isEqual
  )
  const search_status = useSearchListenerStatus(model_name, listener_id)
  const pagination = useSearchListenerPagination(model_name, listener_id)
  useFormActionListener('reset', controls.reset, { disabled: !HookUtils.getReadyState([search_status]) })
  return [result, controls.filterSearch, _.merge({}, search_status, pagination), controls]
}

export default useModelSearch