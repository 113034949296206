import _ from "lodash"

class GenericError extends Error {
  constructor(message, obj) {
    super(_.isObject(message) ? message : obj)
    const info = _.isObject(message) ? message : obj
    this.message = !_.isObject(message) ? message : _.get(info, 'message')
    this.name = this.constructor.name
    this.code = _.get(info, 'code')
    this.context = _.get(info, 'context')
    this.trace_id = _.get(info, 'logger.trace_id')
    this.callstack = _.get(info, 'callstack') || []
    this.errorstack = _.compact(_.flatten([_.get(info, 'errorstack') || [], this.stack]))
    this.stack = _.get(info, 'stack') || this.stack
  }
  static getErrorKeys() {
    return ['message', 'name', 'code', 'context', 'stack', 'logger', 'callstack', 'errorstack', 'sql']
  }
  static load(err) {
    const picked_error = typeof err === 'string' ? { message: err } : _.pick(err, GenericError.getErrorKeys())
    const error_class = _.get(GenericError, _.get(err, 'name'))
    if (_.includes(_.keys(GenericError), _.get(err, 'name')))
      return new error_class(picked_error)
    else return new FatalError(_.merge({}, picked_error, { context: picked_error }))
  }
  getMessage() {
    return this.message
  }
  getFormattedMessage() {
    return `${this.getMessage()} (${this.getCode()})`
  }
  getCode() {
    return this.code
  }
  getContext() {
    return this.context
  }
  setContext(context) {
    this.context = context
    return this
  }
  getTraceID() {
    return this.trace_id
  }
}


class NotFoundError extends GenericError {
}
class MissingArgumentError extends GenericError {
}
class ArgumentError extends GenericError {
}
class MismatchError extends GenericError {
}
class AuthorizationError extends GenericError {
}
class TimeoutError extends GenericError {
}
class AlreadyExistsError extends GenericError {
}
class FatalError extends GenericError {
}
class ValidationError extends GenericError {
}
const ErrorList = {
  NotFoundError,
  MissingArgumentError,
  ArgumentError,
  MismatchError,
  AuthorizationError,
  TimeoutError,
  AlreadyExistsError,
  ValidationError,
  FatalError
}
export default _.reduce(ErrorList, (acc, error_class, error_name) => _.set(acc, error_name, error_class), GenericError)
