import _ from 'lodash'

import useModel from '../../store/useModel'
import useRidemodeDurationAmount from '../ridemode/useRidemodeDurationAmount'

const useEstimationDurationAmount = (estimation_id, duration) => {
  const [estimation] = useModel('estimation', [estimation_id], { single: true })
  return useRidemodeDurationAmount(estimation?.ridemode_parameter, duration)
}

export default useEstimationDurationAmount