import { useCallback } from "react"

import useFileDownload from "../../relay/useFileDownload"

const useOrderInvoice = (order_id) => {
  const downloadInvoice = useFileDownload('GET', 'order/download')
  const getDownloadBlob = useCallback(() => downloadInvoice({ order_id }), [downloadInvoice, order_id])

  return [getDownloadBlob]

}

export default useOrderInvoice