import SetUtils from "./set"
class ProviderUtils {
  static recycleListener(parent, listeners, listener_class, config) {
    const { callback, subscribe_id, info, options } = config || {}
    const { callback_type } = options || {}
    const existing_listener = SetUtils.find(listeners, (listener) => listener.getId() === subscribe_id)
    if (!!existing_listener) {
      const listener_instance = existing_listener.recycle(callback, info, options)
      if (callback_type !== 'attached')
        return listener_instance.setIsInitialized(true)
      return listener_instance
    }
    else {
      const listener_instance = new listener_class(parent, subscribe_id, info, options)
      listeners.add(listener_instance.recycle(callback, info, options))
      if (callback_type !== 'attached')
        return listener_instance.setIsInitialized(true)
      return listener_instance
    }
  }

  static destroyListener(listeners, subscribe_id) {
    const removed_listener = SetUtils.find(listeners, (listener) => listener.getId() === subscribe_id)?.destroy()
    if (!!removed_listener)
      SetUtils.remove(listeners, (listener) => listener.getId() === subscribe_id)
    return this
  }
}

export default ProviderUtils