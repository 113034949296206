import _ from 'lodash'
import md5 from 'md5'
import moment from "moment"
import isEqual from "react-fast-compare"
class Helpers {
  static getPopulateMap(populate) {
    const grouped_map = _.groupBy(populate, (elem) => _.head(_.split(elem, '.')))
    return _.reduce(grouped_map, (acc, paths, key) => {
      const cleaned_paths = _.compact(_.map(paths, (path) => _.slice(_.split(path, '.'), 1).join('.')))
      return _.set(acc, key, cleaned_paths)
    }, {})
  }
  static mergeChecksums(base, modifiers) {
    if (_.isEmpty(modifiers))
      return base
    return md5(_.compact(_.flatten([base, modifiers])).join(','))
  }
  static getDiff(key, existing, diff) {
    return !!_.get(existing, key) && !!_.get(diff, key) ? moment.utc(_.get(existing, key)).diff(moment.utc(_.get(diff, key)), 'milliseconds') : null
  }

  static mergeKeys(existing_record, diff) {
    return _.reduce(_.keys(diff), (key_acc, key) => {
      if (isEqual(_.get(key_acc, key), _.get(diff, key)))
        return key_acc
      return _.set(key_acc, key, _.get(diff, key))
    }, _.merge({}, existing_record))
  }
  static mergeRecords(existing_record, diff, reference) {
    if (!!existing_record) {
      const updatedAt_diff = Helpers.getDiff('updatedAt', existing_record, diff)
      const ref_updatedAt_diff = Helpers.getDiff('updatedAt', existing_record, reference)
      if (updatedAt_diff <= 0)
        return Helpers.mergeKeys(existing_record, diff)
      else {
        if (ref_updatedAt_diff <= 0)
          return Helpers.mergeKeys(existing_record, _.omit(diff, ['updatedAt']))
        return _.reduce(_.keys(_.omit(diff, ['updatedAt'])), (key_acc, key) => {
          if (isEqual(_.get(key_acc, key), _.get(reference, key)) && !isEqual(_.get(key_acc, key), _.get(diff, key)))
            return _.set(key_acc, key, _.get(diff, key))
          return key_acc
        }, _.merge({}, existing_record))
      }
    }
    else return Helpers.mergeKeys(reference, diff)
  }
}

export default Helpers
