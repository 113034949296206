import { useContext, useMemo } from 'react'
import {
  GlobalFormContext,
} from '../..'
import _ from 'lodash'
const useGlobalFormStatus = (form_name, status_name) => {
  const { getPartialState } = useContext(GlobalFormContext)
  const state = useMemo(() => getPartialState(form_name), [getPartialState, form_name])
  const stateValues = useMemo(() => _.get(state, 'values'), [state])
  const formState = useMemo(() => _.get(state, 'state'), [state])
  const isSubmitted = useMemo(() => _.get(state, 'submitted') || false, [state])
  const isSubmitting = useMemo(() => _.get(state, 'submitting') || false, [state])
  const isRemoved = useMemo(() => _.isEmpty(state), [state])
  const isMounted = useMemo(() => !_.isUndefined(stateValues), [stateValues])
  const isReadOnly = useMemo(() => _.get(formState, 'isReadOnly') && !_.isUndefined(formState), [formState])
  const isEditable = useMemo(() => _.get(formState, 'isEditable'), [formState])
  const status = useMemo(() => ({ isMounted, isRemoved, isSubmitted, isSubmitting, isReadOnly, isEditable }), [isMounted, isRemoved, isSubmitted, isSubmitting, isReadOnly, isEditable])
  return useMemo(() => !!status_name ? _.get(status, status_name) : status, [status, status_name])
}

export default useGlobalFormStatus
