import Utils from './main'
import moment from 'moment'
import _ from 'lodash'

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]
class ScheduleUtils {
  static exportSchedule = schedule => {
    const hasApplicableDays = _.has(schedule, 'applicable_days')
    return ({
      name: _.get(schedule, 'name'),
      type: _.get(schedule, 'type'),
      start_time: _.get(schedule, 'start_time'),
      end_time: _.get(schedule, 'end_time'),
      monday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'monday') : undefined,
      tuesday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'tuesday') : undefined,
      wednesday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'wednesday') : undefined,
      thursday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'thursday') : undefined,
      friday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'friday') : undefined,
      saturday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'saturday') : undefined,
      sunday: !!hasApplicableDays ? _.includes(_.get(schedule, 'applicable_days'), 'sunday') : undefined
    })
  }

  static loadSchedule = schedule => ({
    name: _.get(schedule, 'name'),
    start_time: Utils.mergeDateTime(moment(), moment(_.get(schedule, 'start_time'), 'HH:mm:ss')),
    end_time: Utils.mergeDateTime(moment(), moment(_.get(schedule, 'end_time'), 'HH:mm:ss')),
    applicable_days: _.compact(_.map(DAYS, day => !!_.get(schedule, day) ? day : false)),
    type: _.get(schedule, 'type')
  })
}

export default ScheduleUtils
