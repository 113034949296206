import _ from 'lodash'
import useModel from '../../store/useModel'
import { useRelayConstant } from '../../relay/'

const useOrderWaitingTime = (order_id) => {
  const [order] = useModel('order', [order_id], {
    single: true,
    populate: [
      'active_transport.preference',
      'specific_parameter',
      'commercial_package'
    ]
  })

  const NOSHOW_WAITING_MINUTES = useRelayConstant('NOSHOW_WAITING_MINUTES')

  return order?.specific_parameter?.max_waiting_time
    || ((order?.active_transport?.preference?.waiting_duration || 0) / 60)
    || order?.commercial_package?.max_waiting_time
    || NOSHOW_WAITING_MINUTES


}

export default useOrderWaitingTime
