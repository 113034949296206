import _ from 'lodash'
class Callbacks {
  static clearHandler(getListener) {
    return async function clear() {
      return getListener()?.setExternalResult(null)
    }
  }
  static searchHandler(getListener) {
    return async function search(filter, options, silent, extra) {
      const existing_options = getListener()?.getSearchOptions()
      return getListener()?.search(filter, _.merge({}, existing_options, options), silent, extra)
    }
  }
  static resetHandler(getListener) {
    return async function reset() {
      return getListener()?.resetSearch()
    }
  }
  static refreshHandler(getListener) {
    return function refresh() {
      return getListener()?.refreshSearch()
    }
  }
  static setPageSizeHandler(search, getListener) {
    return function setPageSize(pageSize) {
      return search(
        getListener().getLastCallArg('filter'),
        { limit: pageSize, skip: 0 })
    }
  }
  static changePageHandler(search, getListener) {
    return function changePage(page) {
      if (!getListener().getSearchOption('count')) {
        console.warn('Pagination is used without count')
      }
      const filter = getListener().getLastCallArg('filter')
      const count = getListener().getCount() || 0
      const pageSize = parseInt(getListener().getSearchOption('limit'))
      const pageCount = !!pageSize ? _.ceil(count / pageSize) : 0
      if (page >= pageCount) {
        console.warn("End of list reached")
        return search(filter)
      }
      if (page < 0) {
        console.warn("Beginning of list reached")
        return search(filter)
      }
      return search(filter, { skip: (parseInt(page) || 0) * pageSize }, { action: 'traverse' })
    }
  }
  static goNextHandler(changePage, getListener) {
    return function goNext() {
      const pageSize = parseInt(getListener().getSearchOption('limit'))
      const currentPage = !!pageSize ? _.floor(parseInt(getListener().getSearchOption('skip') || 0) / pageSize) : 0
      return changePage(currentPage + 1)
    }
  }
  static goPrevHandler(changePage, getListener) {
    return function goPrev() {
      const pageSize = parseInt(getListener().getSearchOption('limit'))
      const currentPage = !!pageSize ? _.floor(parseInt(getListener().getSearchOption('skip') || 0) / pageSize) : 0
      return changePage(currentPage - 1)
    }
  }
  static goFirstHandler(changePage, getListener) {
    return function goFirst() {
      return changePage(0)
    }
  }
  static goLastHandler(changePage, getListener) {
    return function goLast() {
      const pageSize = parseInt(getListener().getSearchOption('limit'))
      const pageCount = !!pageSize ? _.ceil(count / pageSize) : 0
      return changePage(pageCount - 1)
    }
  }
  static ChangeSortHandler(search, getListener) {
    return function ChangeSort(sort) {
      return search(getListener().getLastCallArg('filter'), { sort })
    }
  }
}

export default Callbacks
