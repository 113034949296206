import _ from 'lodash'
class MapUtils {
  static getInitialRegion(center, location) {
    const initialRegion = MapUtils.convertToRegion(center)
    const userRegion = MapUtils.convertToRegion(location)
    return userRegion || initialRegion || null
  }
  static convertToRegion(coordinates) {
    if (!!_.get(coordinates, 'lat') && !!_.get(coordinates, 'lng'))
      return {
        latitude: _.get(coordinates, 'lat'),
        longitude: _.get(coordinates, 'lng'),
        latitudeDelta: 0.0222,
        longitudeDelta: 0.0222
      }
    return null
  }
  static convertToCoordinates(region) {
    if (!!region)
      return { lat: region.latitude, lng: region.longitude }
    return null
  }
  static convertToCamera(coordinates, extra) {
    if (!!_.get(coordinates, 'lat') && !!_.get(coordinates, 'lng'))
      return _.merge({
        center: {
          latitude: parseFloat(_.get(coordinates, 'lat')),
          longitude: parseFloat(_.get(coordinates, 'lng')),
        },
        pitch: 0,
        heading: 0,
        zoom: 16
      }, extra)
    return null
  }
}

export default MapUtils
