import React, { useEffect, useCallback, useSyncExternalStore } from 'react'
import _ from 'lodash'
import { ProviderInstance } from '../../provider'
import useProviderStatusListener from '../../hooks/root/useProviderStatusListener'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import isEqual from 'react-fast-compare'

function FetchHandler() {
  useSyncExternalStoreWithSelector(
    useProviderStatusListener('fetch'),
    useCallback(() => ProviderInstance.getProvider('fetch').getPendingFetch(), []),
    null,
    _.identity,
    isEqual
  )
  useEffect(() => {
    ProviderInstance.getProvider('fetch').startRequest()
  })
  return null
}
export default React.memo(FetchHandler)
