import I18n from '../../../../utils/translation'
import _ from 'lodash'

const useTranslation = (prefix, value, { suffix, disabled, emptyValue, args } = {}) => {
  if (_.isEmpty(value))
    return _.isUndefined(emptyValue) ? '-' : emptyValue
  if (disabled)
    return value
  return I18n.t(_.compact([prefix, value, suffix]).join('.'), args)
}

export default useTranslation
