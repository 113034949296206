import _ from 'lodash'
class HookUtils {
  static getRecordsWatcher(...args) {
    return _.map(_.compact(_.flatten(args)), (arg) => {
      const records = _.isArray(arg) ? arg : [arg]
      return _.map(records, '_checksum').join(',')
    }).join(',')
  }
  static getModelWatcher(...args) {
    return _.map(_.compact(args), (arg) => {
      const models = _.isArray(arg) ? arg : [arg]
      return _.map(models, (model) => {
        const records = _.values(model)
        return _.map(records, '_checksum').join(',')
      }).join(',')
    }).join(',')
  }
  static getModelStaleWatcher(...args) {
    return _.map(_.compact(args), (arg) => {
      const models = _.isArray(arg) ? arg : [arg]
      return _.map(models, (model) => {
        const records = _.values(model)
        return _.map(records, '_stale').join(',')
      }).join(',')
    }).join(',')
  }
  static getLoadingState(states) {
    return _.reduce(_.flatten([states]), (acc, state) => acc || _.get(state, 'isLoading') || false, false)
  }
  static getReadyState(states) {
    return _.reduce(_.flatten([states]), (acc, state) => acc && (_.get(state, 'isReady') || !_.has(state, 'isReady')), true)
  }
  static getFailedState(states) {
    return _.reduce(_.flatten([states]), (acc, state) => acc || _.get(state, 'isFailed'), false)
  }
  static getPopulateLoading(record, paths) {
    const records = _.flatten(_.map(paths, (path) => _.get(record, path)))
    return !HookUtils.getRecordComplete(record) && HookUtils.getRecordsLoading(records)
  }
  static getRecordLoading(record) {
    return !HookUtils.getRecordComplete(record)
  }
  static getRecordsLoading(records) {
    return !_.every(records, HookUtils.getRecordComplete)
  }
  static getRecordComplete(record) {
    return !!_.get(record, '_complete')
  }
  static getRecordsComplete(records) {
    return !HookUtils.getRecordsLoading(records)
  }
}

export default HookUtils
