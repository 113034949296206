import _ from 'lodash'

import useActiveUser from '../user/useActiveUser'

import Format from './format'

const getFilterByModel = (model_name, role, roleFilter) => {
  switch (model_name) {
    case 'companyuser': return Format.companyuser(role, roleFilter)
    case 'companycontract': return Format.companycontract(role, roleFilter)
    case 'companyservice': return Format.companyservice(role, roleFilter)
    case 'order': return Format.order(role, roleFilter)
  }
}

const useCompanyFilter = (company_id, options) => {
  const { model_name } = options
  const [user] = useActiveUser({ populate: ['info', 'customerinfo', 'auth', 'user_companies.services'] })
  const companyuser = _.find(_.get(user, 'user_companies'), ['company', company_id])
  const administration_level = _.get(companyuser, 'administration_level')

  return getFilterByModel(
    model_name,
    administration_level,
    {
      company_id,
      services: _.uniq(_.map(_.get(companyuser, 'services'), 'companyservice_id')),
      contracts: _.uniq(_.map(_.get(companyuser, 'services'), 'contract'))
    }
  )
}

export default useCompanyFilter
