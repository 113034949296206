import _ from "lodash";
function getUpdatedMeta(metas, field, meta) {
  const [existing_metas, other_metas] = _.partition(metas, ['field', field])
  const existing_meta = _.merge({ field }, _.head(existing_metas))
  return _.orderBy(_.flatten([other_metas, _.merge({}, _.omit(existing_meta, !!_.has(existing_meta, 'field_error') ? ['field_error'] : []), meta)]), 'field')
}
function form_reducer(state, action) {
  if (!action.form) {
    console.warn("Missing form name", action)
    return state
  }
  switch (action.type) {
    case 'INIT':
      return _.set(_.merge({}, state), `${action.form}`, _.merge({}, action.data, _.pick(_.get(state, action.form), ['enabled_sections'])));
    case 'UPDATE_FIELD_META':
      const form_update_metas = _.get(state, `${action.form}.meta`) || []
      const final_meta = getUpdatedMeta(form_update_metas, action.field, action.data)
      return _.set(_.merge({}, state), `${action.form}.meta`, final_meta)
    case 'REMOVE_FIELD_META':
      const form_remove_metas = _.get(state, `${action.form}.meta`) || []
      const [, remaining_metas] = _.partition(form_remove_metas, ['field', action.field])
      return _.set(_.merge({}, state), `${action.form}.meta`, remaining_metas)
    case 'SET_FIELD_VALUE':
      return _.set(_.merge({}, state), `${action.form}.values.${action.field}`, action.data)
    case 'SET_STATUS':
      return _.set(_.merge({}, state), `${action.form}.status.${action.status}`, action.data)
    case 'SET_BULK_VALUES':
      return _.reduce(action.data, (acc, field_info) => {
        const { field, value, meta } = field_info
        const existing_meta = _.find(_.get(acc, `${action.form}.meta`), ['field', field])
        const updated_metas = getUpdatedMeta(_.get(acc, `${action.form}.meta`) || [], field, _.merge({}, meta, {
          dirty: !_.isEqual(_.get(existing_meta, 'initial'), value)
        }))
        _.set(acc, `${action.form}.meta`, updated_metas)
        if (!!_.has(field_info, 'value'))
          return _.set(acc, `${action.form}.values.${field}`, value)
        return acc
      }, _.merge({}, state))
    case 'ADD_SECTION_VALUES':
      const { section, values } = action.data
      return _.set(_.merge({}, state), `${action.form}.values.${section}`, _.flatten([_.get(state, `${action.form}.values.${section}`) || [], values]))
    case 'REMOVE_BULK_VALUES':
      return _.reduce(action.data, (acc, field_info) => {
        const { section, index, field } = field_info
        if (!!field)
          return _.omit(acc, _.compact([action.form, 'values', section, index, field]).join('.'))
        return _.set(acc, `${action.form}.values.${section}`, _.filter(_.get(acc, `${action.form}.values.${section}`), (v, i) => i !== index))
      }, _.merge({}, state))
    case 'RESET_VALUES':
      return _.set(_.merge({}, state), action.form, { state: action.initialState, values: action.initialValues })
    case 'CLEAR_FORM':
      return _.omit(_.merge({}, state), [`${action.form}.values`, `${action.form}.meta`])
    case 'CLEAR':
      return _.omit(_.merge({}, state), [action.form])
    case 'UPDATE_STATE':
      return _.reduce(action.value, (acc, val, key) => _.set(acc, `${action.form}.state.${key}`, val), _.merge({}, state))
    case 'UPDATE_STATE_KEY':
      return _.set(_.merge({}, state), `${action.form}.state.${action.key}`, action.value)
    case 'SUBMIT_START':
      return _.set(_.merge({}, state), `${action.form}.submitting`, true)
    case 'SUBMITTED':
      return _.set(_.set(_.merge({}, state), `${action.form}.submitting`, false), `${action.form}.submitted`, true)
    case 'ADD_ENABLED_SECTIONS':
      const final_add_sections = _.compact(_.uniq(_.flatten([_.get(state, `${action.form}.enabled_sections`) || [], action.data])))
      const transformed_add_state = _.set(_.merge({}, state), `${action.form}.enabled_sections`, final_add_sections)
      return _.isEmpty(final_add_sections) ? _.set(transformed_add_state, `${action.form}.state.isReadOnly`, true) : _.set(transformed_add_state, `${action.form}.state.isReadOnly`, false)
    case 'REMOVE_ENABLED_SECTIONS':
      const enabled_sections = _.get(state, `${action.form}.enabled_sections`) || []
      const available_sections = _.keys(_.get(state, `${action.form}.values`)) || []
      const final_sections = _.isEmpty(enabled_sections) ? available_sections : enabled_sections
      const final_remove_sections = _.compact(_.uniq(_.difference(final_sections, action.data)))
      const transformed_remove_state = _.set(_.merge({}, state), `${action.form}.enabled_sections`, final_remove_sections)

      return _.isEmpty(final_remove_sections) ? _.set(transformed_remove_state, `${action.form}.state.isReadOnly`, true) : _.set(transformed_remove_state, `${action.form}.state.isReadOnly`, false)
    default:
      return state
  }
}

export default form_reducer
