import { useCallback } from "react"
import { useMethodListenerResult } from "../../store"
import _ from 'lodash'

const useEstimationSelector = () => {
  const estimations = useMethodListenerResult('ridemode', 'package_estimations')

  return useCallback((commercial_package_id, estimation_type) => {
    const package_estimations = _.filter(estimations, ['commercial_package_id', commercial_package_id])
    if (package_estimations?.length > 1 && !estimation_type)
      return _.find(package_estimations, ['type', 'estimated'])
    return !!estimation_type && estimation_type !== 'special'
      ? _.find(package_estimations, ['type', estimation_type])
      : _.head(package_estimations)
  }, [estimations])
}

export default useEstimationSelector