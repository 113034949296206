import _ from "lodash"
import Provider from '..'
import Listener from './listener'
class Global extends Provider {
  constructor(id, root) {
    super(id, root)
    this.config = null
    this.state = {}
  }
  getState() {
    return this.state
  }
  getConfig() {
    return this.config
  }
  getListenerClass() {
    return Listener
  }
  getDefaultListenerStatus() {
    return { isReady: false, isLoading: false }
  }
  async rehydrateStorage() {
    this.state = await this.getProvider('storage').readPrefix('global') || {}
    return this.refreshAllListeners()
  }
  async updateStorage(paths) {
    const persist_list = _.get(this.getConfig(), 'persist') || []
    if (_.find(paths, (path) => !!_.find(persist_list, (persist_path) => _.startsWith(path, persist_path))))
      this.getProvider('storage').setPrefix('global', _.pick(this.getState(), persist_list))
  }
  async init(config) {
    this.config = config
    await this.rehydrateStorage()
    return super.init(true)
  }
  setValues(info) {
    _.map(info, ({ value, path }) => _.set(this.state, path, value))
    this.updateStorage(_.map(info, 'path'))
    this.refreshAllListeners()
    return this
  }
  clearValues(paths) {
    this.state = _.omit(this.state, _.flatten([paths]))
    this.updateStorage(paths)
    this.refreshAllListeners()
    return this
  }
  getDefaultListenerResult(listener_id, shallow_info) {
    return _.get(this.getState(), listener_id) || _.get(shallow_info, 'initial_value') || undefined
  }
}

export default Global