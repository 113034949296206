import _ from "lodash"
import Provider from ".."

function unpack(info) {
  return _.omit(
    _.reduce(info, (acc, info) =>
      _.reduce(info.record_ids, (record_acc, record_id) =>
        _.set(
          record_acc,
          [info.model_name, record_id].join('.'),
          _.uniq(_.compact(_.flatten([_.get(record_acc, [info.model_name, record_id].join('.')) || [], info.populate || []])))
        ),
        _.set(acc, [info.model_name, '_'].join('.'), [])
      ),
      {}
    ),
    _.map(info, (info) => [info.model_name, '_'].join('.'))
  )
}

function pack(tree) {
  return _.flatten(_.map(tree, (model_tree, model_name) => {
    const record_data = _.map(model_tree, (populate, record_id) => ({ record_id, populate }))
    const grouped_data = _.groupBy(record_data, (record_info) => _.compact(_.orderBy(record_info.populate)).join(','))
    return _.map(grouped_data, (group_info) => ({
      model_name,
      record_ids: _.uniq(_.compact(_.flatten(_.map(group_info, 'record_id')))),
      populate: _.uniq(_.compact(_.flatten(_.map(group_info, 'populate'))))
    }))
  }))
}
function mergeModelInfo(info1, info2) {
  return pack(unpack(_.flatten([info1, info2])))
}
function differenceModelInfo(info1, info2) {
  const info_tree = unpack(info1)
  const sanitized_tree = _.reduce(info2, (acc, remove_info) => {
    return _.reduce(remove_info?.record_ids, (record_acc, record_id) => {
      const record_path = [remove_info.model_name, record_id].join('.')
      const new_populate = _.difference(_.get(record_acc, record_path) || [], remove_info.populate || [])
      if (_.isEmpty(new_populate))
        _.unset(record_acc, record_path)
      else _.set(record_acc, record_path, new_populate)
      return record_acc
    }, acc)
  }, info_tree)
  return pack(sanitized_tree)
}
class Fetch extends Provider {
  constructor(id, root) {
    super(id, root)
    this.request_info = []
    this.fetching_info = []
    this.fetch_listener = null
  }
  async startRequest() {
    const remaining_info = differenceModelInfo(this.request_info, this.fetching_info)
    if (!_.isEmpty(remaining_info)) {
      try {
        this.fetching_info = mergeModelInfo(this.fetching_info, remaining_info)
        const result = await this.getProvider('relay').getMainContact().execute('POST', 'fetch', remaining_info)
        this.getProvider('store').setRecords(result)
      }
      catch (err) {
        console.warn("Error fetching records", err)
        this.requestRecords(remaining_info)
      }
      finally {
        this.fetching_info = differenceModelInfo(this.fetching_info, remaining_info)
        this.request_info = differenceModelInfo(this.request_info, remaining_info)
      }
    }
    return this.notifyProviderListeners()
  }
  getPendingFetch() {
    if (!this.getProvider('store').getProviderStatus() || _.isEmpty(this.request_info))
      return null
    return this.request_info
  }
  requestRecords(info) {
    this.request_info = mergeModelInfo(this.request_info, info)
    return this.notifyProviderListeners()
  }
  async init() {
    return super.init(true)
  }
}

export default Fetch