import Provider from ".."

class Storage extends Provider {
  constructor(id, root) {
    super(id, root)
    this.manager = null
  }
  getManager() {
    return this.manager
  }
  async init(config) {
    this.manager = config.manager
    return super.init(true)
  }
  async readPrefix(prefix) {
    return this.getManager().rehydrate(prefix)
  }
  async setPrefix(prefix, data) {
    return this.getManager().store(prefix, data)
  }
}

export default Storage