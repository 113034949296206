import _ from "lodash"
import Provider from '..'
import FormListener from './listener/form'
class FormProvider extends Provider {
  constructor(id, root) {
    super(id, root)
  }
  getListenerClass(info, options) {
    return FormListener
  }
  async init() {
    return super.init(true)
  }
  getForm(name) {
    return this.getListener(name)
  }
}

export default FormProvider