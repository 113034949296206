import { io } from "socket.io-client";
import _ from 'lodash'
import Provider from ".."

async function tick(interval = 1) {
  return new Promise(resolve => setTimeout(resolve, interval * 1000));
}
function getDifference(listener_ids, subscriber_ids) {
  const subscribe_ids = _.compact(_.difference(listener_ids, subscriber_ids))
  const unsubscribe_ids = _.compact(_.difference(subscriber_ids, listener_ids))
  if (!_.isEmpty(unsubscribe_ids) && !_.isEmpty(subscribe_ids))
    return { subscribe: subscribe_ids, unsubscribe: unsubscribe_ids }
  else if (!_.isEmpty(unsubscribe_ids))
    return { unsubscribe: unsubscribe_ids }
  else if (!_.isEmpty(subscribe_ids))
    return { subscribe: subscribe_ids }
  return null
}
class Socket extends Provider {
  constructor(id, root) {
    super(id, root)
    this.subscribers = {}
    this.search_subscribers = []
    this.manager = null
    this.boundOnSocketConnected = this.onSocketConnected.bind(this)
    this.boundOnSocketDisconnected = this.onSocketDisconnected.bind(this)
    this.boundOnModelChange = this.onModelChange.bind(this)
    this.boundOnRecordsChange = this.onRecordsChange.bind(this)
    this.boundOnWatcherEvent = this.onWatcherEvent.bind(this)
    this.boundOnKeepAlive = this.onKeepAlive.bind(this)
    this.boundOnKeepAliveResponse = this.onKeepAliveResponse.bind(this)
    this.ticker = setInterval(this.boundOnKeepAlive, 30000)
  }
  isConnected() {
    return this.getManager()?.connected
  }
  getManager() {
    return this.manager
  }
  async onKeepAliveResponse(refreshed_watcher_ids) {
    try {
      const missing_listeners = _.filter(this.getProvider('store').getSearchListeners(), (listener) => !!listener.isWatchEnabled() && !_.includes(refreshed_watcher_ids, listener.getWatcherId()))
      await Promise.all(_.map(missing_listeners, (listener) => listener.refreshSearch(true)))
    }
    catch (err) {
      console.warn("[VOC] Failed to handle keep alive response", err)
      await tick(5)
      return this.onKeepAliveResponse(refreshed_watcher_ids)
    }
  }
  onKeepAlive() {
    this.manager?.emit('keepalive', this.search_subscribers, this.boundOnKeepAliveResponse)
  }
  onSocketDisconnected() {
    console.log("[VOC] Socket disconnected")
    this.notifyProviderListeners()
    this.reconnect()
  }
  onSocketConnected() {
    this.notifyProviderListeners()
    this.getProvider('store').rehydrateServer()
    console.log("[VOC] Socket connected")
  }
  async init() {
    if (this.getProvider('auth').isAuthenticated()) {
      const default_server = this.getProvider('relay').getDefaultServer()
      const token = this.getProvider('auth').getAccessToken()
      this.manager = new io(`${parseInt(_.get(default_server, 'port')) === 443 ? 'wss' : 'ws'}://${_.get(default_server, 'host')}:${_.get(default_server, 'port')}`, {
        reconnection: false,
        autoConnect: false,
        withCredentials: true,
        transports: ['websocket'],
        protocols: ["VOC3"],
        auth: { token }
      })
      this.manager.on('connect', this.boundOnSocketConnected)
      this.manager.on('disconnect', this.boundOnSocketDisconnected)
      this.manager.on('model_changed', this.boundOnModelChange)
      this.manager.on('records_updated', this.boundOnRecordsChange)
      this.manager.on('watcher_updated', this.boundOnWatcherEvent)
      this.manager.connect()
      return super.init(true)
    }
    return false
  }
  deinit() {
    clearInterval(this.ticker)
    this.manager?.disconnect()
    this.manager?.off('connect', this.boundOnSocketConnected)
    this.manager?.off('disconnect', this.boundOnSocketDisconnected)
    this.manager?.off('model_changed', this.boundOnModelChange)
    this.manager?.off('records_updated', this.boundOnRecordsChange)
    this.manager?.off('watcher_updated', this.boundOnWatcherEvent)
    this.manager = null
    return super.deinit()
  }
  async resync(data) {
    if (!!this.manager) {
      try {
        console.log("[VOC] Making Socket request", data)
        const response = await new Promise((resolve, reject) => this.manager.emit('resync', data, (result, err) => !!err ? reject(err) : resolve(result)))
        console.log("[VOC] Received Socket response", response)
        return response
      }
      catch (err) {
        console.warn("[VOC] Failed to resync socket data, retrying...", err)
        await tick(3)
        return this.resync(data)
      }
    }
    return []
  }
  refresh() {
    const related_info = this.getProvider('store').getRelatedInfo()
    const grouped_records = _.compact(_.map(related_info, ({ record_ids, model_name }) => {
      const differences = getDifference(record_ids, _.get(this.subscribers, model_name))
      if (!differences)
        return null
      return _.merge({ model_name, record_ids }, differences);
    }))
    const removed_models = _.map(_.pick(this.subscribers, _.difference(_.keys(this.subscribers), _.map(related_info, 'model_name'))), (m_ids, m_name) => ({ model_name: m_name, record_ids: [], unsubscribe: m_ids }))
    const total_records = _.flatten([grouped_records, removed_models])
    if (_.isEmpty(total_records))
      return true
    if (!!this.manager) {
      this.manager.emit('resync_bulk', total_records)
      _.map(total_records, group => {
        if (_.isEmpty(group.record_ids))
          return _.unset(this.subscribers, group.model_name)
        return _.set(this.subscribers, group.model_name, group.record_ids)
      })
    }
    return this
  }
  refreshWatchers() {
    const watcher_ids = _.uniq(_.compact(_.map(this.getProvider('store').getSearchListeners(), (l) => l?.getWatcherId())))
    const new_watchers = _.difference(watcher_ids, this.search_subscribers)
    const extra_watchers = _.difference(this.search_subscribers, watcher_ids)
    if (!_.isEmpty(new_watchers))
      this.manager?.emit('subscribe', new_watchers)
    if (!_.isEmpty(extra_watchers))
      this.manager?.emit('unsubscribe', extra_watchers)
    this.search_subscribers = watcher_ids
    return this
  }
  reloadAll() {
    const related_info = _.filter(
      _.map(this.subscribers, (record_ids, model_name) => ({ model_name, record_ids })),
      ({ record_ids }) => !_.isEmpty(record_ids)
    )
    if (!_.isEmpty(this.search_subscribers))
      this.manager?.emit('subscribe', this.search_subscribers)
    if (_.isEmpty(related_info))
      return []
    return this.resync(related_info)
  }
  listen(event, func) {
    this.attach(event, func)
    return () => this.dettach(event, func)
  }
  attach(event, func) {
    this.manager?.on(event, func)
  }
  dettach(event, func) {
    this.manager?.off(event, func)
  }
  async reconnect() {
    if (!this.getProvider('auth').isAuthenticated()) return
    await tick(1)
    if (!!this.manager && !this.isConnected()) {
      console.log("[VOC] Reconnecting socket manager")
      this.manager.connect()
      return this.reconnect()
    }
  }
  onRecordsChange(data) {
    const { model_name, record_ids, event_name, full_diff, references } = data
    if (_.includes(event_name, '_removed'))
      this.getProvider('store').removeRecords([{ model_name, record_ids }])
    else this.getProvider('store').updateRecords([{ model_name, record_ids, full_diff, references }])
  }
  onModelChange(data) {
    const { model_name, record, event_name, full_diff } = data
    const record_id = _.get(record, this.getProvider('store').getModel(model_name).getPrimaryKey())
    if (_.includes(event_name, '_removed'))
      this.getProvider('store').removeRecords([{ model_name, record_ids: [record_id] }])
    else this.getProvider('store').updateRecords([{ model_name, record_ids: [record_id], full_diff, references: [record] }])
  }
  onWatcherEvent(event) {
    const listeners = this.getProvider('store').getModel(event.model_name)?.getSearchListeners()
    _.map(listeners, listener => {
      if (_.get(listener?.getExternalResult(), 'headers.x-watcher-id') === event.watcher_id)
        switch (event.type) {
          case 'index':
            return listener?.onSocketIndexUpdate(event)
          case 'result':
            return listener?.onSocketResultUpdate(event)
          case 'count':
            return listener?.onSocketCountUpdate(event)
          default:
            console.warn("Unknow watcher type in socket event", event)
            return null
        }
    })
  }
}
export default Socket

