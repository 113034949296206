import { useCallback } from 'react'
import _ from 'lodash'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '../../../provider'

const useSearchListenerOptions = (model_name, listener_id) => {
  return useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider('store').getModel(model_name).prepareAttachedSubscription(listener_id, 'search'), [listener_id]),
    useCallback(() => ProviderInstance.getProvider('store').getModel(model_name).getListener(listener_id)?.getExternalOptions(), []),
    null,
    _.identity,
    isEqual
  )
}

export default useSearchListenerOptions