import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { Form } from "..";
import { useModelFunction, useModelSchema, useModel, useRouter } from '../../hooks';
import Callbacks from './callbacks';
import HookUtils from '../../../utils/hooks';

const ModelForm = (props) => {
  const {
    record_id,
    model_name,
    onGenerate,
    onModify,
    populate,
    beforeSubmit = () => true,
    recordToForm = _.identity,
    recordToState = _.noop,
    getEmptyForm = () => ({}),
    getEmptyState = () => ({}),
    formToOptions = _.noop,
    formToRecord = _.identity,
    status,
    modifyChangesOnly = true,
    generateChangesOnly = false,
    create_fields = [],
    modify_fields = [],
    getSuccessNotification: inputGetSuccessNotification = _.noop,
    getFailedNotification: inputGetFailedNotification = _.noop,
    disableDefaultFailedNotification,
    disableDefaultSuccessNotification,
    disableDefaultNotification,
    initialState: inputInitialState,
    config,
    baseRoute,
    redirectOnModify = false,
    redirectOnGenerate = true,
    disableSubmitParser = false,
    children,
    ...rest
  } = props
  const [router] = useRouter()
  const [onDefaultGenerate] = useModelFunction(model_name, 'generate')
  const [onDefaultModify] = useModelFunction(model_name, 'modify')
  const model_schema = useModelSchema(model_name)
  const { primary_key } = model_schema
  const isManagingRecord = !!record_id
  const [record, record_status] = useModel(model_name, [record_id], { single: true, populate, ...config })
  const onSubmit = useCallback(
    Callbacks.onModelSubmitHandler(model_schema, formToRecord, formToOptions, record_id, onGenerate || onDefaultGenerate, onModify || onDefaultModify, { router, redirectOnModify, redirectOnGenerate, baseRoute, disableSubmitParser }),
    [model_schema, formToRecord, formToOptions, record_id, onGenerate, onModify, onDefaultGenerate, onDefaultModify, router, redirectOnModify, redirectOnGenerate, baseRoute, disableSubmitParser]
  )
  const getSuccessNotification = useCallback(Callbacks.getSuccessNotificationHandler(inputGetSuccessNotification, disableDefaultNotification, disableDefaultSuccessNotification), [inputGetSuccessNotification, disableDefaultNotification, disableDefaultSuccessNotification])
  const getFailedNotification = useCallback(Callbacks.getFailedNotificationHandler(inputGetFailedNotification, disableDefaultNotification, disableDefaultFailedNotification), [inputGetFailedNotification, disableDefaultNotification, disableDefaultFailedNotification])

  const initialValues = useMemo(() => !!record ? recordToForm(record) : getEmptyForm(), [recordToForm, HookUtils.getRecordsWatcher([record])])
  const initialState = useMemo(() => _.merge(
    { primary_key, record_id, model_name, isManagingRecord, isReadOnly: !!record_id },
    inputInitialState,
    !!record_id ? recordToState(record) : getEmptyState(),
  ), [recordToState, primary_key, record_id, model_name, isManagingRecord, record, inputInitialState])

  const formProps = {
    onSubmit,
    beforeSubmit,
    status: { isReady: HookUtils.getReadyState([status, record_status]), isLoading: HookUtils.getLoadingState([status, record_status]) },
    submitChangesOnly: !!isManagingRecord ? modifyChangesOnly : generateChangesOnly,
    enabled_fields: !!isManagingRecord ? modify_fields : create_fields,
    initialValues,
    initialState,
    closeOnSuccess: !!isManagingRecord,
    getSuccessNotification,
    getFailedNotification
  }
  return (
    <Form {...formProps} {...rest} >
      {children}
    </Form>
  )
}

export default React.memo(ModelForm)
