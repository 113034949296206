class Format {
  static companyuser(role, roleFilter) {
    switch (role) {
      case 'company': return { company: roleFilter?.company_id }
      case 'contract': return { services: { contract: roleFilter?.contracts } }
      case 'service': return { services: { companyservice_id: roleFilter?.services } }
    }
  }
  static companycontract(role, roleFilter) {
    switch (role) {
      case 'company': return { company: roleFilter?.company_id }
      case 'contract': return { companyservices: { contract: roleFilter?.contracts } }
      case 'service': return { companyservices: { companyservice_id: roleFilter?.services } }
    }
  }
  static companyservice(role, roleFilter) {
    switch (role) {
      case 'company': return { contract: { company: roleFilter?.company_id } }
      case 'contract': return { contract: roleFilter?.contracts }
      case 'service': return { companyservice_id: roleFilter?.services }
    }
  }
  static order(role, roleFilter) {
    switch (role) {
      case 'company': return { service: { contract: { company: { company_id: roleFilter?.company_id } } } }
      case 'contract': return { service: { contract: { companycontract_id: roleFilter?.contracts } } }
      case 'service': return { service: { companyservice_id: roleFilter?.services } }
    }
  }
}

export default Format
