import { useCallback } from 'react'
import {
  useGlobalState
} from '../../../hooks'
import _ from 'lodash'

const useDialogs = () => {
  const [dialogs, , , { setSubValue }] = useGlobalState('dialog')
  const setDialog = useCallback((name, extra, state, status) => setSubValue(name, { isOpen: status, extra, state }), [])
  const openDialog = useCallback((name, extra, state) => setSubValue(name, { isOpen: true, extra, state }), [])
  const closeDialog = useCallback((name) => setSubValue(name, { isOpen: false }), [])
  const toggleDialog = useCallback((name, extra, state) => !!_.get(dialogs, `${name}.isOpen`) ? closeDialog(name) : openDialog(name, extra, state), [dialogs, openDialog, closeDialog])
  return [dialogs, { setDialog, openDialog, closeDialog, toggleDialog }]
}

export default useDialogs
