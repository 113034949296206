import { useCallback, useSyncExternalStore } from "react"
import { ProviderInstance } from "../../../provider"
import useProviderStatusListener from "../../root/useProviderStatusListener"

function useSocketStatus() {
  const listener = useProviderStatusListener('socket')
  return useSyncExternalStore(
    listener,
    useCallback(() => ProviderInstance.getProvider('socket').isConnected(), [])
  )
}

export default useSocketStatus