import { useModel } from "../../../store"
import { useTranslation } from "../../translation"

const useTransactionStatus = (transaction_object, { translated, ...options } = {}) => {
  const { transaction_id } = transaction_object || {}
  const [transaction] = useModel('transaction', [transaction_id], { single: true })
  return useTranslation('transaction.status', transaction?.status, { disabled: !translated, ...options })
}

export default useTransactionStatus
