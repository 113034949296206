import { useMemo, useCallback } from 'react'
import {
  useDialogs
} from '../../../hooks'
import _ from 'lodash'

const useDialog = (name) => {
  const [dialogs, { openDialog: globalOpenDialog, closeDialog: globalCloseDialog, toggleDialog: globalToggleDialog }] = useDialogs()
  const openDialog = useCallback((extra, state) => globalOpenDialog(name, extra, state), [name, globalOpenDialog])
  const closeDialog = useCallback(() => globalCloseDialog(name), [name, globalCloseDialog])
  const toggleDialog = useCallback((extra, state) => globalToggleDialog(name, extra, state), [name, globalToggleDialog])
  const dialog = useMemo(() => _.get(dialogs, name) || {}, [name, dialogs])

  return [dialog, { openDialog, closeDialog, toggleDialog }]
}

export default useDialog