import _ from 'lodash'
import GenericError from '../../classes/error'
import I18n from '../../utils/translation'
class Callbacks {
  static getInjectedDispatchHandler(dispatch) {
    return function getInjectedDispatch(name) {
      return function injectedDispatch(data) {
        return dispatch(_.merge({}, data, { form: name }))
      }
    }
  }
  static getPartialStateHandler(state) {
    return function getPartialState(name) {
      return _.get(state, name) || {}
    }
  }
  static onSubmitResultHandler(result_type, form_name, dispatch, onResult, closeOnResult, openNotification, getNotification, listener) {
    return function onSubmitResult(result, final_values, extra, meta, state) {
      dispatch({ type: 'SUBMITTED' })
      listener.emit(form_name, { action: 'submitted', data: { result_type, result, extra } })
      !!onResult && onResult(result, final_values, extra, meta, state)
      !!closeOnResult && dispatch({ type: 'UPDATE_STATE_KEY', key: 'isReadOnly', value: true })
      const notification = getNotification(result, final_values, extra, meta, state)
      if (!_.isEmpty(notification)) openNotification(...notification)
      return result
    }
  }
  static onFormSubmitHandler(
    onSubmit,
    beforeSubmit,
    skipFormError,
    { values, meta, state },
    { submitOnEmpty, submitChangesOnly, includeReadOnly },
    { onSubmitSuccess, onSubmitFailed, dispatch }
  ) {
    return function onFormSubmit(extra, options) {
      if (!_.get(state, 'isReadOnly')) {
        const dirty_meta = !!submitChangesOnly ? _.filter(meta, (meta_info) => _.get(meta_info, 'dirty') || _.get(meta_info, 'include')) : meta
        const final_meta = !includeReadOnly ? _.filter(dirty_meta, (elem) => !elem?.readonly) : dirty_meta
        const error_meta = _.filter(meta, (m) => (!!m.form_error && !skipFormError) || !!m.field_error || !!m.internal_error)
        if (!_.isEmpty(error_meta) && !options?.skip_field_validation)
          return onSubmitFailed(new GenericError.ValidationError(I18n.t('voc.form.validation.failed'), { code: 'VALIDATION_FAILED', context: error_meta }), final_values, extra, meta, state)
        const final_values = _.reduce(final_meta, (acc, { field }) => {
          const field_value = _.get(values, field)
          if (_.isUndefined(field_value))
            return acc
          return _.set(acc, field, field_value)
        }, {})
        const canSubmit = beforeSubmit(final_values, extra, meta, state)

        if (!canSubmit) return

        if (!_.isEmpty(final_values) || (_.isEmpty(final_values) && submitOnEmpty)) {
          dispatch({ type: 'SUBMIT_START' })
          onSubmit(final_values, extra, meta, state)
            .then((result) => onSubmitSuccess(result, final_values, extra, meta, state))
            .catch((err) => onSubmitFailed(GenericError.load(err), final_values, extra, meta, state))
        }
        else {
          onSubmitSuccess(null, final_values, extra, meta, state)
        }
      }
    }
  }
  static resetHandler(dispatch, listener, name, initialValues, initialState) {
    return function reset() {
      dispatch({ type: 'RESET_VALUES', initialValues, initialState })
      listener.emit(name, { action: 'reset' })
    }
  }
  static onEmitterActionHandler(onFormSubmit, reset, submitOnChange) {
    return function onEmitterAction(event) {
      switch (_.get(event, 'action')) {
        case 'submit':
          onFormSubmit(event.data, event.options)
          break;
        case 'field_change':
          !!submitOnChange && onFormSubmit()
          break;
        case 'reset':
          reset()
          break;
        default:
          console.warn("Unknown action", event)
      }
    }
  }
}

export default Callbacks
