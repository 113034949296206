import { useActiveUser } from '../user/'

const useIsRoleManager = (args, options = {}) => {
  const [user] = useActiveUser({ populate: ['auth'] })
  const isManager = _.includes([
    'tKnpKj2vbv',
    'UI4IIstcWz',
    'dUwdVG4ztd',
    'tFmBLB2AFy'
  ], _.get(user, 'auth.role'))

  return isManager
}

export {
  useIsRoleManager
}
