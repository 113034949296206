import { useMemo } from 'react'
import { useGlobalForm } from '../../../hooks'
import _ from 'lodash'

const useGlobalFieldValues = (form_name, fields) => {
  const [values] = useGlobalForm(form_name)
  return useMemo(() => _.map(fields, (field) => _.get(values, field)), [fields.join(','), values])
}

export default useGlobalFieldValues
