import { useCallback } from "react"

import useFileDownload from "../../relay/useFileDownload"

const useVonageRecord = (vonage_record_id) => {
  const downloadRecord = useFileDownload('GET', 'vonagerecord/download', {}, { type: 'audio' })
  const getDownloadBlob = useCallback(() => downloadRecord({ vonage_record_id }), [downloadRecord, vonage_record_id])

  return [getDownloadBlob]

}

export default useVonageRecord