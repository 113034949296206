import _ from 'lodash'
import moment from 'moment-timezone'
import I18n from './translation'

class TimeUtils {
  static getFormat(datetime, format, options) {
    if (!datetime) return null
    switch (options?.mode) {
      case 'string':
        return moment.utc(datetime).local().format(format)
      case 'instance':
        if (format === 'local')
          return moment.utc(datetime).local()
        return moment.utc(datetime)
      case 'calendar':
      default:
        return moment.utc(datetime).local().calendar(null, I18n.t(`voc.${format || 'calendar_complete'}`))
    }
  }
  static getDatetimeOffset(datetime, timezone = moment.tz.guess()) {
    return moment.tz.zone(timezone).utcOffset(moment.utc(datetime).valueOf())
  }
  static getOffsetMomentBack(datetime, utc_offset = moment(datetime).utcOffset()) {
    if (!datetime) return null
    return moment.utc(datetime).local().utc(true).utcOffset(utc_offset).utc(true).format()
  }
  static getTimezoneMomentBack(datetime, timezone = moment.tz.guess()) {
    return TimeUtils.getOffsetMomentBack(datetime, TimeUtils.getDatetimeOffset(datetime, timezone))
  }
  static getDetailsMomentBack(datetime, details) {
    return TimeUtils.getTimezoneMomentBack(datetime, _.get(details, 'timezone'))
  }
  static getOffsetMomentFront(datetime, options, utc_offset = moment(datetime).utcOffset()) {
    if (!datetime) return null
    return moment.utc(datetime).utcOffset(-utc_offset).local(true).utc().format(options?.format)
  }
  static getTimezoneMomentFront(datetime, timezone, options) {
    const final_timezone = timezone || moment.tz.guess()
    return TimeUtils.getOffsetMomentFront(datetime, options, TimeUtils.getDatetimeOffset(datetime, final_timezone))
  }
  static getDetailsMomentFront(datetime, details, options) {
    return TimeUtils.getTimezoneMomentFront(datetime, _.get(details, 'timezone'), options)
  }

  static getDurationRange(min_duration, max_duration, step) {
    return _.dropWhile(
      _.range(0, max_duration + step, step),
      duration => duration < min_duration || duration === 0
    )
  }
  static getSecondsFormat(seconds, options) {
    const isExactDays = _.isInteger(seconds / 86400)
    const exact_days_format = _.compact([I18n.t('voc.day.short'), !isExactDays && `${I18n.t('voc.and')} `]).join(' ')
    const days_unit_format = !options?.hide_days_unit && `[${options?.days_unit || exact_days_format}]`

    return _.compact(_.flatten([
      seconds < 86400 ? [] : [
        'DDD',
        days_unit_format
      ],
      seconds < 3600 || !!isExactDays ? [] : [
        'HH',
        !!_.get(options, 'hide_hours_unit') ? null : `[${_.get(options, 'hours_unit') || 'h'}]`
      ],
      !!_.get(options, 'hide_minutes') || seconds < 60 || seconds > 86400 || !!isExactDays ? [] : [
        'mm',
        !!_.get(options, 'hide_minutes_unit') ? null : `[${_.get(options, 'minutes_unit') || 'min'}]`
      ],
      !!_.get(options, 'hide_seconds') || seconds > 86400 ? [] : [
        'ss',
        !!_.get(options, 'hide_seconds_unit') ? null : `[${_.get(options, 'seconds_unit') || 'sec'}]`
      ]
    ])).join('')
  }
  static secondsToTime = (secs, format, options) => {
    const generated_format = !!format ? format : TimeUtils.getSecondsFormat(secs || 0, options)
    if (_.isEmpty(generated_format))
      return null
    else
      return moment.utc().startOf('year').add(secs || 0, 'seconds').format(generated_format)
  }
  static formatCalendar = (datetime, scheduleType, immediate_text) => {
    const momentValue = moment.utc(datetime)
    if (scheduleType === 'immediate')
      return immediate_text || 'Pick-up Now'
    return momentValue.local().calendar(null, I18n.t('voc.calendar'))
  }
  static mergeMoments(date, time) {
    if (!date || !time) return null
    let dateMoment = date
    if (!moment.isMoment(date))
      dateMoment = !!date ? moment(date) : moment()
    let timeMoment = time
    if (!moment.isMoment(time))
      timeMoment = !!time ? moment(time) : moment()
    if (timeMoment.isValid() && dateMoment.isValid()) {
      return moment(`${dateMoment.format('YYYY/MM/DD')} ${timeMoment.format('HH:mm')}`, 'YYYY/MM/DD HH:mm')
    }
    return null
  }

  static getSpecificFormat(time, format) {
    if (!time) return ''
    if (format === 'calendar')
      return moment.utc(time).local().calendar(null, I18n.t('voc.calendar'))
    else return moment.utc(time).local().format(format)
  }


  static formatDateFrontToBack = date => (!_.isEmpty(date)
    ? moment(date)
    : moment()
  ).utc().toISOString()

  static formatDateBackToFront = (date, format, defaultValue) =>
    _.isEmpty(date)
      ? defaultValue
      : moment.utc(date).local().format(format || 'DD/MM/YYYY HH:mm')

}

export default TimeUtils
