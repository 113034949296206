import _ from 'lodash'
import ProviderUtils from '../../utils/provider'
import SetUtils from '../../utils/set'
class Provider {
  constructor(id, root) {
    this._id = id
    this._root = root
    this.listeners = new Set()
    this.status_listeners = new Set()
    this.isReady = false
  }
  getReadyStatus() {
    return this.isReady
  }
  getProviderId() {
    return this._id
  }
  getListenerClass(info, options) {
    return null
  }
  getListeners() {
    return this.listeners
  }
  getListener(subscribe_id) {
    return SetUtils.find(this.getListeners(), (listener) => listener.getId() === subscribe_id)
  }
  addListener(listener_instance) {
    this.listeners.add(listener_instance)
    return this
  }
  prepareSubscription(subscribe_id, info, options) {
    return function getSubscription(callback) {
      const listener_instance = this.updateOrCreateListener(callback, subscribe_id, info, options)
      return () => listener_instance?.removeCallback(callback, _.get(options, 'callback_type') || 'main')
    }.bind(this)
  }
  prepareStatusSubscription() {
    return function getSubscription(listener) {
      this.status_listeners.add(listener)
      return () => this.status_listeners.delete(listener)
    }.bind(this)
  }
  prepareAttachedSubscription(subscribe_id, listener_type) {
    return function getSubscription(callback) {
      const new_listener = this.updateOrCreateListener(callback, subscribe_id, null, { listener_type, callback_type: 'attached' })
      return () => this.destroyListener(new_listener?.removeCallback(callback, 'attached')?.getId());
    }.bind(this);
  }
  getRoot() {
    return this._root
  }
  setRoot(root) {
    this._root = root
    return this
  }
  getProvider(name) {
    return this.getRoot().getProvider(name)
  }
  getProviderStatus() {
    return this.isReady
  }
  setProviderStatus(status) {
    this.isReady = status || false
    return this.notifyProviderListeners()
  }
  notifyProviderListeners() {
    this.status_listeners.forEach((callback) => callback())
    return this
  }
  async init(status) {
    return this.setProviderStatus(status)
  }
  deinit() {
    return this.setProviderStatus(false)
  }
  getListenerResult(subscribe_id, shallow_info) {
    const listener = this.getListener(subscribe_id)
    if (!!listener)
      return listener.getResult()
    return this.getDefaultListenerResult(subscribe_id, shallow_info)
  }
  getParentListenerResult(subscribe_id, shallow_info) {
    const listener = this.getListener(subscribe_id)
    if (!!listener)
      return listener.getParentListenerResult()
    return this.getDefaultListenerResult(subscribe_id, shallow_info)
  }
  getDefaultListenerResult(subscribe_id, shallow_info) {
    return
  }
  getListenerStatus(subscribe_id) {
    const listener = this.getListener(subscribe_id)
    if (!!listener)
      return listener.getStatus()
    return this.getDefaultListenerStatus(subscribe_id)
  }
  getParentListenerStatus(subscribe_id) {
    const listener = this.getListener(subscribe_id)
    if (!!listener)
      return listener.getParentListenerStatus()
    return this.getDefaultListenerStatus(subscribe_id)
  }
  getFullListenerStatus(subscribe_id) {
    const listener = this.getListener(subscribe_id)
    if (!!listener)
      return listener.getFullListenerStatus()
    return this.getDefaultListenerStatus(subscribe_id)
  }
  getDefaultListenerStatus(listener_id) {
    const defaultFlags = { isCreated: false, isInitialized: false, isReady: false, isLoading: true, listener_id }
    const defaultStatus = _.merge({ listener_status: defaultFlags, parent_status: null }, defaultFlags)
    return defaultStatus
  }
  refreshAllListeners(callback_options) {
    this.getListeners().forEach((listener) => listener._refresh(callback_options))
    return this
  }
  updateOrCreateListener(callback, subscribe_id, info, options) {
    return ProviderUtils.recycleListener(
      this,
      this.getListeners(),
      this.getListenerClass(info, options),
      { callback, subscribe_id, info, options }
    )
  }
  destroyListener(subscribe_id) {
    return ProviderUtils.destroyListener(this.getListeners(), subscribe_id)
  }
}

export default Provider