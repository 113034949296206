import { GeoUtils } from "../../../utils"
import useModel from "../../store/useModel"
import useSelectedEstimation from "./useSelectedEstimation"
import _ from 'lodash'

const useSelectedEstimationDirections = (commercial_package_id, estimation_type, direction) => {
  const [selected_estimation, status] = useSelectedEstimation(commercial_package_id, estimation_type)
  const direction_id = direction === 'inbound' ? selected_estimation?.details?.params?.inbound_direction_id : selected_estimation?.details?.params?.outbound_direction_id
  const [directions] = useModel('addressdirectioncache', [direction_id], { single: true })
  const parsed_points = _.compact(_.flatten(
    _.map(directions?.steps, (step) => !_.isEmpty(step.points) ?
      GeoUtils.decodePoints(step.points) : [
        { lat: step?.start_location?.lat, lng: step?.start_location?.lng },
        { lat: step?.end_location?.lat, lng: step?.end_location?.lng }
      ]
    )
  ))
  return [
    _.filter(parsed_points, (point) => _.isFinite(point?.lat) && _.isFinite(point?.lng)),
    status
  ]
}

export default useSelectedEstimationDirections