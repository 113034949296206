import _ from 'lodash'
import errors from '../../../classes/error'
import I18n from '../../../utils/translation'
class Callbacks {
  static onModelSubmitHandler(model_schema, formToRecord, formToOptions, record_id, onGenerate, onModify, { router, redirectOnModify, redirectOnGenerate, baseRoute, disableSubmitParser }) {
    return async function onModelSubmit(data, extra, meta, state) {
      const disableRedirect = _.get(extra, 'disableRedirect')
      const { primary_key } = model_schema
      const create_base_route = _.compact(_.dropRight(_.split(_.get(router, 'location.pathname'), '/'))).join('/')
      const modify_base_route = _.get(router, 'location.pathname')
      const operation_base_route = !!record_id ? modify_base_route : create_base_route
      const base_route = baseRoute || operation_base_route
      const record = formToRecord(data, extra, meta, state)
      const options = formToOptions(data, extra, meta, state)
      if (!!record_id) {
        const result = !!disableSubmitParser ? await onModify(data, extra, meta, state) : await onModify({ filter: { [primary_key]: record_id }, updates: record, options: _.merge({ single: true }, options) })
        if (!!redirectOnModify && !disableRedirect && !_.isArray(result))
          router.navigate([base_route, _.get(result, primary_key)].join('/'))
        return result
      }
      else {
        const result = !!disableSubmitParser ? await onGenerate(data, extra, meta, state) : await onGenerate({ data: record, options: _.merge({ single: true }, options) })
        if (!!redirectOnGenerate && !disableRedirect && !_.isArray(result))
          router.navigate(`/${[base_route, _.get(result, primary_key)].join('/')}`)
        else if (!!redirectOnGenerate && !disableRedirect && _.isArray(result))
          router.navigate(`/${[base_route, _.get(_.head(result), primary_key)].join('/')}`)
        return result
      }
    }
  }
  static getSuccessNotificationHandler(inputGetSuccessNotification, disableDefaultNotification, disableDefaultSuccessNotification) {
    return function getSuccessNotification(result, data, extra, meta, state) {
      const notif = inputGetSuccessNotification(result, data, extra, meta, state)
      if (!_.isEmpty(notif))
        return notif
      else if (!disableDefaultNotification && !disableDefaultSuccessNotification)
        return ['Success', { variant: 'success' }]
      else return []
    }
  }
  static getFailedNotificationHandler(inputGetFailedNotification, disableDefaultNotification, disableDefaultFailedNotification) {
    return function getFailedNotification(err, data, extra, meta, state) {
      const notif = inputGetFailedNotification(err, data, extra, meta, state)
      if (err instanceof errors.ValidationError) {
        if (!_.isEmpty(notif))
          return notif
        else if (!disableDefaultNotification && !disableDefaultFailedNotification)
          return [I18n.t('voc.form.validation.failed'), { variant: 'error' }]
        else return []
      }
      else return notif
    }
  }
}

export default Callbacks
