class Constants {
  static getOrderStatuses() {
    return ['created', 'standby', 'attributed', 'canceled', 'complete']
  }
  static getTransportStatuses() {
    return ['planned', 'started', 'at_src', 'on_board', 'at_dst', 'off_board', 'complete', 'canceled']
  }
  static getBillingStatuses() {
    return ['to_check', 'to_validate', 'valid', 'transmit', 'closed']
  }
}

export default Constants
