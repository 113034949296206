const useBillingStatusColorList = () => ({
  to_check: "#E59400",
  to_validate: "#E59400",
  valid: '#4CAF50',
  transmit: '#0060AC',
  closed: '#E21818',
  complete: '#E21818'
})

export default useBillingStatusColorList
