import _ from "lodash"
import Helpers from "../helpers"
import isEqual from "react-fast-compare"
import md5 from "md5"
import GenericError from '../../error'
import Provider from ".."
import BasicListener from "./listener/basic"
import SearchListener from "./listener/search"
import MethodListener from "./listener/method"
import SetUtils from "../../../utils/set"
import TranslationUtils from "../../../utils/translation"

const defaultTruth = () => true

class Model extends Provider {
  constructor(id, root, schema) {
    super(id, root)
    this.schema = schema
    this.records = {}
    this.sticky_ids = []
  }
  getListenerClass(info, options) {
    switch (_.get(options, 'listener_type')) {
      case 'search':
        return SearchListener
      case 'method':
        return MethodListener
      case 'basic':
      default:
        return BasicListener
    }
  }
  getDefaultListenerResult(subscribe_id, shallow_info) {
    if (_.get(shallow_info, 'type') === 'basic') {
      const preFilter = _.get(shallow_info, 'preFilter') || defaultTruth
      const ids = _.get(shallow_info, 'ids') || []
      const filtered_ids = _.map(_.filter(this.getRecords(ids), preFilter), this.getPrimaryKey())
      return this.getPopulatedRecords(filtered_ids, _.get(shallow_info, 'populate') || [])
    }
  }
  getDefaultListenerStatus(subscribe_id) {
    return _.merge(super.getDefaultListenerStatus(subscribe_id), { isReady: false, isLoading: false, isFailed: false, err: null })
  }
  markSticky(ids) {
    this.sticky_ids = _.compact(_.uniq(_.flatten([this.sticky_ids, ids])))
    return this
  }
  removeSticky(ids) {
    this.sticky_ids = _.difference([this.sticky_ids, ids])
    return this
  }
  isRecordSticky(record_id) {
    return !!_.find(this.sticky_ids, record_id)
  }
  getSchema() {
    return this.schema
  }
  getMethods() {
    return _.get(this.getSchema(), 'methods')
  }
  getMethodInfo(name) {
    return _.find(this.getMethods(), ['name', name])
  }
  isMatchingArgs(listener_id, args) {
    return !listener_id || _.isEqual(this.getListener(listener_id)?.getMethodArgs(this.getListener(listener_id)?.getLastCallArgs()), args)
  }
  getMethod(name, listener_id, silent, extra) {
    return async function FunctionCall(args, headers, options) {
      const mutated_args = this.getListener(listener_id)?.getMethodArgs(args) || args
      try {
        const method_info = this.getMethodInfo(name)
        if (!method_info)
          throw new GenericError.ArgumentError(`Function ${name} is not defined in model ${this.getName()}`)
        this.getListener(listener_id)?.setLastCallArgs(args)
        this.getListener(listener_id)?.setLastArgs(mutated_args)
        if (!silent)
          this.getListener(listener_id)?.setIsLoading(true)
        this.getListener(listener_id)?.setIsFailed(false, null)
        const result = await this.getProvider('relay').getMainContact().execute(_.get(method_info, 'method'), _.get(method_info, 'full_path'), mutated_args, headers, _.merge({}, options, { raw: true }))
        if (this.isMatchingArgs(listener_id, mutated_args)) {
          this.getListener(listener_id)?.setExternalResult(result, extra)
          if (!silent)
            this.getListener(listener_id)?.setIsLoading(false)
        }
        if (!!_.get(options, 'raw'))
          return result
        return _.get(result, 'data')
      }
      catch (err) {
        if (this.isMatchingArgs(listener_id, mutated_args)) {
          this.getListener(listener_id)?.clear()
          this.getListener(listener_id)?.setIsFailed(true, err)
        }
        throw err
      }
    }.bind(this)
  }
  getName() {
    return _.get(this.getSchema(), 'name')
  }
  getAttributes() {
    return _.get(this.getSchema(), 'attr')
  }
  getModelKeys() {
    return _.compact(_.map(this.getAttributes(), (details, key) => !!_.get(details, 'model') ? key : null))
  }
  getCollectionKeys() {
    return _.compact(_.map(this.getAttributes(), (details, key) => !!_.get(details, 'collection') ? key : null))
  }
  getTranslationKeys() {
    return _.compact(_.map(this.getAttributes(), (details, key) => _.toLower(_.get(details, 'model')) === 'translation' ? key : null))
  }
  isModelKey(key) {
    return _.includes(this.getModelKeys(), key)
  }
  isCollectionKey(key) {
    return _.includes(this.getCollectionKeys(), key)
  }
  isTranslationKey(key) {
    return _.includes(this.getTranslationKeys(), key)
  }
  getAttribute(key) {
    return _.get(this.getAttributes(), key)
  }
  getPrimaryKey() {
    return _.get(this.schema, 'primary_key')
  }
  signRecord(record) {
    const _checksum = md5(JSON.stringify(_.values(_.pick(record, _.keys(this.getAttributes())))))
    return _.merge({}, record, { _checksum })
  }
  setRecords(records) {
    return _.map(_.compact(_.map(records, record => {
      const existing_record = _.get(this.records, _.get(record, this.getPrimaryKey()))
      const merged_record = Helpers.mergeRecords(existing_record, record, record)
      if (!isEqual(existing_record, merged_record)) {
        _.set(this.records, _.get(record, this.getPrimaryKey()), this.signRecord(merged_record));
        return merged_record
      }
      return null
    })), this.getPrimaryKey())
  }
  updateRecords(record_ids, diff, references) {
    return _.map(_.compact(_.map(record_ids, record_id => {
      const existing_record = _.get(this.records, record_id)
      const reference = _.head(references)
      const merged_record = Helpers.mergeRecords(existing_record, diff, reference)
      if (!isEqual(existing_record, merged_record)) {
        _.set(this.records, record_id, this.signRecord(merged_record));
        return merged_record
      }
      return null
    })), this.getPrimaryKey())
  }
  resetRecords(records) {
    const existing_ids = _.map(this.records, this.getPrimaryKey())
    this.records = _.reduce(records, (acc, record) => _.set(acc, _.get(record, this.getPrimaryKey()), this.signRecord(record)), {})
    return _.flatten([existing_ids, _.map(this.records, this.getPrimaryKey())])
  }
  removeRecords(record_ids) {
    this.records = _.omit(this.records, record_ids)
    return record_ids
  }
  getRecords(record_ids) {
    return _.uniq(_.compact(_.map(_.uniq(record_ids), (record_id) => _.get(this.records, record_id))))
  }
  getAllRecords(skip_sticky) {
    return _.values(_.omit(this.records, !!skip_sticky ? this.sticky_ids : []))
  }
  getRelatedModel(key) {
    const attr = this.getAttribute(key)
    return this.getProvider('store').getModel(_.get(attr, 'model') || _.get(attr, 'collection'))
  }
  getMethodListeners() {
    return SetUtils.filter(this.getListeners(), (l) => l instanceof MethodListener)
  }
  getSearchListeners() {
    return SetUtils.filter(this.getListeners(), (l) => l instanceof SearchListener)
  }
  getBasicListeners() {
    return SetUtils.filter(this.getListeners(), (l) => l instanceof BasicListener)
  }
  sanitizeRecord(record) {
    const collection_keys = this.getCollectionKeys()
    const model_keys = this.getModelKeys()
    return _.reduce(record, (record_acc, value, key) => {
      if (_.includes(collection_keys, key))
        return _.set(record_acc, key, _.map(value, (v) => _.get(v, this.getRelatedModel(key).getPrimaryKey())))
      else if (_.includes(model_keys, key))
        return _.set(record_acc, key, _.isObject(value) ? _.get(value, this.getRelatedModel(key).getPrimaryKey()) : value)
      else return _.set(record_acc, key, value)
    }, {})
  }
  flattenRecords(populated_data) {
    const collection_keys = this.getCollectionKeys()
    const model_keys = this.getModelKeys()
    const sanitized_records = _.map(_.flatten([populated_data]), (populated_record) => this.sanitizeRecord(populated_record))
    return _.reduce(_.flatten([collection_keys, model_keys]), (record_acc, associated_key) => {
      const associated_records = _.filter(_.flatten(_.compact(_.map(_.flatten([populated_data]), associated_key))), _.isObject)
      if (_.isEmpty(associated_records))
        return record_acc
      const nested_records = this.getRelatedModel(associated_key).flattenRecords(associated_records)
      return _.flatten([record_acc, nested_records])
    }, [{ model_name: this.getName(), records: sanitized_records }])
  }
  getRelatedInfo(record_ids, populate) {
    if (_.isEmpty(record_ids))
      return []
    const records = this.getRecords(record_ids)
    const extended_populate = _.flatten([populate || [], this.getTranslationKeys()])
    const existing_ids = _.map(records, this.getPrimaryKey())
    const missing_ids = _.difference(record_ids, existing_ids)
    const populate_map = Helpers.getPopulateMap(extended_populate)
    const incomplete_ids = _.map(_.filter(records, (record) => !_.every(_.keys(populate_map), (populate_key) => _.has(record, populate_key))), this.getPrimaryKey())
    return _.reduce(populate_map, (acc, populate_path, populate_key) => {
      const related_records = _.uniq(_.compact(_.flatten(_.map(records, populate_key))))
      return _.flatten([acc, this.getRelatedModel(populate_key).getRelatedInfo(related_records, populate_path)])
    }, [{ model_name: this.getName(), record_ids, missing_ids, incomplete_ids, populate: extended_populate }])
  }
  getPopulatedRecords(record_ids, populate) {
    const existing_records = this.getRecords(record_ids)
    const populate_map = Helpers.getPopulateMap(populate)
    const populated_records = _.map(existing_records, (record) =>
      _.reduce(populate_map,
        (acc, populate_path, populate_key) => {
          if (this.isCollectionKey(populate_key)) {
            const populate_records = _.uniq(_.get(record, populate_key) || [])
            const found_records = this.getRelatedModel(populate_key).getPopulatedRecords(populate_records, populate_path)
            const _complete = _.get(acc, '_complete') && (_.isEmpty(found_records) || (found_records.length === populate_records.length && _.every(found_records, (r) => !!r._complete)))
            _.set(acc, '_complete', _complete)
            _.set(acc, populate_key, found_records)
            _.map(found_records, (found_record) => _.set(acc, '_checksum', Helpers.mergeChecksums(_.get(acc, '_checksum'), _.get(found_record, '_checksum'))))
            return acc
          }
          else if (this.isModelKey(populate_key)) {
            if (!!_.get(record, populate_key)) {
              const found_record = _.head(this.getRelatedModel(populate_key).getPopulatedRecords([_.get(record, populate_key)], populate_path))
              _.set(acc, '_complete', _.get(acc, '_complete') && (!_.get(record, populate_key) || !!_.get(found_record, '_complete')))
              if (!!found_record) {
                _.set(acc, populate_key, found_record)
                return _.set(acc, '_checksum', Helpers.mergeChecksums(_.get(acc, '_checksum'), _.get(found_record, '_checksum')))
              }
            }
            return acc
          }
          return acc;
        }, _.merge({}, record, { _complete: true })
      )
    )
    const translated_records = _.map(populated_records, (record) => {
      return _.reduce(this.getTranslationKeys(), (acc, populate_key) => {
        const found_translation = _.head(this.getRelatedModel(populate_key).getRecords([_.get(record, populate_key)]))
        const parsed_locale = TranslationUtils.parseLocale(this.getProvider('relay').getLocale())
        const translated_key = _.get(found_translation, parsed_locale)
        const [translation_base] = _.split(populate_key, '_trkey')
        const translation_key = [translation_base, "translated"].join('_')
        return _.set(acc, translation_key, translated_key || _.get(acc, translation_base))
      }, _.merge({}, record))
    })
    const sanitized_records = _.map(translated_records, (record) => _.omit(record, _.difference(this.getCollectionKeys(), _.keys(populate_map))))
    return sanitized_records
  }

}
export default Model