import React, { useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import Callbacks from './callbacks';
import Memos from './memos'

import useFormProps from '../../provider/form/hooks/form/useFormProps'

const withSelector = (Component, defaultProps = {}) => (props) => {
  const {
    value,
    onChange,
    multiple = defaultProps.multiple,
    allowNone,
    noneLabel = 'Aucune',
    noneValue,
    noneFirst = false,
    resetOnChange = defaultProps.resetOnChange,
    selectKeys = ['value'],
    labelKeys: inputLabelKeys = ['label'],
    createKey,
    matchKeys = selectKeys,
    disabledOptions,
    controls,
    getOptionsProps: inputGetOptionsProps = _.noop,
    getOptionProps: inputGetOptionProps = _.noop,
    getOptionLabelProps: inputGetOptionLabelProps = _.noop,
    getOptionIcon = _.noop,
    getOptionIconProps = _.noop,
    allowDeselect = defaultProps.allowDeselect,
    disabled,
    required,
    options: inputOptions,
    selectFirst = !!required && !multiple,
    selectSingleOption = false,
    clearInvalid = !multiple,
    loadOnMount = false,
    loadOnFocus = _.has(defaultProps, 'loadOnFocus') ? defaultProps.loadOnFocus : true,
    includeLabelInSelection = false,
    isLoading,
    meta,
    onInputChange,
    watchers = [],
    clearOnWatchersChange = !_.isEmpty(watchers),
    ...custom
  } = props
  const noneOptions = !!allowNone ? [{ [_.head(selectKeys)]: noneValue, [_.head(inputLabelKeys)]: noneLabel }] : []
  const options = !!noneFirst ? _.flatten([noneOptions, inputOptions]) : _.flatten([inputOptions, noneOptions])
  const final_value = useMemo(() => Memos.getSanitizedValue(value, multiple), [value, multiple])
  const labelKeys = useMemo(() => _.uniq(_.compact(_.flatten([inputLabelKeys, createKey]))), [inputLabelKeys.join(','), createKey])
  const getOptionLabel = useCallback(Callbacks.getOptionLabelHandler(labelKeys), [labelKeys.join(',')])
  const getOptionValue = useCallback(Callbacks.getOptionValueHandler(selectKeys, matchKeys, labelKeys, includeLabelInSelection), [selectKeys.join(','), matchKeys.join(','), labelKeys.join(','), includeLabelInSelection])
  const getOptionKey = useCallback(Callbacks.getOptionKeyHandler(matchKeys), [matchKeys.join(',')])
  const isValueEqualValue = useCallback(Callbacks.isValueEqualValueHandler(selectKeys, matchKeys), [selectKeys.join(','), matchKeys.join(',')])
  const isOptionEqualValue = useCallback(Callbacks.isOptionEqualValueHandler(isValueEqualValue, getOptionValue), [isValueEqualValue, getOptionValue])
  const isOptionEqualOption = useCallback(Callbacks.isOptionEqualOptionHandler(isValueEqualValue, getOptionValue), [isValueEqualValue, getOptionValue])
  const isValueSelected = useCallback(Callbacks.isValueSelectedHandler(final_value, isValueEqualValue), [final_value, isValueEqualValue])
  const isOptionSelected = useCallback(Callbacks.isOptionSelectedHandler(isValueSelected, getOptionValue), [isValueSelected, getOptionValue])

  const getOptionLabelProps = useCallback(
    Callbacks.getOptionPropsHandler(inputGetOptionLabelProps, disabledOptions, isOptionEqualValue, disabled),
    [inputGetOptionLabelProps, disabledOptions, isOptionEqualValue, disabled]
  )

  const getOptionProps = useCallback(
    Callbacks.getOptionPropsHandler(inputGetOptionProps, disabledOptions, isOptionEqualValue, disabled),
    [inputGetOptionProps, disabledOptions, isOptionEqualValue, disabled])

  const getValueOption = useCallback(Callbacks.getValueOptionHandler(options, isOptionEqualValue), [options, isOptionEqualValue])
  const isOptionLoading = useCallback(Callbacks.isOptionLoadingHandler(), [])
  const onValueChange = useCallback(
    Callbacks.onValueChangeHandler(onChange, isValueEqualValue, final_value, { multiple, allowDeselect, resetOnChange }),
    [onChange, isValueEqualValue, final_value, multiple, allowDeselect, resetOnChange]
  )
  const clearValue = useCallback(Callbacks.clearValueHandler(onInputChange, onChange), [onInputChange, onChange])

  const getLabelKeys = useCallback(() => labelKeys, [labelKeys.join(',')])
  const getCreateKey = useCallback(() => createKey, [createKey])
  const submitOnMount = useFormProps('submitOnMount')
  const optionsWatcher = useMemo(() => _.map(options, getOptionKey).join(','), [options, getOptionKey])
  const valueWatcher = useMemo(() => _.map(_.compact(_.flatten([final_value])), (v) => getOptionKey(getValueOption(v))).join(','), [final_value, getOptionKey, getValueOption])
  const canSelect = useMemo(() => !disabled && !isLoading, [disabled, isLoading])
  const isSelectionEmpty = useMemo(() => _.isEmpty(_.compact(_.flatten([final_value]))), [valueWatcher])
  const isCurrentDisabled = !!_.get(getOptionProps(getValueOption(final_value)), 'disabled')
  const isValueValid = useMemo(() => !!multiple || isSelectionEmpty || !!_.find(options, (option) => isOptionEqualValue(option, final_value)), [multiple, optionsWatcher, isSelectionEmpty, valueWatcher, isOptionEqualValue])
  const firstOption = useMemo(() => _.find(options, (option) => !_.get(getOptionProps(option), 'disabled')), [optionsWatcher, getOptionProps])
  const shouldSelectFirstOption = useMemo(
    () => !submitOnMount && !!selectFirst && !!firstOption && !multiple && (isSelectionEmpty || !!isCurrentDisabled),
    [selectFirst, getOptionKey(firstOption), multiple, isSelectionEmpty, isCurrentDisabled, submitOnMount]
  )
  const shouldSelectSingleOption = useMemo(
    () => !!selectSingleOption && options.length === 1 && !multiple && (isSelectionEmpty || !!isCurrentDisabled),
    [selectSingleOption, options.length, multiple, isSelectionEmpty, isCurrentDisabled]
  )

  useEffect(() => {
    !isLoading && !!canSelect && (!!shouldSelectFirstOption || !!shouldSelectSingleOption) && onChange(getOptionValue(firstOption))
  }, [canSelect, shouldSelectFirstOption, shouldSelectSingleOption, getOptionKey(firstOption), isLoading])

  useEffect(() => {
    !!canSelect && !isValueValid && !!clearInvalid && onChange((!!selectFirst || !!selectSingleOption) ? getOptionValue(firstOption) : undefined)
  }, [canSelect, isValueValid])

  useEffect(() => {
    !!loadOnFocus && !!_.get(meta, 'active') && !!onInputChange && onInputChange()
  }, [_.get(meta, 'active')])

  useEffect(() => {
    !!loadOnMount && !disabled && _.isEmpty(watchers) && !!onInputChange && onInputChange()
  }, [disabled])
  useEffect(() => {
    !clearOnWatchersChange && !disabled && !_.isEmpty(watchers) && !!onInputChange && onInputChange()
    !!clearOnWatchersChange && !disabled && !_.isEmpty(watchers) && clearValue()
  }, [watchers.join(','), disabled])

  const selectorProps = {
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    getOptionLabelProps,
    getValueOption,
    isValueEqualValue,
    isOptionEqualValue,
    isOptionEqualOption,
    isValueSelected,
    isOptionSelected,
    isOptionLoading,
    getLabelKeys,
    getCreateKey,
    getOptionIcon,
    getOptionIconProps,
    clearValue,
    controls
  }

  const finalGetOptionProps = useCallback(opt => getOptionProps(opt, selectorProps), [selectorProps])

  const getOptionsProps = useCallback(
    Callbacks.getOptionsPropsHandler(inputGetOptionsProps, options, selectorProps),
    [inputGetOptionsProps, options, selectorProps])

  const finalProps = {
    selectorProps: {
      ...selectorProps,
      getOptionProps: finalGetOptionProps,
      getOptionsProps
    },
    value: final_value,
    multiple,
    onChange: onValueChange,
    meta,
    onInputChange,
    disabled,
    required,
    isLoading,
    options,
    displayEmpty: !!allowNone
  }


  return <Component {...finalProps} {...custom} />
}


export default withSelector
