import _ from "lodash"
import isEqual from "react-fast-compare"
import HookUtils from "../../utils/hooks"
/**
 * Class representing a generic Provider Listener
 */
class ProviderListener {
  constructor(provider, listener_id, info, options) {
    this.provider = provider
    this.listener_id = listener_id
    this.main_callbacks = new Set()
    this.attach_callbacks = new Set()
    this.parent_callbacks = new Set()
    this.children_callbacks = new Set()
    this.info = info
    this.options = options
    this.external_result = undefined
    this.result = undefined
    this.isLoading = false
    this.isReady = false
    this.isFailed = false
    this.err = null
    this.isInitialized = false
    this.parent_listener = null
    this.boundOnParentEvent = this._onParentEvent.bind(this)
  }
  createModelListener(listener_id, model_name, info, options) {
    this.parent_listener = this.getParentProvider().getProvider('store').getModel(model_name).updateOrCreateListener(
      this.boundOnParentEvent,
      listener_id,
      info,
      _.merge({}, options, { callback_type: 'parent' })
    )
    return this.getParentListener()
  }
  getParentListener() {
    return this.parent_listener
  }
  getParentListenerResult() {
    return this.getParentListener()?.getParentProvider()
      .getListenerResult(this.getParentListener().getId())
  }
  getParentListenerStatus() {
    if (!this.getParentListener())
      return null
    return this.getParentListener().getParentProvider()
      .getListenerStatus(this.getParentListener().getId())
  }
  getFullListenerStatus() {
    const listener_status = this.getStatus()
    const parent_status = this.getParentListenerStatus()
    return _.merge({ listener_status, parent_status }, listener_status, {
      isLoading: HookUtils.getLoadingState([listener_status, parent_status]),
      isReady: HookUtils.getReadyState([listener_status, parent_status]),
      listener_id: this.getId()
    })
  }
  onDestroy() {
    this.getParentListener()?.getParentProvider().destroyListener(this.getParentListener().getId())
    this.parent_listener = null
    return this
  }
  onCreate(event) {
    return this
  }
  onInfoUpdated(event) {
    return this
  }
  onOptionsUpdated(event) {
    return this
  }
  onResultUpdated(event) {
    return this
  }
  onExternalResultUpdated() {
    return this
  }
  onParentEvent(event) {
    return this
  }
  onLocalEvent(event) {
    return this
  }
  _onParentEvent(event) {
    if (!!this.isInitialized)
      this.onParentEvent(event, this.getInfo(), this.getOptions())
    return this.notifyCallbacks(event, { skip_local: true, skip_children: true })
  }
  _onLocalEvent(event) {
    if (event?.key === 'isInitialized' && !!event?.data)
      this.onCreate(event, this.getInfo(), this.getOptions())
    if (!!this.isInitialized) {
      if (event?.key === 'options')
        this.onOptionsUpdated(event, this.getInfo(), this.getOptions())
      if (event?.key === 'info')
        this.onInfoUpdated(event, this.getInfo(), this.getOptions())
      if (event?.key === 'result')
        this.onResultUpdated(event, this.getInfo(), this.getOptions())
      if (event?.key === 'external_result')
        this.onExternalResultUpdated(event, this.getInfo(), this.getOptions())
      this.onLocalEvent(event, this.getInfo(), this.getOptions())
    }
  }
  handleKeyChange(name, key, data, callback_options) {
    if (!isEqual(this[key], data)) {
      const prev_value = _.clone(this[key])
      this[key] = data
      this.notifyCallbacks({
        name,
        key,
        data,
        prev_value,
      }, callback_options)
    }
    return this
  }
  handleConfigChange(key, config, callback_options) {
    return this.handleKeyChange('config_updated', key, config, callback_options)
  }
  handleStatusChange(key, status, callback_options) {
    return this.handleKeyChange('status_updated', key, status, callback_options)
  }
  setIsInitialized(status, callback_options) {
    return this.handleStatusChange('isInitialized', status, callback_options)
  }
  setIsLoading(status, callback_options) {
    this.handleStatusChange('isLoading', status, { skip_main: true, skip_attached: true })
    if (!status) this.setIsReady(true, { skip_main: true, skip_attached: true })
    if (!!status) this.setIsFailed(false, null, { skip_main: true, skip_attached: true })
    this.notifyCallbacks({ name: 'status_updated', key: 'isLoading', data: status }, _.merge({ skip_parent: true, skip_children: true }, callback_options))
    return this
  }
  setIsFailed(status, err, callback_options) {
    this.err = err
    this.handleStatusChange('isFailed', status, { skip_main: true, skip_attached: true })
    if (!!status) this.setIsLoading(false, { skip_main: true, skip_attached: true })
    this.notifyCallbacks({ name: 'status_updated', key: 'isFailed', data: status }, _.merge({ skip_parent: true, skip_children: true }, callback_options))
    return this
  }
  setIsReady(status, callback_options) {
    return this.handleStatusChange('isReady', status, callback_options)
  }
  getStatus() {
    return {
      isCreated: true,
      isInitialized: this.isInitialized,
      isReady: this.isReady,
      isLoading: this.isLoading,
      isFailed: this.isFailed,
      err: this.err,
      listener_id: this.getId()
    }
  }
  setResult(result, callback_options) {
    return this.handleKeyChange('result_updated', 'result', result, callback_options)
  }
  getResult() {
    return this.result
  }
  setExternalResult(result, extra) {
    return this.handleKeyChange('result_updated', 'external_result', result)
  }
  getExternalResult() {
    return this.external_result
  }
  getId() {
    return this.listener_id
  }
  setInfoKey(key, value, callback_options) {
    const new_info = _.set(_.clone(this.getInfo()), key, value)
    return this.setInfo(new_info, callback_options)
  }
  getInfoKey(key, default_value) {
    return _.isUndefined(_.get(this.getInfo(), key)) ? default_value : _.get(this.getInfo(), key)
  }
  setInfo(info, callback_options) {
    return this.handleConfigChange('info', info, callback_options)
  }
  getInfo() {
    return this.info
  }
  setOptions(options, callback_options) {
    return this.handleConfigChange('options', options, callback_options)
  }
  getOptions() {
    return this.options || {}
  }
  getOption(option) {
    return _.get(this.getOptions(), option)
  }
  setOptionKey(key, value, callback_options) {
    const new_options = _.set(_.clone(this.getOptions()), key, value)
    return this.setOptions(new_options, callback_options)
  }
  getParentProvider() {
    return this.provider
  }
  getMainCallbacks() {
    return this.main_callbacks
  }
  getAttachedCallbacks() {
    return this.attach_callbacks
  }
  getParentCallbacks() {
    return this.parent_callbacks
  }
  getChildrenCallbacks() {
    return this.children_callbacks
  }
  addCallback(callback, type) {
    switch (type) {
      case 'attached':
        this.attach_callbacks.add(callback)
        break;
      case 'parent':
        this.parent_callbacks.add(callback)
        break;
      case 'child':
      case 'children':
        this.children_callbacks.add(callback)
        break;
      case 'main':
      default:
        this.main_callbacks.add(callback)
    }
    return this
  }
  removeCallback(callback, type) {
    switch (type) {
      case 'attached':
        this.attach_callbacks.delete(callback)
        break;
      case 'parent':
        this.parent_callbacks.delete(callback)
        break;
      case 'child':
      case 'children':
        this.children_callbacks.delete(callback)
        break;
      case 'main':
        this.main_callbacks.delete(callback)
        break;
      default:
        this.main_callbacks.delete(callback)
        this.attach_callbacks.delete(callback)
        this.parent_callbacks.delete(callback)
        this.children_callbacks.delete(callback)
    }
    return this
  }
  notifyMainCallbacks(event) {
    this.getMainCallbacks().forEach((callback) => callback(event))
    return this
  }
  notifyAttachedCallbacks(event) {
    this.getAttachedCallbacks().forEach((callback) => callback(event))
    return this
  }
  notifyParentCallbacks(event) {
    this.getParentCallbacks().forEach((callback) => callback(event))
    return this
  }
  notifyChildrenCallbacks(event) {
    this.getChildrenCallbacks().forEach((callback) => callback(event))
    return this
  }
  notifyCallbacks(event, callback_options) {
    const { skip_main, skip_attached, skip_local, skip_parent, skip_children } = callback_options || {}
    const tagged_event = _.merge({}, event, { source: this, provider: this.getParentProvider(), source_id: this.getId() })
    !skip_parent && this.notifyParentCallbacks(tagged_event)
    !skip_local && this._onLocalEvent(tagged_event)
    !skip_main && this.notifyMainCallbacks(tagged_event)
    !skip_attached && this.notifyAttachedCallbacks(tagged_event)
    !skip_children && this.notifyChildrenCallbacks(tagged_event)
    return this
  }
  _refresh() {
    return this
  }
  recycle(callback, info, options) {
    const { callback_type, ...remaining_options } = options || {}
    if (callback_type === 'attached') {
      this
        .addCallback(callback, callback_type)
    }
    else {
      this
        .setInfo(info)
        .setOptions(remaining_options)
        .addCallback(callback, callback_type)
    }
    return this
  }
  destroy(force) {
    if (
      !!force || (
        this.getMainCallbacks().size + this.getParentCallbacks().size <= 1 &&
        this.getAttachedCallbacks().size === 0
      )
    ) {
      this.onDestroy()
      return this
    }
    return null
  }
}


export default ProviderListener
