import _ from 'lodash'
import { LocalContext } from '../..'
import { useContext } from 'react'
const useLocalContext = (key) => {
  const context = useContext(LocalContext)
  if (!key)
    return context
  return _.get(context, key)
}

export default useLocalContext