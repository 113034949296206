import _ from 'lodash'
import moment from 'moment'
import I18n from './translation'

class ValidationUtils {
  static parsePhone(value, country_code) {
    return (value || '')
      .replace(/([ .-]+)/g, '')
      .replace(/^00/g, '+')
      .replace(/^0([1-9])/g, `${country_code}$1`)
  }
  static validatePassword(password) {
    return [
      { type: 'lower', isValid: /^(?=.*[a-z]).+$/.test(password) },
      { type: 'upper', isValid: /^(?=.*[A-Z]).+$/.test(password) },
      { type: 'number', isValid: /^(?=.*[0-9]).+$/.test(password) },
      { type: 'length', isValid: /^.{8,}$/.test(password) }
    ]
  }
  static isPast(value) {
    return (
      moment.utc().isAfter(moment.utc(value)) ? I18n.t('date.past') : undefined
    )
  }
  static validateEmail(email) {
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      return I18n.t('voc.field.format')
    }
    return undefined
  }
  static validateGsm = (value) => {
    const hasError = !(_.startsWith(value, '+') || _.startsWith(value, '0')) || (value || '').length < 5
    return !!hasError ? I18n.t('voc.field.format') : undefined
  }


}

export default ValidationUtils
