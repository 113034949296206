import { hooks, I18n } from '@front/volcanion'
import { useCallback } from 'react'

const useRecordExist = (model_name, filterPath, options = {}) => {
  const [, updateMeta] = hooks.useFormMeta()
  const [, search] = hooks.useModelSearch(model_name, 'get')

  return useCallback(async (value, field) => {
    if (!value) return
    try {
      const filterOptions = _.set({}, filterPath, value)
      const result = await search(_.merge({}, filterOptions, options))
      updateMeta(field, { form_error: !_.isEmpty(result) ? I18n.t('voc.error.exist', { value }) : null })
    } catch (error) {
      console.log('Server Error : ', error)
    }
  }, [filterPath, options, updateMeta, search])
}

export default useRecordExist
