import { useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';
import _ from 'lodash'
import useProviderStatusListener from '../../hooks/root/useProviderStatusListener'
import { ProviderInstance } from "../../provider"
import isEqual from 'react-fast-compare';
const useVersion = () => {
  return useSyncExternalStoreWithSelector(
    useProviderStatusListener('relay'),
    useCallback(() => ProviderInstance.getProvider('relay').getDeviceInfo(), []),
    null,
    useCallback((device_info) => _.get(device_info, 'version_number'), []),
    isEqual
  )
}

export default useVersion
