import _ from 'lodash'
import useModel from '../../store/useModel'

const useExcludeHeatMap = (filter) => {
  const { user_id, companyservice_id } = filter || {}

  const [user] = useModel('user', [user_id], { single: true })
  const [companyservice] = useModel('companyservice', [companyservice_id], { single: true, populate: ['contract.company'] })

  const exclude_heatmap = _.some([companyservice?.contract?.company?.exclude_heatmap, companyservice?.exclude_heatmap, companyservice?.contract?.exclude_heatmap, user?.exclude_heatmap])
  return [exclude_heatmap]

}

export default useExcludeHeatMap
