import _ from 'lodash'
import TimeUtils from '../../../utils/time'
import useModel from '../../store/useModel'
const useRidemodeDurationOptions = (ridemode_id, format = 'HH[h]mm[min]') => {
  const [ridemode] = useModel('ridemode', [ridemode_id], { single: true })
  return _.map(
    TimeUtils.getDurationRange(ridemode?.min_duration, ridemode?.max_duration, ridemode?.duration_step),
    value => ({ label: TimeUtils.secondsToTime(value, format), value })
  )
}

export default useRidemodeDurationOptions