import _ from 'lodash'

class UserUtils {
  static getDisplayName = data => {
    const user = data && data.toJS ? data.toJS() : data
    const designation = _.get(user, 'customerinfo.name', null)

    if (designation) return designation
    else return _.compact([_.get(user, 'info.first_name'), _.get(user, 'info.last_name')]).join(' ')
  }
  static getFormatedName = ({ first_name, last_name, designation }, options) => {
    const { prefix, suffix, separator = ' - ' } = options || {}
    const fullname = _.compact([first_name, last_name]).join(' ') || designation
    return _.compact([prefix, fullname, suffix]).join(separator)
  }
}

export default UserUtils
