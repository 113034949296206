import _ from 'lodash'
class Callbacks {
  static onBlurHandler(setNormalizedFieldValue, onValidateField, updateFieldMeta, { validateOnBlur }) {
    return function onBlur(value, defaultValue) {
      if ((!value || (_.isObject(value) && _.isEmpty(value))) && !_.isUndefined(defaultValue)) {
        const df_value = typeof defaultValue === 'function' ? defaultValue() : defaultValue
        !!validateOnBlur ? setNormalizedFieldValue(df_value, { active: false, touched: true }) : updateFieldMeta({ active: false, touched: true, blur: false });
      }
      else !!validateOnBlur ? onValidateField(value, { active: false, touched: true, blur: false }) : updateFieldMeta({ active: false, touched: true, blur: false });
    }
  }
  static onFieldValidationHandler(validateField, updateFieldMeta) {
    return function onFieldValidation(value, extra_meta) {
      const error = validateField(value)
      return updateFieldMeta(_.merge({}, extra_meta, { field_error: error || null }))
    }
  }
}

export default Callbacks
