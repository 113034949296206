import _ from 'lodash'
import { useModelSearch } from '../../store'
import { useCallback } from 'react'
import { UserUtils } from '../../../utils'

const useCompanyUserInfo = (user_id, service_id) => {
  const [companyuser, , companyuser_state] = useModelSearch('companyuser', 'get', {
    initial_filter: {},
    single: true,
    populate: ['contact', 'user.auth', 'user.customerinfo'],
    forced_filter: {
      user: user_id,
      services: [service_id]
    },
    watchers: [user_id, service_id],
    validate_filter: useCallback(() => !!user_id && !!service_id, [user_id, service_id]),
  })
  return [{
    name: UserUtils.getFormatedName({
      first_name: companyuser?.contact?.first_name,
      last_name: companyuser?.contact?.last_name,
      designation: companyuser?.user?.customerinfo?.name,
    }),
    number: companyuser?.user?.auth?.gsm,
  }, companyuser_state]
}

export default useCompanyUserInfo
