import { useCallback, useContext } from 'react'
import { ExternalContext } from '../..'
import _ from 'lodash'

const useNotification = () => {
  const { useNotification: useNativeNotification } = useContext(ExternalContext)
  const { open: openNotification, close: closeNotification } = useNativeNotification()
  return {
    openNotification,
    closeNotification,
  }
}
export default useNotification