import useBillingStatusColorList from './useBillingStatusColorList'
import useModel from '../../store/useModel'

const useBillingStatusColor = order_object => {
  const { order_id } = order_object || {}
  const [order] = useModel('order', [order_id], { single: true })
  return useBillingStatusColorList()[_.get(order, 'billing_status')]
}

export default useBillingStatusColor
