import _ from 'lodash'
class Callbacks {
  static requestSubmitHandler(emitter, form_name, isReadOnly) {
    return function requestSubmit(extra, options) {
      if (!isReadOnly) {
        emitter.emit(form_name, { action: 'submit', form: form_name, data: extra || {}, options: options || {} })
      }
    }
  }
  static resetHandler(emitter, form_name) {
    return function reset() {
      emitter.emit(form_name, { action: 'reset', form: form_name })
    }
  }
  static reloadHandler(dispatch, isMounted, formValues, state, meta) {
    return function reload(values) {
      !!isMounted && dispatch({ type: 'INIT', data: { values: values || formValues, state, meta } })
    }
  }
  static onModeChangeHandler(dispatch) {
    return function onModeChange(initialValues, initialState) {
      if (!!_.get(initialState, 'isReadOnly')) {
        dispatch({
          type: 'INIT',
          data: { state: initialState }
        })
        return () => ({})
      }
      else {
        dispatch({
          type: 'INIT',
          data: { values: initialValues, state: initialState }
        })
        return () => dispatch({ type: 'CLEAR_FORM' })
      }
    }
  }
  static bulkChangeHandler(dispatch, isReadOnly) {
    return function bulkChange(data) {
      dispatch({
        type: 'SET_BULK_VALUES',
        data,
      })
    }
  }
  static bulkRemoveHandler(dispatch, isReadOnly) {
    return function bulkRemove(data, formName) {
      dispatch({
        type: 'REMOVE_BULK_VALUES',
        data,
      }, formName)
    }
  }
}

export default Callbacks
