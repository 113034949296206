import { useGlobalFormMeta } from '../../../hooks'

import _ from 'lodash'
const useGlobalSectionMeta = (form_name, path) => {
  const [formMeta] = useGlobalFormMeta(form_name)
  const sectionMeta = _.filter(formMeta, (field_meta) => _.startsWith(_.get(field_meta, 'field'), path.join('.')))
  return [sectionMeta]
}

export default useGlobalSectionMeta
