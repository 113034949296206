import _ from 'lodash'
import HookUtils from '../../../utils/hooks'
import useJobNextSteps from './useJobNextSteps'
import useJobCurrentStatus from './useJobCurrentStatus'
const user_list = ['planned', 'started', 'at_src', 'on_board', 'off_board', 'complete']
const package_list = ['planned', 'started', 'at_src', 'on_board', 'at_dst', 'off_board', 'complete']
const getNextStatus = (status, status_list) => {
  const current_index = _.indexOf(status_list, status)
  if (current_index === -1)
    return status
  return current_index < status_list.length - 1 ? status_list[current_index + 1] : status;
}
const useJobNextStatus = (input) => {
  const job_id = _.get(input, 'job_id')
  const [next_steps, stepState] = useJobNextSteps({ job_id }, { populate: ['order.commercial_package'] })
  const [current_status, currentStatusState] = useJobCurrentStatus({ job_id })
  const status_list = _.get(_.head(next_steps), 'order.commercial_package.load_type') === 'user' ? user_list : package_list
  const target_status = getNextStatus(current_status, status_list)
  return [target_status, { isLoading: HookUtils.getLoadingState([stepState, currentStatusState]) }]
}


export default useJobNextStatus