import _ from "lodash"
import { TimeUtils, GeoUtils } from "../../../utils"

class Callbacks {
  static refreshEstimatesHandler(getEstimates, clearEstimates, onEstimationComplete, old_estimations) {
    return async function refreshEstimates(values) {
      const {
        mode,
        user_id,
        service,
        requestedAt,
        returnAt,
        source_details,
        destination_details,
        duration,
        vehicle_opts,
        driver_opts,
        load_type,
        load_count,
        application,
        commercial_formula,
        schedule_type,
        codepromo_id,
        disable_destination_validate,
        disable_traffic_validate
      } = values
      if (!!mode && !_.isEmpty(source_details)) {
        const adapted_requestedAt = !!requestedAt ? TimeUtils.getDetailsMomentBack(requestedAt, source_details) : undefined
        const adapted_returnAt = !!returnAt ? TimeUtils.getDetailsMomentBack(returnAt, destination_details) : undefined
        const estimations = await getEstimates({
          mode,
          user_id,
          source: _.pick(source_details, GeoUtils.getAddressKeys()),
          destination: _.isEmpty(destination_details) ? undefined : _.pick(destination_details, GeoUtils.getAddressKeys()),
          duration,
          service,
          requestedAt: adapted_requestedAt,
          returnAt: adapted_returnAt,
          vehicle_opts,
          driver_opts,
          load_type,
          load_count,
          application,
          commercial_formula,
          schedule_type,
          codepromo_id,
          disable_destination_validate,
          disable_traffic_validate
        })
        !!onEstimationComplete && onEstimationComplete(estimations, old_estimations)
      }
      else await clearEstimates()
    }
  }
}

export default Callbacks
