import { useCallback, useSyncExternalStore } from "react"
import { ProviderInstance } from "../../../provider"
import useProviderStatusListener from "../../root/useProviderStatusListener"

function useCompatible() {
  const listener = useProviderStatusListener('auth')
  return useSyncExternalStore(
    listener,
    useCallback(() => ProviderInstance.getProvider('auth').isCompatible(), [])
  )
}

export default useCompatible