import { useCallback, useSyncExternalStore } from 'react'
import _ from 'lodash'
import useProviderStatusListener from "../root/useProviderStatusListener"
import { ProviderInstance } from "../../provider"
const useRelayConfig = (name) => {
  return useSyncExternalStore(
    useProviderStatusListener('relay'),
    useCallback(() => ProviderInstance.getProvider('relay').getExtra(name), [name])
  )
}

export default useRelayConfig