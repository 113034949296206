import _ from 'lodash'
import useModelSearch from '../../store/useModelSearch'
const useSetting = (setting_name) => {
  const [[value], reload, state] = useModelSearch('setting', 'get', {
    forced_filter: { name: setting_name },
    initial_filter: {},
    mutator: 'value'
  })
  return [value, reload, state]
}

export default useSetting
