import _ from 'lodash'
import { useModel } from '../../store'
import { UserUtils } from '../../../utils'

const useCustomerInfo = (user_id) => {
  const [user, userState] = useModel('user', [user_id], {
    single: true,
    populate: [
      'info',
      'auth',
      'customerinfo'
    ],
  })
  return [{
    name: UserUtils.getFormatedName({
      first_name: user?.info?.first_name,
      last_name: user?.info?.last_name,
      designation: user?.customerinfo?.name,
    }),
    number: user?.auth?.gsm,
  }, userState]
}

export default useCustomerInfo
