import _ from "lodash"
import Model from '../model'
import GenericError from "../../error"
import Provider from ".."
import SetUtils from "../../../utils/set"

class Store extends Provider {
  constructor(id, root) {
    super(id, root)
    this.config = null
    this.models = {}
  }
  getSchema() {
    return _.map(this.models, (model) => model.getSchema())
  }
  getDefaultListenerResult() {
    return []
  }
  getDefaultListenerStatus() {
    return { isReady: false, isLoading: true }
  }
  getConfig() {
    return this.config
  }
  async init(config) {
    this.config = config
    return false
  }
  async rehydrateServer() {
    if (_.isEmpty(this.models)) {
      const schema = await this.getProvider('relay').getMainContact().execute('GET', 'schema', {})
      _.reduce(schema, (acc, s) => _.set(acc, _.get(s, 'name'), new Model(_.get(s, 'name'), this.getRoot(), s)), this.models)
    }
    await this.reloadStore()
    return super.init(true)
  }
  async reloadStore() {
    const auto_load_info = _.map(_.get(this.getConfig(), 'auto_load'), ({ model_name, func, params }) => {
      const model_methods = _.get(this.getModel(model_name).getSchema(), 'methods')
      const matched_method = _.find(model_methods, ['name', func || 'get'])
      return _.merge({ model_name }, matched_method, { params })
    })
    const results = _.flatten(await Promise.all(_.map(auto_load_info, async ({ model_name, full_path, method, params }) => {
      const model_result = await this.getProvider('relay').getMainContact().execute(method, full_path, params)
      return this.getModel(model_name).flattenRecords(model_result)
    })))
    _.map(results, ({ model_name, records }) => this.getModel(model_name).markSticky(_.map(records, this.getModel(model_name).getPrimaryKey())))
    const final_data = _.flatten([await this.getProvider('socket').reloadAll(), results])
    this.resetRecords(final_data)
    return this
  }
  addPopulatedRecords(model_name, records, skip_tree_shake) {
    return this.setRecords(this.getModel(model_name).flattenRecords(records), skip_tree_shake)
  }
  setRecords(result, skip_tree_shake) {
    const modified_records = _.map(result, info => {
      return { model_name: info.model_name, record_ids: this.getModel(info.model_name).setRecords(info.records) }
    })
    return this.onTreeUpdate(modified_records, skip_tree_shake)
  }
  updateRecords(result, skip_tree_shake) {
    const modified_records = _.map(result, info => {
      return { model_name: info.model_name, record_ids: this.getModel(info.model_name).updateRecords(info.record_ids, info.full_diff, info.references) }
    })
    return this.onTreeUpdate(modified_records, true)
  }
  removeRecords(result, skip_tree_shake) {
    const modified_records = _.map(result, info => {
      return { model_name: info.model_name, record_ids: this.getModel(info.model_name).removeRecords(info.record_ids) }
    })
    return this.onTreeUpdate(modified_records, skip_tree_shake)
  }
  resetRecords(result, skip_tree_shake) {
    const grouped_info = _.map(_.groupBy(result, 'model_name'), (info, model_name) => ({ model_name, records: _.flatten(_.map(info, 'records')) }))
    const modified_records = _.compact(_.map(this.models, model => {
      const related_records = _.get(_.find(grouped_info, ['model_name', model.getName()]), 'records') || []
      const reset_result = model.resetRecords(related_records)
      if (_.isEmpty(reset_result))
        return null
      return { model_name: model.getName(), record_ids: reset_result }
    }))
    return this.onTreeUpdate(modified_records, skip_tree_shake)
  }
  onTreeUpdate(records, skip_tree_shake) {
    _.map(this.getBasicListeners(), listener => listener.refreshIfRelated(records))
    !skip_tree_shake && this.shakeTree()
    this.getProvider('socket').refresh()
    return records
  }
  getTreeInfo() {
    return _.compact(_.map(this.models, model => {
      const existing_ids = _.map(model.getAllRecords(true), model.getPrimaryKey())
      if (_.isEmpty(existing_ids))
        return null
      return { model_name: model.getName(), record_ids: existing_ids }
    }));
  }
  shakeTree() {
    const tree_info = this.getTreeInfo()
    const remove_info = _.compact(_.map(tree_info, ({ model_name, record_ids }) => {
      const related_model_info = _.find(this.getRelatedInfo(), ['model_name', model_name])
      if (!related_model_info)
        return { model_name, record_ids }
      const extra_ids = _.difference(record_ids, related_model_info.record_ids)
      if (_.isEmpty(extra_ids))
        return null
      return { model_name, record_ids: extra_ids }
    }))
    if (!_.isEmpty(remove_info))
      this.removeRecords(remove_info)
  }
  getRelatedInfo() {
    return _.map(
      _.groupBy(_.flatten(SetUtils.map(this.getBasicListeners(), (listener) => listener.getRelatedInfo())), 'model_name'),
      (group, model_name) => ({ model_name, record_ids: _.uniq(_.compact(_.flatten(_.map(group, 'record_ids')))) })
    )
  }
  getSearchListeners() {
    return _.flatten(_.map(this.models, (m) => m.getSearchListeners()))
  }
  getMethodListeners() {
    return _.flatten(_.map(this.models, (m) => m.getMethodListeners()))
  }
  getBasicListeners() {
    return _.flatten(_.map(this.models, (m) => m.getBasicListeners()))
  }
  getModel(model_name) {
    const found_model = _.get(this.models, _.toLower(model_name))
    if (!found_model)
      throw new GenericError.ArgumentError(`Unknown model ${model_name}`)
    return found_model
  }
  getModelMethod(model_name, function_name, listener_id) {
    return this.getModel(model_name).getMethod(function_name, listener_id)
  }
}

export default Store