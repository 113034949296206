import { useCallback, useEffect, useMemo, useReducer } from 'react'
import _ from 'lodash'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import useModelSearchSubscription from '../useModelSearchSubscription'
import useSearchListenerPagination from '../useSearchListenerPagination'
import useSearchListenerStatus from '../useSearchListenerStatus'
import HookUtils from '../../../utils/hooks'
import { useFormActionListener } from '../../../provider/hooks'
import { ProviderInstance } from '../../../provider'
const useModelSearchList = (model_name, method, options) => {
  const { watchers, ...config } = options || {}
  const [listener, listener_id, controls] = useModelSearchSubscription(model_name, method, config, watchers)
  const result = useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => _.compact(ProviderInstance.getProvider('store').getModel(model_name).getListenerResult(listener_id)) || [], []),
    null,
    _.identity,
    isEqual
  )
  const status = useSearchListenerStatus(model_name, listener_id)
  const pagination = useSearchListenerPagination(model_name, listener_id)
  useFormActionListener('reset', controls.reset, { disabled: !HookUtils.getReadyState([status]) })
  return [result, controls.filterSearch, _.merge({}, status, pagination), controls]
}

export default useModelSearchList