import { useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import _ from 'lodash'
import useProviderStatusListener from "../root/useProviderStatusListener"
import { ProviderInstance } from "../../provider"
import isEqual from 'react-fast-compare';
const useRelayFeatures = (names) => {
  return useSyncExternalStoreWithSelector(
    useProviderStatusListener('relay'),
    useCallback(() => ProviderInstance.getProvider('relay').getAllFeatures(), []),
    null,
    useCallback((features) => _.pick(features, names), [_.compact(names).join(',')]),
    isEqual
  )
}

export default useRelayFeatures