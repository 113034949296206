import useRouter from '../useRouter'

import _ from 'lodash'

const useNavigate = () => {
  const [router] = useRouter()
  return _.get(router, 'navigate')
}


export default useNavigate