import useModel from '../../store/useModel'
import useTranslation from '../translation/useTranslation'

const useStatus = (order_object, { translated, ...options } = {}) => {
  const { order_id } = order_object || {}
  const [order] = useModel('order', [order_id], { single: true })
  return useTranslation('order.status', order?.status, { disabled: !translated, ...options })
}

export default useStatus
