import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useGlobalForm } from '../../../hooks'
const useGlobalField = (form_name, field) => {
  const [values, bulkChange] = useGlobalForm(form_name)
  const value = useMemo(() => _.get(values, field), [values, field])
  const onChange = useCallback((new_value, meta) => bulkChange([{ field, value: new_value, meta }]), [field, bulkChange])
  return [value, onChange]
}

export default useGlobalField
