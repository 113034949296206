import _ from 'lodash'
import { useFormFunction, useFormStatus, useFormValues } from '../../../hooks'

const useForm = () => {
  const functions = useFormFunction()
  const status = useFormStatus()
  const values = useFormValues()
  return [values, _.get(functions, 'bulkChange'), status, functions]
}

export default useForm
