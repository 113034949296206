import { useContext, useCallback } from 'react'
import { FormContext } from '../..'
import { useFormName, useGlobalFormFunction } from '../../../hooks'
import _ from 'lodash'

const useFormFunction = (function_name) => {
  const form_name = useFormName()
  const { functions } = useContext(FormContext)
  const global_functions = useGlobalFormFunction(form_name)
  const merged_functions = _.merge({}, global_functions, functions)
  return useCallback(!!function_name ? _.get(merged_functions, function_name) : merged_functions, [merged_functions, function_name])
}

export default useFormFunction
