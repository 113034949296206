import _ from 'lodash'
import useModel from '../../store/useModel'

const useUserLanguage = ({ user_id }) => {
  const [language] = useModel('user', [user_id], { single: true, populate: ['info'], mutator: 'info.preferred_language' })
  return language
}

export default useUserLanguage

