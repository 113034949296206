import _ from "lodash"
import { useCallback, useState } from "react"
import useGlobalState from "../../global/useGlobalState"
const defaultLayout = { height: 0, width: 0, x: 0, y: 0 }
const useLayoutSize = (listener_id) => {
  const [layoutSize, setLayoutSize] = !!listener_id ? useGlobalState(listener_id, defaultLayout) : useState(defaultLayout)
  const onLayout = useCallback((e) => !_.isEqual(e?.nativeEvent?.layout, layoutSize) ? setLayoutSize(e?.nativeEvent?.layout || defaultLayout) : null, [layoutSize])
  return [layoutSize, onLayout]
}

export default useLayoutSize
