import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useForm } from '../../../hooks'
const useField = (field) => {
  const [values, bulkChange] = useForm()
  const value = useMemo(() => _.get(values, field), [values, field])
  const onChange = useCallback((new_value, meta) => bulkChange([{ field, value: new_value, meta }]), [field, bulkChange])
  return [value, onChange]
}

export default useField
