import _ from 'lodash'
import useModelSearch from '../../store/useModelSearch'
import useRelayServer from '../../relay/useRelayServer'

const useDetailedCurrentDomain = (config) => {
  const { host, port } = useRelayServer()
  const endpoint = _.compact(_.flatten([host, parseInt(port) === 443 ? null : port])).join(':')
  const [[domain], , status] = useModelSearch('domain', 'get', _.merge({}, config, { initial_filter: { endpoint }, default_limit: 1 }))
  return [domain, status]
}


export default useDetailedCurrentDomain
