import React, { useState, useContext, useEffect } from 'react'
import { ExternalContext } from '../../contexts/external'
import useCDNAsset from '../../hooks/relay/useCDNAsset'
const CDNImage = ({ name, category = 'images', variant, extension, style, children, ...rest }) => {
  const path = useCDNAsset(name, category, { variant, extension: extension || 'png' })
  const [error, setError] = useState(false)
  const { ImageRenderer } = useContext(ExternalContext)

  useEffect(() => {
    setError(false)
  }, [category, name])


  if (!path) return null
  if (!!error) return children

  return (
    <ImageRenderer
      style={{ resizeMode: 'contain', objectFit: 'contain', ...style }}
      path={path}
      onError={(e) => {
        setError(true)
        e.stopPropagation()
      }}
      onLoadStart={() => setError(false)}
      {...rest}
    />
  )
}

export default React.memo(CDNImage)

export {
  CDNImage
}
