import { useContext, useCallback } from 'react'
import { useGlobalFormStatus } from '../../../../hooks'
import { GlobalFormContext } from '../../..'
import Callbacks from './callbacks'
import _ from 'lodash'

const useGlobalFormFunction = (form_name, function_name) => {
  const { getInjectedDispatch, getFormValues, getFormState, getFormMeta, getPartialState, emitter } = useContext(GlobalFormContext)
  const isReadOnly = useGlobalFormStatus(form_name, 'isReadOnly')
  const isMounted = useGlobalFormStatus(form_name, 'isMounted')
  const requestSubmit = useCallback(Callbacks.requestSubmitHandler(emitter, form_name, isReadOnly), [form_name, emitter, isReadOnly])
  const reset = useCallback(Callbacks.resetHandler(emitter, form_name), [emitter, form_name])
  const clear = useCallback(() => getInjectedDispatch(form_name)({ type: 'CLEAR_FORM' }), [form_name, getInjectedDispatch])
  const reload = useCallback(
    Callbacks.reloadHandler(getInjectedDispatch(form_name), isMounted, getFormValues(form_name), getFormState(form_name), getFormMeta(form_name)),
    [form_name, getInjectedDispatch, isMounted, getFormValues, getFormState, getFormMeta]
  )
  const addSection = useCallback((section, values) => getInjectedDispatch(form_name)({ type: 'ADD_SECTION_VALUES', data: { section, values } }), [form_name, getPartialState])
  const addEnabledSections = useCallback((sections) => getInjectedDispatch(form_name)({ type: 'ADD_ENABLED_SECTIONS', data: sections }), [form_name, getInjectedDispatch])
  const removeEnabledSections = useCallback((sections) => getInjectedDispatch(form_name)({ type: 'REMOVE_ENABLED_SECTIONS', data: sections }), [form_name, getInjectedDispatch])
  const getEnabledSections = useCallback(() => _.compact(_.flatten([_.get(getPartialState(form_name), 'enabled_sections') || [], !isReadOnly ? '_create' : null])), [form_name, getPartialState, isReadOnly])
  const isSectionEnabled = useCallback((section) => _.isEmpty(_.get(getPartialState(form_name), 'enabled_sections') || []) || _.some(getEnabledSections(), (enabled_section) => _.startsWith(section, enabled_section)), [form_name, getPartialState, getEnabledSections])
  const onModeChange = useCallback(Callbacks.onModeChangeHandler(getInjectedDispatch(form_name)), [form_name, getInjectedDispatch])
  const bulkChange = useCallback(Callbacks.bulkChangeHandler(getInjectedDispatch(form_name)), [form_name, getInjectedDispatch])
  const bulkRemove = useCallback(Callbacks.bulkRemoveHandler(getInjectedDispatch(form_name)), [form_name, getInjectedDispatch])

  const functions = {
    requestSubmit,
    reset,
    clear,
    reload,
    addSection,
    addEnabledSections,
    removeEnabledSections,
    getEnabledSections,
    isSectionEnabled,
    onModeChange,
    bulkChange,
    bulkRemove
  }
  return useCallback(!!function_name ? _.get(functions, function_name) : functions, [functions, function_name])
}

export default useGlobalFormFunction
