import _ from 'lodash'
import HookUtils from '../../../utils/hooks'
import useModel from '../../store/useModel'
import useJobNextSteps from './useJobNextSteps'

const useJobNextTransports = ({ job_id } = {}, options) => {
  const [next_steps, stepState] = useJobNextSteps({ job_id }, { populate: ['order'] })
  const [transports, transportState] = useModel('transport', _.map(next_steps, 'order.assigned_transport'), options)
  return [transports, { isLoading: HookUtils.getLoadingState([stepState, transportState]) }]
}


export default useJobNextTransports