import React, { createContext } from 'react'
import _ from 'lodash'

const ExternalContext = createContext({})
const ExternalContextProvider = ExternalContext.Provider

const ExternalProvider = (props) => {
  const { children, ...externalHooks } = props
  return (
    <ExternalContextProvider value={externalHooks}>
      {children}
    </ExternalContextProvider>
  )
}

export default React.memo(ExternalProvider)
export {
  ExternalContext
}