class FormatUtils {

  static distance = (value, precision = 2) => {
    if (!value) return null
    return value < 1000
      ? `${value}m`
      : `${(value / 1000).toFixed(precision)}km`
  }

  static parsePhoneNumber = (value, codeIntlPhone) => {
    if (!value) return undefined
    else {
      return (value || '').replace(/([ .-]+)/g, '')
        .replace(/^00/g, '+')
        .replace(/^0([1-9])/g, `${codeIntlPhone}$1`)
    }
  }

  static parseFMBPhoneNumber = (value, codeIntlPhone) =>
    (value || '').replace(/([ .-]+)/g, '')
      .replace(/^00/g, '+')
      .replace(/^0([1-9])/g, `+${codeIntlPhone}$1`)

  static intervalFilter = (start, end) => {
    if (!!start && !!end) return { '>=': start, '<=': end }
    else if (!!start && !end) return { '>=': start }
    else if (!start && !!end) return { '<=': end }
    else return undefined
  }
}

export default FormatUtils
