import _ from 'lodash'
import useRelayConstant from './useRelayConstant'
import qs from 'qs'
const useCDNAsset = (name, category, { variant, extension } = {}) => {
  const ASSETS_URL = useRelayConstant('ASSETS_URL')
  const SELF_SOURCE_ID = useRelayConstant('SELF_SOURCE_ID')
  if (!name) return null
  const query = qs.stringify({ source_id: SELF_SOURCE_ID, name, category, variant, extension })
  return `${ASSETS_URL}?${query}`
}

export default useCDNAsset