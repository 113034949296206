import { useCallback, useSyncExternalStore } from 'react'
import _ from 'lodash'
import useProviderStatusListener from "../root/useProviderStatusListener"
import { ProviderInstance } from "../../provider"
const useLocale = () => {
  return useSyncExternalStore(
    useProviderStatusListener('relay'),
    useCallback(() => _.head(_.split(ProviderInstance.getProvider('relay').getLocale(), '_')), [])
  )
}

export default useLocale