import { useCallback, useMemo } from 'react'
import shortid from 'shortid'
import { ProviderInstance } from '../../../provider'
import _ from 'lodash'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import isEqual from 'react-fast-compare'
import useModelSubscription from '../useModelSubscription'
import useModelFormatter from '../useModelFormatter'
import useModelListenerStatus from '../useModelListenerStatus'

const useModel = (model_name, ids, options) => {
  const {
    populate,
    allowAll,
    preFilter,
    listener_id: inputListenerId,
  } = options || {}
  const listener_id = useMemo(() => inputListenerId || shortid.generate(), [inputListenerId])

  const [subscribe_listener] = useModelSubscription(
    model_name,
    { ids },
    { populate, allowAll, preFilter, listener_type: 'basic', listener_id },
    [_.flatten(ids).join(','), preFilter]
  )
  const full_result = useSyncExternalStoreWithSelector(
    subscribe_listener,
    useCallback(() => ProviderInstance.getProvider('store').getModel(model_name).getListenerResult(listener_id, { ids, preFilter, populate, type: 'basic' }), [_.flatten(ids).join(','), preFilter]),
    null,
    useModelFormatter(options),
    isEqual
  )
  const status = useModelListenerStatus(model_name, listener_id)
  return [full_result, status]
}

export default useModel