import { useMemo } from 'react'
import _ from 'lodash'
import useModel from '../../store/useModel'
import HookUtils from '../../../utils/hooks'

const useJobNextSteps = (input, options) => {
  const job_id = _.get(input, 'job_id')
  const [job, jobState] = useModel('job', [job_id], { populate: ['steps.order.assigned_transport'], single: true })
  const next_steps = useMemo(() => _.filter(_.get(job, 'steps'), step => {
    return step.index === _.get(job, 'current_index') &&
      !_.includes(['canceled', 'complete'], _.get(step, 'order.assigned_transport.status')) &&
      (_.get(step, 'order.status') !== 'canceled' || !!_.get(step, 'order.assigned_transport'))
  }), [HookUtils.getRecordsWatcher(job)])
  const [steps, stepState] = useModel('step', _.map(next_steps, 'step_id'), options)
  return [steps, { isLoading: HookUtils.getLoadingState([jobState, stepState]) }]
}


export default useJobNextSteps