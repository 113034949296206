import _ from 'lodash'
import { useCallback } from "react";
import { ProviderInstance } from "../../../provider"
import Utils from '../../../utils';

function useProviderListener(name, subscribe_id, info, options, watchers, info_watchers, option_watchers) {
  return useCallback(
    ProviderInstance.getProvider(name)?.prepareSubscription(subscribe_id, info, options) || _.noop,
    _.flatten([
      watchers,
      Utils.selectAllKeys(info, info_watchers),
      Utils.selectAllKeys(options, option_watchers),
      [subscribe_id, name]
    ])
  )
}

export default useProviderListener