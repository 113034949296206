import React, { useEffect } from 'react'
import FormProvider from './form'
import ProviderClass from '../classes'
import _ from 'lodash'
import FetchHandler from '../contexts/fetch'
import ExternalProvider from '../contexts/external'

const ProviderInstance = new ProviderClass()
global.ProviderInstance = ProviderInstance


const Provider = (props) => {
  const { children, main, form, ...remaining_props } = props
  useEffect(() => {
    ProviderInstance.init(remaining_props)
    return () => ProviderInstance?.deinit()
  }, [])
  return (
    <>
      {/* <React.StrictMode> */}
      <FetchHandler />
      <ExternalProvider  {...main}>
        <FormProvider {...form}>
          {children}
        </FormProvider>
      </ExternalProvider>
      {/* </React.StrictMode> */}
    </>
  )
}
export default React.memo(Provider)

export {
  ProviderInstance
}