import _ from 'lodash'
import useProviderReady from '../useProviderReady'
import useAuthenticated from '../../auth/useAuthenticated'
const useReadyStatus = () => {
  const auth_ready = useProviderReady('auth')
  const store_ready = useProviderReady('store')
  const isAuthenticated = useAuthenticated()
  return !!isAuthenticated ? store_ready : auth_ready
}

export default useReadyStatus
