import useModel from '../../store/useModel'
import useSetting from '../setting/useSetting'

const useClientID = (order_object, { parsed, emptyValue = '-', ...options } = {}) => {
  const { order_id } = order_object || {}
  const [prefix] = useSetting('order_id_prefix')
  const [order] = useModel('order', [order_id], { single: true, populate: ['commercial_package'] })
  const suffix = _.get(order, 'commercial_package.suffix') || '0'
  const order_client_id = _.get(order, 'order_client_id') || ''
  const datetime = _.slice(order_client_id, (prefix || '').length, (prefix || '').length + 6).join('')
  const id = _.replace(order_client_id, [prefix, datetime, suffix].join(''), '')
  if (!parsed)
    return order_client_id || emptyValue
  return {
    prefix,
    suffix,
    datetime,
    id,
    full: order_client_id
  }
}


export default useClientID
