import { useCallback } from "react"
import { ProviderInstance } from "../../../provider"

function useAuthFunction(path) {
  return [
    null,
    useCallback(ProviderInstance.getProvider('auth')[path].bind(ProviderInstance.getProvider('auth')), [path])
  ]
}

export default useAuthFunction