
import React, { createContext } from 'react'
import ViewSelector from '../../../components/ViewSelector'
import { useSectionPath, useFormName } from '../../hooks'
import _ from 'lodash'


const SectionContext = createContext({})
const SectionContextProvider = SectionContext.Provider

const Section = (props) => {
  const {
    name: inputName = [],
    getName,
    children,
    ...rest
  } = props
  const name = !!getName ? getName(props) : inputName
  const current_path = useSectionPath()
  const form_name = useFormName()
  const final_prefix = _.flatten([current_path, name])

  return (
    <SectionContextProvider value={{ path: final_prefix, form_name }}>
      <ViewSelector {...rest}>{children}</ViewSelector>
    </SectionContextProvider>
  )
}

export {
  SectionContext
}
export default React.memo(Section)