import _ from 'lodash'
import I18n from './translation'
class BillingUtils {
  static getCurrencySymbol(currency) {
    switch (currency) {
      case 'USD':
        return '$'
      case 'GBP':
        return '£'
      case 'EUR':
      default:
        return '€'
    }
  }

  static getCurrencyTranslation(currency) {
    switch (_.toLower(currency)) {
      case 'usd':
      case 'eur':
      case 'gbp':
        return ['voc.currency', _.toLower(currency)].join('.')
      default:
        return 'voc.currency.eur'
    }
  }

  static mergeWithCurrency(value, currency) {
    return I18n.t(BillingUtils.getCurrencyTranslation(currency), { price: value })
  }

  static getRoundedPrice(price, precision) {
    if (_.isFinite(precision))
      return _.round(price || 0, precision)
    return price || 0
  }
  static getMinMax(estimation, precision, extra) {
    const { discount = 0 } = extra || {}
    const parsed_min = parseFloat(_.subtract(_.get(estimation, 'min_rate'), discount))
    const parsed_max = parseFloat(_.subtract(_.get(estimation, 'max_rate'), discount))
    const min = BillingUtils.getRoundedPrice(_.max([parsed_min, 0]), precision)
    const max = BillingUtils.getRoundedPrice(_.max([parsed_max, 0]), precision)
    return {
      min, max
    }
  }

  static getEstimationDisplay(estimation, hide_currency, precision, extra) {
    const { min, max } = BillingUtils.getMinMax(estimation, precision, extra)

    const currency = _.get(estimation, 'currency')
    const elems = min === 0 || max === 0 ? [min, max] : _.compact([min, max])
    if (_.has(estimation, 'localized_rate'))
      return estimation?.localized_rate
    const price = !_.has(estimation, 'min_rate') || (_.get(estimation, 'type') === 'special' && _.get(estimation, 'min_rate') === 0) ? '-' : _.uniq(_.invokeMap(elems, Number.prototype.toFixed, 2)).join(' - ')
    if (hide_currency)
      return price
    return BillingUtils.mergeWithCurrency(price, currency)
  }


  static getPaymentDisplay(payment, hide_currency, precision, disable_discount) {
    const base = _.get(payment, 'base')
    const driver_package_fee = _.get(payment, 'driver_package_fee')
    const discount = _.get(payment, 'discount') || 0
    const fees = _.get(payment, 'fees')
    const handling = _.get(payment, 'handling')
    const prepaid = _.get(payment, 'prepaid')
    const currency = _.get(payment, 'currency')
    const empty_fee = _.get(payment, 'empty_fee')
    const service_amount = _.get(payment, 'service_amount')
    const duration_amount = _.get(payment, 'duration_amount')
    const option_amount = _.get(payment, 'option_amount')
    const night_amount = _.get(payment, 'night_amount')

    const sum = _.sum(_.compact([base, driver_package_fee, fees, handling, empty_fee, prepaid, service_amount, duration_amount, option_amount, night_amount]))
    const price = !!disable_discount ? _.max([(sum), 0]) : _.max([(sum - discount), 0])
    const total = BillingUtils.getRoundedPrice(price, precision).toFixed(2)
    if (hide_currency)
      return total
    return BillingUtils.mergeWithCurrency(total, currency)
  }
}

export default BillingUtils
