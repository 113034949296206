import _ from "lodash"
import Provider from ".."
import GenericError from '../../error'

class Auth extends Provider {
  constructor(id, root) {
    super(id, root)
    this.user_info = null
    this.is_compatible = true
    this.access_token = null
    this.refresh_token = null
    this.signature = null
  }
  async resetToken() {
    this.user_info = null
    this.access_token = null
    this.refresh_token = null
    this.signature = null
    await this.updateStorage()
    return this.notifyProviderListeners()
  }
  async updateToken(data) {
    this.access_token = _.get(data, 'payload.access_token')
    this.refresh_token = _.get(data, 'payload.refresh_token')
    this.signature = _.get(data, 'signature')
    await this.updateStorage()
    return this.notifyProviderListeners()
  }
  setAccessToken(token) {
    this.access_token = token
    return this
  }
  setRefreshToken(token) {
    this.refresh_token = token
    return this
  }
  getAccessToken() {
    return this.access_token
  }
  getRefreshToken() {
    return this.refresh_token
  }
  isCompatible() {
    return this.is_compatible
  }
  getSignature() {
    return this.signature
  }
  setSignature(signature) {
    this.signature = signature
    return this
  }
  isAuthenticated() {
    return this.signature !== null
  }
  getUserInfo() {
    return this.user_info
  }
  async rehydrateServer() {
    try {
      if (!!this.isAuthenticated() && !this.getProvider('relay').getIdentity()) {
        this.resetToken()
        return super.init(true)
      }
      this.user_info = await this.getProvider('relay').getMainContact().execute('GET', 'me', {})
      this.is_compatible = true
      await this.getProvider('socket').init()
      return super.init(true)
    }
    catch (err) {
      if (err instanceof GenericError && err.getCode() === 'VERSION_TOO_LOW') {
        this.is_compatible = false
        this.notifyProviderListeners()
      }
      else if (err instanceof GenericError && (err.getCode() === 'TOKEN_REVOKED' || err.getCode() === 'ACCESS_UNAUTHORIZED')) {
        this.resetToken()
        return super.init(true)
      }
      else throw err
    }
  }
  async rehydrateStorage() {
    const stored_config = await this.getProvider('storage').readPrefix('main')
    if (!_.isEmpty(_.get(stored_config, 'auth.payload'))) {
      this
        .setAccessToken(_.get(stored_config, 'auth.payload.access_token'))
        .setRefreshToken(_.get(stored_config, 'auth.payload.refresh_token'))
        .setSignature(_.get(stored_config, 'auth.signature'))
      return true
    }
    return false
  }
  async updateStorage() {
    if (!this.isAuthenticated())
      await this.getProvider('storage').setPrefix('main', { auth: null })
    else await this.getProvider('storage').setPrefix('main', {
      auth: {
        payload: { access_token: this.getAccessToken(), refresh_token: this.getRefreshToken() },
        signature: this.getSignature()
      }
    })
  }
  async init() {
    if (await this.rehydrateStorage())
      return this.rehydrateServer()
    return super.init(true)
  }
  async signup(data) {
    return this.getProvider('relay').getMainContact().execute('POST', 'auth/signup', data)
  }
  async updateUser(data) {
    return this.getProvider('relay').getMainContact().execute('PUT', 'me', data)
  }
  async resetPassword(data) {
    return this.getProvider('relay').getMainContact().execute('GET', 'auth/reset-password', data)
  }
  async changePassword(data) {
    return this.getProvider('relay').getMainContact().execute('POST', 'auth/password', data)
  }
  async validateResetPasswordToken(data) {
    return this.getProvider('relay').getMainContact().execute('POST', 'auth/validate', data)
  }
  async setPushToken(data) {
    return this.getProvider('relay').getMainContact().execute('POST', 'auth/push', data)
  }
  async signin(data) {
    const auth = await this.getProvider('relay').getMainContact().execute('POST', 'auth/signin', data, {}, { raw: true })
    this
      .setAccessToken(_.get(auth, 'data.payload.access_token'))
      .setRefreshToken(_.get(auth, 'data.payload.refresh_token'))
      .setSignature(_.get(auth, 'data.signature'))
    await this.rehydrateServer()
    await this.updateStorage()
    return auth?.data
  }
  async findUser(data) {
    const contact = !!_.get(this.getProvider('relay').getConfig(), 'BACKEND_HOST') ? this.getProvider('relay').getMainContact() : this.getProvider('relay').getRelayContact()
    const response = await contact.execute('GET', 'driver', data, {}, { raw: true })
    this.getProvider('relay').setIdentity(_.get(response, 'headers.x-fl-identity')).rehydrateServer()
    return _.get(response, 'data')
  }
  async clear() {
    try {
      await this.getProvider('relay').getMainContact().execute('POST', 'auth/signout', {})
    }
    catch (err) {
      console.warn("Failed to contact backend onsignout", err)
    }
    await this.resetToken()
  }

}

export default Auth