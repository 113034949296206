import React from 'react'
import _ from 'lodash'
class TypeUtils {
  static oneByType(children, Component) {
    return TypeUtils.getArray(children).find(TypeUtils.elementHasType(Component)) || null
  }
  static allByType(children, Component) {
    return TypeUtils.getArray(children).filter(TypeUtils.elementHasType(Component))
  }
  static getChildren(children) {
    return React.Children.map(children, (child) => child.props.children)
  }
  static getProps(child, omit_children) {
    return (!!omit_children ? _.omit(child?.props, ['children']) : child?.props) || {}
  }

  static withoutTypes(children, ...Components) {
    const types = Components.map(TypeUtils.getType)

    return TypeUtils.getArray(children).filter(
      (child) => !types.includes(child.type)
    )
  }

  static getArray(children) {
    const array = React.Children.toArray(children)
    if (!React.Fragment) return array
    return array.flatMap((child) => {
      const isFragment = TypeUtils.elementHasType(React.Fragment)(child)
      return isFragment ? TypeUtils.getArray(child.props.children) : child
    })
  }

  static elementHasType(Component) {
    const type = TypeUtils.getType(Component)
    return (element) => element.type === type
  }

  static getType(Component) {
    const element = React.createElement(Component)
    return element.type
  }
}

export default TypeUtils
