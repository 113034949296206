import { useCallback, useEffect } from 'react'

import Utils, { GeoUtils } from '../../../utils'
import Callbacks from './callbacks'
import _ from 'lodash'
import useEstimationStatus from './useEstimationStatus'
import { useModel, useModelValues } from '../../store'

const useEstimation = (filter, options, onEstimationComplete) => {
  const {
    user_id,
    service,
    requestedAt,
    source_details,
    destination_details,
    mode,
    duration,
    estimation_type,
    vehicle_opts,
    driver_opts,
    returnAt,
    load_type,
    load_count,
    application,
    commercial_formula,
    codepromo_id
  } = filter

  const {
    isReady,
    disabled,
    clearOnDisabled = true,
    refreshOnEnabled = false,
    disable_destination_validate,
    disable_traffic_validate
  } = options

  const final_filter = _.merge(filter, { disable_destination_validate, disable_traffic_validate })

  const [estimations, getEstimates, , { clear: clearEstimates }] = useModelValues('ridemode', 'estimate', null, { search_id: 'package_estimations' })

  const mutator = useCallback((cp) => {
    const matching_estimation = _.find(estimations, ['commercial_package_id', cp?.commercial_package_id])
    return _.merge({}, cp, { available: matching_estimation?.available, reason: matching_estimation?.reason })
  }, [estimations])

  const [authorized] = useModel('commercialpackage', _.map(estimations, 'commercial_package_id'), {
    search_id: 'package_authorized',
    listener_id: 'package_authorized',
    mutator,
    sortBy: 'display_index',
    sortDirection: ['DESC']
  })
  const status = useEstimationStatus()

  const refreshEstimates = useCallback(Callbacks.refreshEstimatesHandler(getEstimates, clearEstimates, onEstimationComplete, estimations), [getEstimates, clearEstimates, onEstimationComplete, estimations])
  const controls = {
    refreshEstimate: useCallback(() => refreshEstimates(final_filter), [final_filter, refreshEstimates])
  }
  const estimationWatchers = [
    user_id,
    service,
    requestedAt,
    returnAt,
    Utils.selectFirstKey(source_details, GeoUtils.getAddressKeys()),
    Utils.selectFirstKey(destination_details, GeoUtils.getAddressKeys()),
    mode,
    duration,
    estimation_type,
    _.join(vehicle_opts, ','),
    _.join(driver_opts, ','),
    load_type,
    load_count,
    application,
    commercial_formula,
    codepromo_id
  ]

  useEffect(() => {
    if (!disabled && !!isReady)
      refreshEstimates(final_filter)
  }, estimationWatchers)
  useEffect(() => {
    if (!!disabled && clearOnDisabled) {
      clearEstimates()
    }
    else if (!disabled && refreshOnEnabled) {
      refreshEstimates(final_filter)
    }
  }, [disabled])
  return [estimations, authorized, status, controls]

}

export default useEstimation
