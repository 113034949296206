import _ from 'lodash'

class Callbacks {
  static setValueHandler(setProviderValues, key) {
    return function setValue(value) {
      return setProviderValues([{ path: key, value }])
    }
  }
  static setSubValueHandler(setProviderValues, key) {
    return function setSubValue(sub_key, value) {
      return setProviderValues([{ path: _.flatten([key, sub_key]).join('.'), value }])
    }
  }
  static setSubValuesHandler(setProviderValues, key) {
    return function setSubValues(data) {
      return setProviderValues(_.map(data, ({ path, value }) => ({ path: _.flatten([key, path]).join('.'), value })))
    }
  }
  static clearHandler(clearProviderValues, key) {
    return function clear() {
      return clearProviderValues([key])
    }
  }
}

export default Callbacks
