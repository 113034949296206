import { useCallback, useSyncExternalStore } from "react";
import { ProviderInstance } from "../../../provider"
import useProviderStatusListener from "../useProviderStatusListener";
function useProviderReady(provider_name) {
  const listener = useProviderStatusListener(provider_name)
  return useSyncExternalStore(
    listener,
    useCallback(() => ProviderInstance.getProvider(provider_name).getProviderStatus(), [provider_name])
  )
}

export default useProviderReady