
import React from 'react'
import useLocalSection from '../hooks/section/useLocalSection'
import useFormState from '../hooks/form/useFormState'
import Section from '../section'
import _ from 'lodash'

const SectionGenerator = ({ children, allowEmptyRow, isReadOnly }) => {
  const [values] = useLocalSection()
  const current_count = (values || []).length
  const sectionChildren = _.map(values, (value, index) => <Section key={index} name={index}>{children}</Section>)
  return !!allowEmptyRow && !isReadOnly ? _.flatten([sectionChildren, <Section key={current_count} name={current_count} >{children}</Section>]) : sectionChildren
}

const SectionArray = (props) => {
  const {
    name,
    children,
    allowEmptyRow
  } = props

  const [isReadOnly] = useFormState('isReadOnly')
  return (
    <Section name={name}>
      <SectionGenerator allowEmptyRow={allowEmptyRow} isReadOnly={isReadOnly}>
        {children}
      </SectionGenerator>
    </Section>
  )
}

export default React.memo(SectionArray)
