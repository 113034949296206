import _ from "lodash";
import { useEffect } from "react";

function useDeprecated(name, replacements) {
  useEffect(() => {
    if (_.isEmpty(replacements))
      console.warn(`**** DEPRECATED ${name} is deprecated with no replacement ****`)
    console.warn(`**** ${name} is deprecated. Use ${(replacements || []).join(', ')} instead ****`)
  }, [])
}

export default useDeprecated