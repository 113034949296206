import _ from 'lodash'
import useActiveUser from './useActiveUser'

const useAdministrationLevelUser = (company, contract) => {
  const [user] = useActiveUser({ populate: ['user_companies.companyuser_services.companyservice.contract'] })
  const companyuser = _.find(user?.user_companies, ['company', company])

  const administration_level = companyuser?.administration_level
  const company_contracts = _.map(_.filter(companyuser?.companyuser_services, ['companyservice.contract.company', company]), 'companyservice.contract.companycontract_id')

  const is_user_contract = !!_.find(companyuser?.companyuser_services, elem => _.includes(_.flatten(contract || company_contracts), elem?.companyservice?.contract?.companycontract_id))

  if (administration_level === 'company') {
    return 'company'
  } else if (administration_level === 'contract' && !!is_user_contract) {
    return 'contract'
  } else if (administration_level === 'service') {
    return 'service'
  } else {
    return null
  }
}

export default useAdministrationLevelUser
