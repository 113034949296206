import QueryString from 'qs'
import _ from 'lodash'

import useServerURL from './useServerURL'
import useRelaySetting from './useRelaySetting'
import useAccessToken from '../auth/useAccessToken'

const userDownloadURL = (path, args = {}) => {
  const server_url = useServerURL()
  const token = useAccessToken()
  const prefix = useRelaySetting('BACKEND_PREFIX')
  const query_string = QueryString.stringify({ ...args, token })
  const url = _.join(_.compact([_.join(_.compact([server_url, prefix, path]), ''), query_string]), '?')
  return [url]

}

export default userDownloadURL