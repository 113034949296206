import { useContext, useCallback, useMemo } from 'react'
import { GlobalFormContext } from '../..'

const useGlobalFormMeta = (form_name) => {
  const { getFormMeta, getInjectedDispatch } = useContext(GlobalFormContext)
  const formMeta = useMemo(() => getFormMeta(form_name), [form_name, getFormMeta])
  const updateFormMeta = useCallback((field, meta) => getInjectedDispatch(form_name)({ type: 'UPDATE_FIELD_META', field, data: meta }), [form_name, getInjectedDispatch])
  const removeFormMeta = useCallback((field) => getInjectedDispatch(form_name)({ type: 'REMOVE_FIELD_META', field }), [form_name, getInjectedDispatch])
  return [formMeta, updateFormMeta, removeFormMeta]
}

export default useGlobalFormMeta
