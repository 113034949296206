import { useForm, useFormMeta } from '../../../hooks'
import _ from 'lodash'

const useFieldValues = (fields, options) => {
  const { only_mounted, nullValue = null } = options || {}
  const [values] = useForm()
  const [meta] = useFormMeta()
  return _.map(fields, (field) => !!only_mounted && !_.find(meta, ['field', field]) ? nullValue : _.get(values, field))
}

export default useFieldValues
