import useModel from '../../store/useModel'
import useTranslation from '../translation/useTranslation'

const useVehicleStatus = (vehicle_object, { translated, ...options } = {}) => {
  const { vehicle_id } = vehicle_object || {}
  const [vehicle] = useModel('vehicle', [vehicle_id], { single: true })
  return useTranslation('vehicle.status', _.get(vehicle, 'status'), { disabled: !translated, ...options })
}

export {
  useVehicleStatus
}
