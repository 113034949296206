import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import GenericError from '../error'

async function tick(interval = 1) {
  return new Promise(resolve => setTimeout(resolve, interval * 1000));
}
class Contact {
  constructor(provider, name, external, options) {
    this.provider = provider
    this.name = name
    this.root_url = null
    this.headers = {}
    this.internal = !external
    this.disable_retry = _.get(options, 'disable_retry')
    this.use_provider_auth = _.get(options, 'use_provider_auth', false)
    this.onIdentityChange = _.noop
  }
  getParentProvider() {
    return this.provider
  }
  getRequestOptions(method, path, args, headers, options) {
    const token = this.getAccessToken()
    return _.merge({
      method,
      url: _.compact([this.root_url || null, path]).join('/'),
      headers: _.merge({}, !!this.use_provider_auth && !!token ? { 'Authorization': `Bearer ${token}` } : {}, this.headers, headers),
      maxContentLength: 44040192,
      maxBodyLength: 44040192
    },
      this.axios_options,
      options,
      _.toLower(method) === 'get' || _.toLower(method) === 'delete' ? { params: args } : { data: args },
      this.internal ? { paramsSerializer: this.getInternalSerializer } : {},
    )
  }
  getInternalSerializer(params) {
    return qs.stringify({ data: JSON.stringify(params) })
  }
  init(host, port, headers, options) {
    const prefix = _.get(options, 'prefix') || '/'
    this.root_url = _.trim(_.compact([`http${(parseInt(port) === 443) ? 's' : ''}://${host}:${port}`, prefix]).join('/'), '/')
    this.headers = headers
    this.axios_options = _.get(options, 'axios')
    return this
  }
  getAccessToken() {
    return this.getParentProvider()?.getProvider('auth').getAccessToken()
  }
  getRefreshToken() {
    return this.getParentProvider()?.getProvider('auth').getRefreshToken()
  }
  async renewToken() {
    try {
      const renew_result = await this.execute('POST', 'refreshtoken/renew', { expired_token: this.getAccessToken() }, { 'Authorization': `Bearer ${this.getRefreshToken()}` })
      await this.getParentProvider()?.getProvider('auth').updateToken(renew_result)
    }
    catch (err) {
      const formatted_err = GenericError.load(err)
      if (formatted_err.getCode() === 'TOKEN_REVOKED')
        await this.revokeToken()
      throw formatted_err
    }
  }
  revokeToken() {
    return this.getParentProvider()?.getProvider('auth').resetToken()
  }
  async loadError(err, args, route_options) {
    const { raw, axios_options } = route_options || {}
    if (_.get(axios_options, 'responseType') === 'blob')
      return GenericError.load(JSON.parse(await err.response.data.text()))
    const parsed_err = GenericError.load(err?.response?.data)
    if (!!raw)
      return parsed_err.setContext(_.merge({}, parsed_err?.context, { args }, _.pick(err?.response, ['data', 'headers'])))
    return parsed_err
  }
  async execute(method, path, args, headers, route_options) {
    const { raw, axios_options } = route_options || {}
    const connection_options = this.getRequestOptions(method, path, args, headers, axios_options)
    try {
      console.log("[VOC] Making HTTP Request", connection_options)
      const result = await axios(connection_options)
      console.log("[VOC] Received HTTP Response", result.data)
      if (!!raw)
        return _.merge({ args }, _.pick(result, ['data', 'headers']))
      return result.data
    }
    catch (err) {
      if (!!_.get(err, 'response.data')) {
        const formatted_err = await this.loadError(err, args, route_options)
        if (formatted_err.getCode() === 'TOKEN_EXPIRED') {
          await this.renewToken()
          return this.execute(method, path, args, headers, route_options)
        }
        else if (formatted_err.getCode() === 'TOKEN_REVOKED') {
          await this.revokeToken()
          throw formatted_err
        }
        console.warn("Received HTTP Error", formatted_err.getFormattedMessage(), { trace_id: formatted_err.getTraceID(), context: formatted_err.getContext(), err: formatted_err })
        throw formatted_err
      }
      else if (GenericError.load(err).getMessage() === 'Network Error') {
        console.warn(err)
        if (!!this.disable_retry)
          throw err
        await tick(1)
        return this.execute(method, path, args, headers, route_options)
      }
      else throw GenericError.load(err)
    }
  }
}


export default Contact