import React, { useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { hooks } from '@front/volcanion'
import HookUtils from '@front/volcanion/utils/hooks'

function getOptionPropsHandler(inputGetOptionProps, primary_key, autoFetchSelection, missing_option_ids) {
  return function getOptionProps(option, selectorProps) {
    const inputOptions = _.merge({}, inputGetOptionProps(option, selectorProps), { color: "success" })
    if (!!autoFetchSelection) {
      if (_.includes(missing_option_ids, _.get(option, primary_key)))
        return _.merge({}, inputOptions, { color: "warning" })
      return inputOptions
    }
    return inputOptions
  }
}

function onInputChangeHandler(searchDataset, autocompleteKey, autocompleteMutator, searchQuery, minQueryLength) {
  return function onInputChange(q) {
    if ((q || '').length >= (minQueryLength || -1)) {
      const filter = autocompleteMutator(q)
      if (!!filter)
        searchDataset(filter)
      else if (!!autocompleteKey) {
        searchDataset(_.set({}, autocompleteKey, { [searchQuery]: q || '' }))
      }
      else searchDataset(q || {})
    }
  }
}

function useSearchContext(searchMode, model_name, model_func, option_ids, config) {
  switch (searchMode) {
    case 'search':
      return hooks.useModelSearch(model_name, model_func, _.merge({ watch_type: 'result' }, config))
    case 'function':
      return hooks.useModelValues(model_name, model_func)
    case 'local':
    default:
      const [result, state] = hooks.useModel(model_name, option_ids, config)
      return [result, _.noop, state]
  }
}

const withModel = (Component) => ({
  name,
  model_name,
  model_func = 'get',
  searchMode = 'local',
  searchQuery = '=',
  minQueryLength,
  config,
  options: input_options,
  autoFetchSelection = searchMode !== 'function',
  getOptionProps: inputGetOptionProps = _.noop,
  isLoading,
  datasetKey,
  autocompleteKey,
  selectKeys: inputSelectKeys,
  autocompleteMutator = _.noop,
  ...props
}) => {
  const { primary_key } = hooks.useModelSchema(model_name)
  const group_key = datasetKey || primary_key
  const selectKeys = _.isUndefined(inputSelectKeys) ? [group_key] : inputSelectKeys
  const selected_values = !_.has(props, 'value') && !!name ? hooks.useLocalFieldValue(name) : props.value
  const sanitized_values = useMemo(() => _.filter(_.compact(_.flatten([selected_values])), (v) => _.isString(v) || _.has(v, group_key)), [selected_values])
  const option_ids = useMemo(() => (!!input_options ? _.map(input_options, primary_key) : _.get(config, 'ids')) || [], [primary_key, input_options, config])
  const [dataset, searchDataset, state, controls] = useSearchContext(searchMode, model_name, model_func, option_ids, config)
  const dataset_ids = _.map(dataset, group_key)
  const missing_option_ids = useMemo(() => _.difference(sanitized_values, dataset_ids), [dataset_ids.join(','), sanitized_values.join(',')])
  const [missing_options, missing_state] = hooks.useModel(model_name, !!autoFetchSelection && !!_.includes(selectKeys, primary_key) ? missing_option_ids : [], _.omit(config, ['watchers']))
  const getOptionProps = useCallback(getOptionPropsHandler(
    inputGetOptionProps, group_key, autoFetchSelection, missing_option_ids),
    [inputGetOptionProps, group_key, autoFetchSelection, missing_option_ids.join(',')]
  )

  const [options] = hooks.useModel(model_name, dataset_ids, _.omit(config, ['watchers']))
  const full_options = _.uniqBy(_.flatten([missing_options, options]), group_key)
  const onInputChange = useCallback(onInputChangeHandler(searchDataset, autocompleteKey, autocompleteMutator, searchQuery, minQueryLength), [searchDataset, autocompleteKey, autocompleteMutator, searchQuery, minQueryLength])

  return (
    <Component
      name={name}
      isLoading={HookUtils.getLoadingState([state, missing_state, { isLoading }])}
      isReady={HookUtils.getReadyState([state, missing_state])}
      options={full_options}
      selectKeys={selectKeys}
      matchKeys={[group_key]}
      getOptionProps={getOptionProps}
      onInputChange={searchMode !== 'local' ? onInputChange : undefined}
      controls={controls}
      {...props}
    />
  );
}


export default withModel
