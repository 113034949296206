import moment from 'moment'
import _ from 'lodash'

const DEFAULT_INTL_PHONE_CODE = '32'

class Utils {
  static isNil(value) {
    return (value === undefined || value === '') && !_.isNumber(value) && !_.isBoolean(value)
  }
  static extractFieldValues(props) {
    return _.reduce(_.sortBy(_.uniq(props.names), (name) => name.split('.').length), (acc, key) => {
      const value = _.get(props, [key, 'input', 'value'].join('.'))
      const jsonValue = (typeof _.get(value, 'toJS') === 'function') ? value.toJS() : value
      return _.set(acc, key, jsonValue === "" ? undefined : jsonValue);
    }, {
      props: _.pick(props, _.difference(_.keys(props), _.map(props.names, (name) => _.head(_.split(name, '.')))))
    });
  }
  static selectAllKeys(obj, keys) {
    return _.map(keys, (key) => _.get(obj, key, null))
  }
  static selectFirstKey(obj, keys, strict) {
    if (_.isUndefined(obj) || !_.isObject(obj))
      return obj
    const found_key = _.find(keys, (key) => !_.isUndefined(_.get(obj, key)))
    if (!_.isUndefined(found_key))
      return _.get(obj, found_key)
    return null
  }
  static handleDefaultProps(props, defaultProps) {
    return _.reduce(defaultProps, (acc, propValue, propName) => {
      if (!_.isUndefined(_.get(props, propName)))
        return acc
      return _.set(acc, propName, propValue)
    }, _.merge({}, props))
  }
  static chainHandler(funcs, value, ...args) {
    const chain = _.compact(_.flatten([funcs]))
    return Promise.all(_.map(chain, async (c) => {
      try {
        const jsonValue = (typeof _.get(value, 'toJS') === 'function') ? value.toJS() : value
        await c(jsonValue, ...args)
      }
      catch (err) {
        console.warn("Error handling after change", { c, funcs, value, err })
      }
    }))
  }
  static isSameObject(obj1, obj2, match_keys) {
    return !!_.reduce(_.intersection(match_keys, _.keys(obj1), _.keys(obj2)),
      (acc, match_key) => acc || (!!_.get(obj1, match_key) && _.isEqual(_.get(obj1, match_key), _.get(obj2, match_key))),
      false
    );
  }
  static prefixKeys(keys, prefix, delimiter) {
    return _.map(keys, (key) => _.flatten([prefix, key]).join(delimiter))
  }
  static parsePhoneNumber(value, codeIntlPhone) {
    return (value || '')
      .replace(/([ .-]+)/g, '')
      .replace(/^00/g, '+')
      .replace(/^0([1-9])/g, ('+' + (codeIntlPhone || DEFAULT_INTL_PHONE_CODE) + '$1'));
  }
  static mergeDateTime(date, time) {
    if (!date || !time) return null
    let dateMoment = date
    if (!moment.isMoment(date))
      dateMoment = moment.utc(date)
    let timeMoment = time
    if (!moment.isMoment(time))
      timeMoment = moment.utc(time)
    if (timeMoment.isValid() && dateMoment.isValid()) {
      return moment(`${dateMoment.format('YYYY/MM/DD')} ${timeMoment.format('HH:mm')}`, 'YYYY/MM/DD HH:mm').utc().format()
    }
    return null
  }
  static parseDateTime(datetime) {
    if (!datetime)
      return {
        time: null,
        date: null
      }
    return {
      time: moment.utc(datetime).format() || null,
      date: moment.utc(datetime).format() || null,
    }
  }
}

export default Utils