
import { hooks } from '@front/volcanion/'
import _ from 'lodash'
import moment from 'moment'

const useAdjustedTimeLimit = (datetime, minuteInterval) => {
  if (!datetime)
    return datetime
  const momentInstance = moment.utc(datetime)
  const value = minuteInterval * _.ceil(momentInstance?.minutes() / minuteInterval) - momentInstance?.minutes()
  return momentInstance.add(value, 'minutes').format()
}

const useSPTimeLimits = (sp_id) => {
  const minuteInterval = hooks.useRelayConstant('TIMEPICKER_MINUTE_INTERVAL')
  const [sp, state] = hooks.useModel('specificparameter', [sp_id], { single: true })
  const { min_delay, max_delay } = sp || {}
  const minimumDate = _.isFinite(min_delay) ? moment.utc().add(min_delay, 'minutes').add(2, 'minutes') : undefined
  const maximumDate = _.isFinite(max_delay) ? moment.utc().add(max_delay, 'minutes') : undefined
  return [
    {
      minimumDate: useAdjustedTimeLimit(minimumDate, minuteInterval || 5),
      maximumDate: useAdjustedTimeLimit(maximumDate, minuteInterval || 5),
    },
    state
  ]
}

export default useSPTimeLimits
