import _ from 'lodash'
import { useContext, useEffect } from 'react'
import {
  GlobalFormContext
} from '../..'
const useGlobalFormListener = (form_name, callback, options) => {
  const { listener } = useContext(GlobalFormContext)
  useEffect(() => {
    if (!!form_name && !_.get(options, 'disabled')) {
      listener.on(form_name, callback)
      return () => listener.off(form_name, callback)
    }
    return _.noop
  }, [form_name, _.get(options, 'disabled'), callback])
}

export default useGlobalFormListener
