import _ from 'lodash'

import useMethodListenerResult from '../../store/useMethodListenerResult'
import useMethodListener from '../../store/useMethodListener'
import useMethodListenerStatus from '../../store/useMethodListenerStatus'

const usePackageEstimation = (search_id, commercial_package_id, estimation_type) => {
  const estimations = useMethodListenerResult('ridemode', search_id)
  const getListener = useMethodListener('ridemode', search_id)
  const status = useMethodListenerStatus('ridemode', search_id, 'method')

  const package_estimations = _.filter(estimations, ['commercial_package_id', commercial_package_id])

  return [
    !!estimation_type
      ? _.filter(package_estimations, ['type', estimation_type])
      : package_estimations,
    status,
    getListener
  ]
}

export default usePackageEstimation
