import { useMemo } from 'react'
import { useGlobalFormFunction, useGlobalFormStatus } from '../../../hooks'

import _ from 'lodash'
const useGlobalSectionState = (form_name, path) => {
  const isSectionEnabled = useGlobalFormFunction(form_name, 'isSectionEnabled')
  const isFormReadOnly = useGlobalFormStatus(form_name, 'isReadOnly')
  const isReadOnly = useMemo(() => isFormReadOnly || !isSectionEnabled(path.join('.')), [isFormReadOnly, isSectionEnabled, path.join('.')])
  return { isReadOnly }
}

export default useGlobalSectionState
