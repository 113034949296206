
import { hooks } from '@front/volcanion/'
import _ from 'lodash'
import moment from 'moment'

const useAdjustedTimeLimit = (datetime, minuteInterval) => {
  if (!datetime)
    return datetime
  const momentInstance = moment.utc(datetime)
  const value = minuteInterval * _.ceil(momentInstance?.minutes() / minuteInterval) - momentInstance?.minutes()
  return momentInstance.add(value, 'minutes').format()
}

const useCPTimeLimits = (cp_id) => {
  const minuteInterval = hooks.useRelayConstant('TIMEPICKER_MINUTE_INTERVAL')
  const [cp, state] = hooks.useModel('commercialpackage', [cp_id], { single: true })
  const { min_delay, max_delay } = cp || {}
  const minimumDate = _.isFinite(min_delay) ? moment.utc().add(min_delay, 'minutes').add(2, 'minutes') : undefined
  const maximumDate = _.isFinite(max_delay) ? moment.utc().add(max_delay, 'minutes') : undefined
  return [
    useAdjustedTimeLimit(minimumDate, minuteInterval || 5),
    useAdjustedTimeLimit(maximumDate, minuteInterval || 5),
    state
  ]
}

export default useCPTimeLimits
